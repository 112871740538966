import { SCALE_SPEED } from '@/components/WindowCreator/constants';
import { KonvaEventObject } from 'konva/lib/Node';

export const useKonvaHandlers = () => {
  const getCenterPosition = ({
    stageCenter,
    stageRelative,
    oldScale,
    newScale,
  }: {
    stageCenter: { x: number; y: number };
    stageRelative: { x: number; y: number };
    oldScale: number;
    newScale: number;
  }) => {
    const mousePointTo = {
      x: stageCenter.x / oldScale - stageRelative.x / oldScale,
      y: stageCenter.y / oldScale - stageRelative.y / oldScale,
    };

    return {
      scale: newScale,
      x: (stageCenter.x / newScale - mousePointTo.x) * newScale,
      y: (stageCenter.y / newScale - mousePointTo.y) * newScale,
    };
  };
  const wheelHandler = (
    e: KonvaEventObject<WheelEvent>,
    {
      maxScale,
      minScale,
    }: {
      maxScale: number;
      minScale: number;
    }
  ) => {
    e.evt.preventDefault();
    const stage = e.target.getStage()!;

    // MIN_ZOOM
    if (stage.scaleX() <= minScale && e.evt.deltaY > 0) {
      return;
    }

    // MAX_ZOOM
    if (stage.scaleX() >= maxScale && e.evt.deltaY < 0) {
      return;
    }
    const oldScale = stage.scaleX();
    const newScale =
      e.evt.deltaY < 0 ? oldScale * SCALE_SPEED : oldScale / SCALE_SPEED;

    return getCenterPosition({
      stageCenter: stage.getPointerPosition()!,
      oldScale,
      newScale,
      stageRelative: { x: stage.x(), y: stage.y() },
    });
  };

  return {
    wheelHandler,
    getCenterPosition,
  };
};
