import React, { useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { flatten, isEqual } from 'lodash';
import { booleanClockwise, lineString } from '@turf/turf';
import { uuidv7 } from 'uuidv7';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  CanvasActionsModes,
  DistanceInput,
  DrawModes,
  FlatVector3,
  MetricLimits,
  NodeType,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import { ExtrudeHandlerData } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  BUILDING_SELECTED_CONTOUR_COLOR,
  C_BasicLineMaterial,
  C_FatLineBorderMaterial,
  C_FloorMaterial,
  C_WallMaterial,
  C_WallSelectedMaterial,
} from '@/shared/materials';
import {
  checkLineIntersection,
  convertBufferGeometryTo3DVectorList,
  createLine2,
  getCenterFromVectorsArray,
  getDistanceBetweenInfiniteLineAndVector,
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  getXYZ,
  isPointsInOneLine,
  isVectorLeftSide,
  triangulateGeometryAndUpdate,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  getProcessingEntity,
  resetExternalElementsState,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import Border from '@/routes/dashboard/projects/project/UserBuilding/components/Border';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import { subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
} from '@/core/event-names';
import { hideSelectionBoxArea, isLeftClick } from '@/shared/helpers';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { setMode } from '@/store/slices/canvasModesSlice';
import { setExtrudeNode } from '@/store/slices/canvasBuildingSlice';
import { getAlphabetIndex } from '@/shared/helpers/format-data';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';

interface ExtrudeStoreyProps {
  storey: UserBuildingStorey;
  extrudeNode: ExtrudeHandlerData;
  buildingGuid: string;
  blockGuid: string;
  cursorPosition?: THREE.Vector3;
  setHandlerPosition?: React.Dispatch<React.SetStateAction<THREE.Vector3>>;
  maxNegativeDistance?: number;
  setMaxNegativeDistance?: (value: number) => void;
  maxPositiveDistance?: number;
  setMaxPositiveDistance?: (value: number) => void;
  isBlockMode?: boolean;
  saveBlock?: boolean;
}
const ExtrudeWall: React.FC<ExtrudeStoreyProps> = ({
  storey,
  extrudeNode,
  blockGuid,
  buildingGuid,
  cursorPosition,
  setHandlerPosition,
  maxNegativeDistance,
  maxPositiveDistance,
  setMaxNegativeDistance,
  setMaxPositiveDistance,
  isBlockMode,
  saveBlock,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const processingEntity = useAppSelector(getProcessingEntity)!;
  const isDirectionalInputActive = processingEntity.active;

  const { updateUserBuildingStoreyData, updateUserBuildingDrawMode } =
    useUpdateUserBuildingData();

  const [extrudeFloorShape, setExtrudeFloorShape] = useState<THREE.Mesh>(null!);
  const [floorContour, setFloorContour] = useState<THREE.Line>(null!);
  const [firstDynamicWall, setFirstDynamicWall] = useState<THREE.Mesh>(null!);
  const [secondDynamicWall, setSecondDynamicWall] = useState<THREE.Mesh>(null!);
  const [extrudeDynamicWall, setExtrudeDynamicWall] = useState<THREE.Mesh>(
    null!
  );
  const [restWalls, setRestWalls] = useState<JSX.Element[]>([]);
  const storeyHeight = storey.ceiling.points[0][1] - storey.floor.points[0][1];

  useEffect(() => {
    if (maxNegativeDistance) {
      const distanceInMillimeters = Number(
        convertMetersToMillimeters(maxNegativeDistance / multiplyRate)
      );
      const maxNegativeDistanceInMillimeters = Number(
        distanceInMillimeters.toFixed(0)
      );

      dispatch(
        setDirectionalInputValues([
          {
            ...processingEntity,
            min: maxNegativeDistanceInMillimeters,
            max: MetricLimits.WidthLengthMax,
          },
        ])
      );
    }
  }, [maxNegativeDistance]);

  const ceiling = useMemo(() => {
    return extrudeFloorShape?.clone();
  }, [extrudeFloorShape]);
  const ceilingContour = useMemo(() => {
    return floorContour?.clone();
  }, [floorContour]);

  const floorPoints = storey.floor.points;
  const floorHeight = storey.ceiling.points[0][1];

  const extrudedWallData = storey.walls.find(
    (wall) => wall.guid === extrudeNode.node.guid
  );

  const restWallsPoints = useMemo(
    () => storey.walls.filter((wall) => wall.guid !== extrudeNode.node.guid),
    [storey, extrudeNode]
  );

  const storeyWalls = useMemo(() => [...storey.walls], [storey]);

  const firstClosestWall = useMemo(() => {
    const extrudedWallIndex = storeyWalls.findIndex(
      (wall) => wall.guid === extrudeNode.node.guid
    );
    const index =
      extrudedWallIndex === storeyWalls.length - 1 ? 0 : extrudedWallIndex + 1;

    return storeyWalls[index];
  }, [restWallsPoints, extrudedWallData, storey, storeyWalls]);

  const secondClosestWall = useMemo(() => {
    const extrudedWallIndex = storeyWalls.findIndex(
      (wall) => wall.guid === extrudeNode.node.guid
    );
    const index =
      extrudedWallIndex === 0 ? storeyWalls.length - 1 : extrudedWallIndex - 1;
    return storeyWalls[index];
  }, [restWallsPoints, extrudedWallData, storey, storeyWalls]);

  const getIntersectionPointsForWall = (wall: UserBuildingWall) => {
    if (!extrudedWallData) return null;
    const intersectionPoint = convertFlatVector3ToVector(
      extrudedWallData.points.find((point) =>
        wall.points.find((closestWallPoint) => isEqual(closestWallPoint, point))
      )!
    );

    const directionPoint = convertFlatVector3ToVector(
      wall.points.find(
        (point) =>
          point[1] === intersectionPoint.y &&
          point[0] !== intersectionPoint.x &&
          point[2] !== intersectionPoint.z
      )!
    );

    const extrudeWallPoints = extrudedWallData!.points.find(
      (point) =>
        point[1] === intersectionPoint.y &&
        point[0] !== intersectionPoint.x &&
        point[2] !== intersectionPoint.z
    )!;

    const isFlatDirection = isPointsInOneLine(
      [directionPoint.x, directionPoint.z],
      [intersectionPoint.x, intersectionPoint.z],
      [extrudeWallPoints[0], extrudeWallPoints[2]]
    );

    const perpendicularDirectionNormalized = getPerpendicularVectorToVectors(
      [
        convertFlatVector3ToVector(extrudedWallData!.points[0]),
        convertFlatVector3ToVector(extrudedWallData!.points[1]),
      ],
      !isBuildingClockwise
    );
    const perpendicularDirection = new THREE.Vector3(
      intersectionPoint.x + perpendicularDirectionNormalized.x,
      intersectionPoint.y + perpendicularDirectionNormalized.y,
      intersectionPoint.z + perpendicularDirectionNormalized.z
    );

    perpendicularDirection.setY(intersectionPoint.y);

    if (isFlatDirection) {
      restWallsPoints.push({ ...wall, guid: uuidv7() });
    }

    return {
      directionPoint: isFlatDirection ? perpendicularDirection : directionPoint,
      intersectionPoint,
      staticPoint: directionPoint,
      isFlatDirection,
    };
  };

  const isBuildingClockwise = useMemo(() => {
    const clockwiseRing = lineString(
      floorPoints.map((point) => [point[0], point[2]])
    );
    return booleanClockwise(clockwiseRing);
  }, [floorPoints]);

  const intersectedPointsForFirstWall = useMemo(
    () => getIntersectionPointsForWall(firstClosestWall),
    [firstClosestWall, extrudedWallData]
  );

  const intersectedPointsForSecondWall = useMemo(
    () => getIntersectionPointsForWall(secondClosestWall),
    [secondClosestWall, extrudedWallData]
  );

  const perpendicularDirectionForWallPoints = useMemo(() => {
    if (!extrudedWallData) return new THREE.Vector3();

    const min = Math.min(...extrudedWallData.points.map((c) => c[1]));
    const bottomCoordinates = extrudedWallData.points.filter(
      (points) => points[1] === min
    );
    return getPerpendicularVectorToVectors(
      convertFlatVector3ToVectors(bottomCoordinates),
      isBuildingClockwise
    );
  }, [extrudedWallData, isBuildingClockwise]);

  const startExtruding = () => {
    hideSelectionBoxArea();

    if (!extrudeFloorShape) {
      const geometry = new THREE.BufferGeometry().setFromPoints(
        convertFlatVector3ToVectors(floorPoints)
      );
      const material = C_FloorMaterial.clone();
      triangulateGeometryAndUpdate(
        geometry,
        convertFlatVector3ToVectors(floorPoints)
      );

      setExtrudeFloorShape(new THREE.Mesh(geometry, material));
    }

    if (!floorContour) {
      const geometry = new THREE.BufferGeometry().setFromPoints(
        convertFlatVector3ToVectors(floorPoints)
      );

      const line = new THREE.Line(geometry, C_BasicLineMaterial.clone());
      setFloorContour(line);
    }

    if (!restWalls.length) {
      const walls = restWallsPoints
        .filter(
          (wall) =>
            (wall.guid !== firstClosestWall?.guid ||
              (wall.guid === firstClosestWall.guid &&
                intersectedPointsForFirstWall?.isFlatDirection)) &&
            (wall.guid !== secondClosestWall?.guid ||
              (wall.guid === secondClosestWall.guid &&
                intersectedPointsForSecondWall?.isFlatDirection))
        )
        .map((wall) =>
          generateStaticWall(
            convertFlatVector3ToVectors(wall.points),
            wall.guid
          )
        );
      setRestWalls(walls);
    }

    dispatch(
      setShowDirectionalInput({
        isShow: true,
      })
    );
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.Distance,
          display: true,
          processing: true,
        },
      ])
    );
  };
  const extrudeWall = () => {
    if (!isDirectionalInputActive) {
      if (
        !cursorPosition ||
        (cursorPosition?.x === 0 &&
          cursorPosition?.y === 0 &&
          cursorPosition?.z === 0)
      )
        return;
      if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
        return;

      cursorPosition.setY(intersectedPointsForFirstWall.intersectionPoint.y);

      const distanceBetweenMouseAndWall =
        getDistanceBetweenInfiniteLineAndVector(
          new THREE.Line3(
            intersectedPointsForFirstWall.intersectionPoint,
            intersectedPointsForSecondWall.intersectionPoint
          ),
          cursorPosition
        );

      const isCursorFromLeftSide = isVectorLeftSide(
        intersectedPointsForFirstWall.intersectionPoint,
        intersectedPointsForSecondWall.intersectionPoint,
        cursorPosition
      );

      const distanceFromCursor = isCursorFromLeftSide
        ? distanceBetweenMouseAndWall
        : -distanceBetweenMouseAndWall;

      const distance = !isBuildingClockwise
        ? distanceFromCursor
        : -distanceFromCursor;

      if (maxPositiveDistance && distance > maxPositiveDistance) {
        fixIsMaxDistanceNotApplied(maxPositiveDistance);
        return;
      }
      if (maxNegativeDistance && distance < maxNegativeDistance) {
        fixIsMaxDistanceNotApplied(maxNegativeDistance);
        return;
      }

      updateDirectionalInputValues(distance);
      extrudeFloor(distance);
    } else {
      if (processingEntity.type === DistanceInput.Distance) {
        extrudeFloor(
          convertMillimetersToMeters(processingEntity.value) * multiplyRate
        );
      }
    }
  };

  const calculateDistance = (
    directionPoint: THREE.Vector3,
    intersectionPoint: THREE.Vector3,
    distance: number
  ) => {
    const direction = new THREE.Vector3()
      .subVectors(intersectionPoint, directionPoint)
      .normalize();

    const angleToNormal =
      perpendicularDirectionForWallPoints.angleTo(direction);

    const restrictedDistance = getRestrictedDistance(distance);
    return angleToNormal > 1.57 ? restrictedDistance : -restrictedDistance;
  };

  const intersectPointForLines = useMemo(() => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return new THREE.Vector3();
    const firstLineEnd = getExtendedVector(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForFirstWall.directionPoint,
      -1000
    );
    const secondLineEnd = getExtendedVector(
      intersectedPointsForSecondWall.intersectionPoint,
      intersectedPointsForSecondWall.directionPoint,
      -1000
    );
    const firstLineStart = getExtendedVector(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForFirstWall.directionPoint,
      1000
    );
    const secondLineStart = getExtendedVector(
      intersectedPointsForSecondWall.intersectionPoint,
      intersectedPointsForSecondWall.directionPoint,
      1000
    );

    const intersect = checkLineIntersection(
      firstLineStart,
      firstLineEnd,
      secondLineStart,
      secondLineEnd
    );

    return new THREE.Vector3(
      intersect.x ?? Infinity,
      secondLineEnd.y,
      intersect.y ?? Infinity
    );
  }, [intersectedPointsForSecondWall, intersectedPointsForFirstWall]);

  const getMaxDistanceForWallExtrude = (
    intersectionPoint: THREE.Vector3,
    directionPoint: THREE.Vector3
  ) => {
    const vector = getExtendedVector(intersectionPoint, directionPoint, 0);
    return intersectPointForLines.distanceTo(vector);
  };

  const geometryVectorPositions = useMemo(() => {
    if (!extrudeFloorShape) return null;
    const position = [...floorPoints];
    const firstWall: number[] = [];
    const secondWall: number[] = [];
    for (let i = 0; i < position.length; i++) {
      const indexForFirstWall = new THREE.Vector3(
        position[i][0],
        position[i][1],
        position[i][2]
      ).equals(
        intersectedPointsForFirstWall?.intersectionPoint ?? new THREE.Vector3()
      );
      indexForFirstWall && firstWall.push(i);
      const indexForSecondWall = new THREE.Vector3(
        position[i][0],
        position[i][1],
        position[i][2]
      ).equals(
        intersectedPointsForSecondWall?.intersectionPoint ?? new THREE.Vector3()
      );
      indexForSecondWall && secondWall.push(i);
    }

    return {
      firstWall,
      secondWall,
    };
  }, [floorContour]);

  const getRestrictedDistance = (distance: number) => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return distance;

    const isExtrudeIn = distance < 0;

    const isFirstWallDirectionPointBehind =
      isVectorLeftSide(
        intersectedPointsForFirstWall.intersectionPoint,
        intersectedPointsForSecondWall.intersectionPoint,
        intersectedPointsForFirstWall.directionPoint
      ) == isBuildingClockwise;

    const isSecondWallDirectionPointBehind =
      isVectorLeftSide(
        intersectedPointsForFirstWall.intersectionPoint,
        intersectedPointsForSecondWall.intersectionPoint,
        intersectedPointsForSecondWall.directionPoint
      ) === isBuildingClockwise;

    const firstWallDistance = isFirstWallDirectionPointBehind
      ? -intersectedPointsForFirstWall.directionPoint.distanceTo(
          intersectedPointsForFirstWall.intersectionPoint
        )
      : -999;

    const secondWallDistance = isSecondWallDirectionPointBehind
      ? -intersectedPointsForSecondWall.directionPoint.distanceTo(
          intersectedPointsForSecondWall.intersectionPoint
        )
      : -999;

    setMaxNegativeDistance &&
      setMaxNegativeDistance(Math.max(firstWallDistance, secondWallDistance));

    return isExtrudeIn
      ? Math.max(
          distance,
          ...(maxNegativeDistance
            ? [maxNegativeDistance]
            : [firstWallDistance, secondWallDistance])
        )
      : distance;
  };

  const calculateTranslatedVector = (
    intersectionPoint: THREE.Vector3,
    directionPoint: THREE.Vector3,
    distance: number
  ) => {
    const translatedVector = getExtendedVector(
      intersectionPoint,
      directionPoint,
      calculateDistance(directionPoint, intersectionPoint, distance)
    );

    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return translatedVector;

    const maxDistance = getMaxDistanceForWallExtrude(
      intersectionPoint,
      directionPoint
    );

    const isExtrudeOutDirection =
      isVectorLeftSide(
        intersectedPointsForSecondWall.intersectionPoint,
        intersectedPointsForFirstWall.intersectionPoint,
        translatedVector
      ) === isBuildingClockwise;

    const isIntersectionPointAtCurrentDirection = isVectorLeftSide(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForSecondWall.intersectionPoint,
      intersectPointForLines
    );

    const isPointIntersected =
      maxDistance < distance &&
      isExtrudeOutDirection &&
      isIntersectionPointAtCurrentDirection;

    setMaxPositiveDistance && setMaxPositiveDistance(maxDistance);

    return isPointIntersected ? intersectPointForLines : translatedVector;
  };

  const addExtraPointForFlatWall = (
    position: FlatVector3[],
    index: number,
    point: THREE.Vector3,
    isRightSidePoint: boolean
  ) => {
    const idx = isRightSidePoint ? index : index + 1;
    return [...position.slice(0, idx), getXYZ(point), ...position.slice(idx)];
  };

  const extrudeFloor = (distance: number) => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return;

    const firstTranslatedVector = calculateTranslatedVector(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForFirstWall.directionPoint,
      distance
    );

    const secondTranslatedVector = calculateTranslatedVector(
      intersectedPointsForSecondWall.intersectionPoint,
      intersectedPointsForSecondWall.directionPoint,
      distance
    );

    let position = [...floorPoints];

    geometryVectorPositions?.firstWall.forEach((index) => {
      position[index] = [
        firstTranslatedVector.x,
        firstTranslatedVector.y,
        firstTranslatedVector.z,
      ];
    });
    geometryVectorPositions?.secondWall.forEach((index) => {
      position[index] = [
        secondTranslatedVector.x,
        secondTranslatedVector.y,
        secondTranslatedVector.z,
      ];
    });

    //add point for flat extruded walls
    if (
      intersectedPointsForFirstWall.isFlatDirection &&
      geometryVectorPositions
    ) {
      position = addExtraPointForFlatWall(
        position,
        geometryVectorPositions.firstWall[0],
        intersectedPointsForFirstWall.intersectionPoint,
        false
      );
    }

    //add point for flat extruded walls
    if (
      intersectedPointsForSecondWall.isFlatDirection &&
      geometryVectorPositions
    ) {
      position = addExtraPointForFlatWall(
        position,
        geometryVectorPositions.secondWall[0],
        intersectedPointsForSecondWall.intersectionPoint,
        true
      );
    }

    //remove a point from a contour when extruding out, and extruded wall compressed at one point
    if (firstTranslatedVector.distanceTo(secondTranslatedVector) === 0) {
      const mergedPointIndex = position.findIndex((point) =>
        isEqual(point, getXYZ(firstTranslatedVector))
      );
      position.splice(mergedPointIndex, 1);
    }

    //remove a point from a contour when extruding in

    if (
      firstTranslatedVector.distanceTo(
        intersectedPointsForFirstWall.directionPoint
      ) < 0.000001
    ) {
      const mergedPointIndex = position.findIndex((point) =>
        isEqual(point, getXYZ(firstTranslatedVector))
      );
      position.splice(mergedPointIndex, 1);
    }

    //remove a point from a contour when extruding in
    if (
      secondTranslatedVector.distanceTo(
        intersectedPointsForSecondWall.directionPoint
      ) < 0.000001
    ) {
      const mergedPointIndex = position.findIndex((point) =>
        isEqual(point, getXYZ(secondTranslatedVector))
      );
      position.splice(mergedPointIndex, 1);
    }

    //contour adjustment when the double point has shrunk and the contour remains unclosed
    if (isEqual(position[position.length], position[position.length])) {
      position.splice(position.length - 1, 1);
      position.push(position[0]);
    }

    floorContour.geometry.setFromPoints(convertFlatVector3ToVectors(position));

    generateDynamicWalls(firstTranslatedVector, secondTranslatedVector);

    extrudeFloorShape.geometry.setFromPoints(
      convertFlatVector3ToVectors(position)
    );
    triangulateGeometryAndUpdate(
      extrudeFloorShape.geometry,
      convertFlatVector3ToVectors(position)
    );
  };

  const resetExtrudeMode = () => {
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(setExtrudeNode(undefined));
    dispatch(resetExternalElementsState());
    dispatch(
      setDirectionalInputValues([
        { type: DistanceInput.Distance, processing: true },
      ])
    );
  };

  const keydownEvent = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        finishExtruding();
        break;
      }
      case 'Escape': {
        resetExtrudeMode();
        break;
      }
    }
  };

  const generateDynamicWalls = (
    firstTranslatedVector: THREE.Vector3,
    secondTranslatedVector: THREE.Vector3
  ) => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return;

    const staticPointForFirstWall =
      intersectedPointsForFirstWall.isFlatDirection
        ? intersectedPointsForFirstWall.intersectionPoint
        : intersectedPointsForFirstWall.staticPoint;

    const staticPointForSecondWall =
      intersectedPointsForSecondWall.isFlatDirection
        ? intersectedPointsForSecondWall.intersectionPoint
        : intersectedPointsForSecondWall.staticPoint;

    const pointForFirstWall = [
      staticPointForFirstWall,
      firstTranslatedVector,
      firstTranslatedVector.clone().setY(floorHeight),
      staticPointForFirstWall.clone().setY(floorHeight),
    ];
    const pointForSecondWall = [
      staticPointForSecondWall,
      secondTranslatedVector,
      secondTranslatedVector.clone().setY(floorHeight),
      staticPointForSecondWall.clone().setY(floorHeight),
    ];

    const pointForCenterWall = [
      secondTranslatedVector,
      firstTranslatedVector,
      firstTranslatedVector.clone().setY(floorHeight),
      secondTranslatedVector.clone().setY(floorHeight),
    ];

    handleDynamicWall(
      firstDynamicWall,
      pointForFirstWall,
      setFirstDynamicWall,
      true
    );
    handleDynamicWall(
      secondDynamicWall,
      pointForSecondWall,
      setSecondDynamicWall,
      true
    );
    handleDynamicWall(
      extrudeDynamicWall,
      pointForCenterWall,
      setExtrudeDynamicWall,
      true,
      true
    );

    const handlerDotPosition = getCenterFromVectorsArray([
      firstTranslatedVector,
      secondTranslatedVector,
    ]).setY(extrudeNode.defaultCenter[1]);

    setHandlerPosition &&
      setHandlerPosition(
        getTranslatedVector(
          handlerDotPosition,
          0.015,
          perpendicularDirectionForWallPoints
        )
      );
  };

  const handleDynamicWall = (
    wall: THREE.Mesh,
    points: THREE.Vector3[],
    setter: (value: React.SetStateAction<THREE.Mesh>) => void,
    withBorders?: boolean,
    highlightWall?: boolean
  ) => {
    const closedPoints = [...points, points[0]];

    if (!wall) {
      const geometry = createGeometryFromVectorList(points, 'vertical');
      const material = highlightWall
        ? C_WallSelectedMaterial.clone()
        : C_WallMaterial.clone();
      const borderMaterial = C_FatLineBorderMaterial.clone();
      if (highlightWall) {
        borderMaterial.color = BUILDING_SELECTED_CONTOUR_COLOR;
        borderMaterial.polygonOffset = true;
        borderMaterial.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.LOW;
        material.polygonOffset = true;
        material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.LOW;
      }

      const mesh = new THREE.Mesh(geometry, material);
      withBorders &&
        mesh.add(
          createLine2(
            flatten(closedPoints.map((point) => [point.x, point.y, point.z])),
            borderMaterial
          )
        );
      setter(mesh);
    } else {
      wall.geometry.setFromPoints(points);
      triangulateGeometryAndUpdate(wall.geometry, points, 'vertical');
      withBorders &&
        updateLine2Position(
          wall.children[0] as Line2,
          flatten(closedPoints.map((point) => [point.x, point.y, point.z]))
        );
    }
  };

  const generateStaticWall = (points: THREE.Vector3[], key?: string) => {
    const geometry = createGeometryFromVectorList(
      [...points, points[0]],
      'vertical'
    );

    const material = C_WallMaterial.clone();
    return (
      <mesh geometry={geometry} material={material} key={key}>
        <Border geometry={geometry} />
      </mesh>
    );
  };

  const fixIsMaxDistanceNotApplied = (distance: number) => {
    if (
      convertMetersToMillimeters(
        (distance / multiplyRate).toFixed(2).toString()
      ).toString() !== processingEntity.value
    ) {
      updateDirectionalInputValues(distance);
      extrudeFloor(distance);
    }
  };

  const validateWall = (points: THREE.Vector3[], wallPoints: FlatVector3[]) => {
    const isWallSameByAmountOfPoints =
      points.reduce((acc, curr) => {
        const isPointExist = wallPoints.some((point) =>
          isEqual(
            [curr.x.toFixed(5), curr.z.toFixed(5)],
            [point[0].toFixed(5), point[2].toFixed(5)]
          )
        );
        return isPointExist ? acc + 1 : acc;
      }, 0) === 4;

    const isWallIsNotCompressed = points.some((point, currentIndex) => {
      const index = currentIndex === points.length - 1 ? 0 : currentIndex;
      return point.distanceTo(points[index + 1]) === 0;
    });

    return isWallSameByAmountOfPoints && !isWallIsNotCompressed;
  };

  const finishExtruding = (event?: PointerEvent) => {
    event?.stopPropagation();
    if (event && !isLeftClick(event)) return;

    //TODO: Delete, when math will be refactoring
    const newFloorPoints = convertBufferGeometryTo3DVectorList(
      floorContour.geometry,
      floorContour.geometry.getAttribute('position').count
    ).map((point) => point.setY(storey.floor.points[0][1]));

    const newCeilingPoints = newFloorPoints.map((point) =>
      point.clone().setY(storey.ceiling.points[0][1])
    );

    const newWalls: UserBuildingWall[] = [];

    for (let i = 0; i < newFloorPoints.length - 1; i++) {
      const wallPoints: FlatVector3[] = [
        getXYZ(newFloorPoints[i]),
        getXYZ(newFloorPoints[i + 1]),
        getXYZ(newCeilingPoints[i + 1]),
        getXYZ(newCeilingPoints[i]),
      ];
      const isExtrudedWall =
        extrudeDynamicWall?.geometry &&
        validateWall(
          convertBufferGeometryTo3DVectorList(
            extrudeDynamicWall.geometry,
            extrudeDynamicWall.geometry.getAttribute('position').count
          ),
          wallPoints
        );
      const isFirstWall =
        firstDynamicWall?.geometry &&
        validateWall(
          convertBufferGeometryTo3DVectorList(
            firstDynamicWall.geometry,
            firstDynamicWall.geometry.getAttribute('position').count
          ),
          wallPoints
        ) &&
        !intersectedPointsForFirstWall?.isFlatDirection;

      const isSecondWall =
        secondDynamicWall?.geometry &&
        validateWall(
          convertBufferGeometryTo3DVectorList(
            secondDynamicWall.geometry,
            secondDynamicWall.geometry.getAttribute('position').count
          ),
          wallPoints
        ) &&
        !intersectedPointsForSecondWall?.isFlatDirection;

      const existingData = storey.walls.find(
        (wall) => isEqual(wall.points, wallPoints)!
      );

      const wall: UserBuildingWall = {
        guid: uuidv7(),
        name: `Wall ${getAlphabetIndex(i)}`,
        gridLines: [],
        windowPlacements: [],
        userData: {},
        ...existingData,
        ...(isFirstWall ? firstClosestWall : {}),
        ...(isSecondWall ? secondClosestWall : {}),
        ...(isExtrudedWall ? extrudedWallData : {}),
        points: wallPoints,
        wallPanels: [],
      };

      newWalls.push(wall);
    }

    updateUserBuildingStoreyData({
      buildingGuid,
      blockGuid,
      storeyGuid: storey.guid,
      newStorey: {
        ...storey,
        ceiling: {
          ...storey.ceiling,
          points: newCeilingPoints.map((point) => getXYZ(point)),
        },
        floor: {
          ...storey.floor,
          points: newFloorPoints.map((point) => getXYZ(point)),
        },
        walls: newWalls,
      },
      saveStoreysLocally: isBlockMode,
      updateBlock: saveBlock,
    });

    updateUserBuildingDrawMode({
      buildingGuid,
      drawMode: DrawModes.FreeDraw,
    });

    resetExtrudeMode();
  };

  const updateDirectionalInputValues = (distance: number) => {
    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          value: convertMetersToMillimeters(
            (distance / multiplyRate).toString()
          ).toString(),
        },
      ])
    );
  };

  const onInputSet = (evt: CustomEvent) => {
    const distance = convertMillimetersToMeters(evt.detail) * multiplyRate;
    extrudeFloor(distance);
  };
  const onInputUpdate = (evt: CustomEvent) => {
    const distance = convertMillimetersToMeters(evt.detail) * multiplyRate;
    extrudeFloor(distance);
  };

  useEffect(() => {
    cursorPosition && extrudeFloorShape && floorContour && extrudeWall();
  }, [cursorPosition, extrudeFloorShape, floorContour]);

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    canvas?.addEventListener('pointerdown', finishExtruding);
    document.addEventListener('keydown', keydownEvent);
    subscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    return () => {
      document.removeEventListener('keydown', keydownEvent);
      canvas?.removeEventListener('pointerdown', finishExtruding);
      unsubscribe(DIRECTIONAL_INPUT__SET, onInputSet);
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    };
  }, [
    storey,
    extrudeNode,
    extrudeFloorShape,
    floorContour,
    intersectPointForLines,
    firstDynamicWall,
    secondDynamicWall,
    extrudeDynamicWall,
    isDirectionalInputActive,
    processingEntity,
    multiplyRate,
    intersectedPointsForSecondWall,
    intersectedPointsForFirstWall,
  ]);

  useEffect(() => {
    startExtruding();
  }, []);

  useEffect(() => {
    extrudeFloorShape && floorContour && extrudeFloor(0);
  }, [extrudeFloorShape, floorContour]);

  return (
    <group
      key={`group-${storey.guid}`}
      userData={{
        ...storey.userData,
        nodeType: NodeType.Storey,
      }}
    >
      {floorContour && <primitive object={floorContour} />}
      {extrudeFloorShape && (
        <primitive object={extrudeFloorShape} key={storey.floor.guid}>
          <primitive
            object={ceiling}
            position={new THREE.Vector3(0, storeyHeight, 0)}
            key={storey.ceiling.guid}
          >
            <primitive object={ceilingContour} />
          </primitive>
        </primitive>
      )}
      {firstDynamicWall && <primitive object={firstDynamicWall} />}
      {secondDynamicWall && <primitive object={secondDynamicWall} />}
      {extrudeDynamicWall && <primitive object={extrudeDynamicWall} />}
      {restWalls}
    </group>
  );
};

export default ExtrudeWall;
