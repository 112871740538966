import React from 'react';

const ColorsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99997 2.5C8.99997 2.22386 8.77611 2 8.49997 2C8.22383 2 7.99997 2.22386 7.99997 2.5V3.9996C7.79524 4.0718 7.60306 4.18978 7.43931 4.35352L2.35352 9.43931C1.76774 10.0251 1.76774 10.9748 2.35352 11.5606L6.68931 15.8964C7.2751 16.4822 8.22484 16.4822 8.81063 15.8964L13.8964 10.8106C14.4822 10.2248 14.4822 9.2751 13.8964 8.68931L9.56063 4.35352C9.39688 4.18978 9.2047 4.0718 8.99997 3.9996V2.5ZM7.99997 5.20708V6.5C7.99997 6.77614 8.22383 7 8.49997 7C8.77611 7 8.99997 6.77614 8.99997 6.5V5.20708L13.1893 9.39642C13.3529 9.56002 13.3794 9.80878 13.2689 10H3.20705L7.99997 5.20708ZM7.39642 15.1893L3.20711 11H12.2928L8.10352 15.1893C7.90826 15.3846 7.59168 15.3846 7.39642 15.1893ZM16.016 11.3922C15.7855 10.997 15.2145 10.997 14.984 11.3922L13.3316 14.2248C12.3554 15.8983 13.5626 18 15.5 18C17.4374 18 18.6445 15.8983 17.6683 14.2248L16.016 11.3922ZM14.1954 14.7287L15.5 12.4923L16.8045 14.7287C17.3919 15.7355 16.6656 17 15.5 17C14.3343 17 13.6081 15.7355 14.1954 14.7287Z"
        fill="#242424"
      />
    </svg>
  );
};

export default ColorsIcon;
