import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import {
  FlatVector2,
  GridLineData,
  NodeType,
  SelectedNode,
  WindowPlacementData,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getModifiedWalls,
  getSelectedGridlines,
  getSelectedPlacedWindows,
  resetSelectedGridLines,
  setHoveredPlacedWindow,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';

import { getFacadeDesignerMode } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';

import FacadeDesignerWallView from '@/components/FacadeDesigner/elements/FacadeDesignerWallView';
import { getMinMaxCoordinatesAtVector3 } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import { round } from 'mathjs';
import {
  convert2DPointsToDistanceInMeters,
  getWallHeight,
} from '@/shared/helpers/metrics';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import WallElevations from './elements/WallElevations';
import FacadeDesignerBackground from '@/components/FacadeDesigner/elements/FacadeDesignerBackground';

import { useGridLines } from '@/components/FacadeDesigner/hooks/useGridLines';
import { useParams } from 'react-router';
import GridLinesInformationMeasurements from './elements/GridLinesInformationMeasurements';
import WindowsInformationMeasurements from './elements/WindowsInformationMeasurements';

interface FacadeDesignerContainerProps {
  buildingGUID: string;
  selectedWalls: SelectedNode[];
  placementHeight: number;
  placementWidth: number;
  isProjectLocked: boolean;
}

const FacadeDesignerContainer: React.FC<FacadeDesignerContainerProps> = ({
  buildingGUID,
  selectedWalls,
  placementHeight,
  placementWidth,
  isProjectLocked,
}) => {
  Konva.dragButtons = [2];

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const modifiedWalls = useAppSelector(getModifiedWalls);
  const { updateWallWindowPlacements, updateBuildingPlacements } =
    useUpdateUserBuildingData();
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const selectedPlacedGridLines = useAppSelector(getSelectedGridlines);

  const { findDataForWall } = useFindNodeData();
  const { generateCustomGrids } = useGridLines(buildingGUID);

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  useEffect(() => {
    if (!isSelectionMode) {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
    }
  }, [isSelectionMode]);

  const wallsData: WallSearchResults[] = useMemo(() => {
    return selectedWalls.map((wall) => findDataForWall(wall.guid)!);
  }, [selectedWalls]);

  const allPlacedWindows: WindowPlacementData[] = useMemo(() => {
    return wallsData.flatMap((wall) => wall.windowPlacements || []);
  }, [selectedWalls]);

  const minMaxWallsCoordinates = useMemo(
    () =>
      getMinMaxCoordinatesAtVector3(
        wallsData.map((wall) => convertFlatVector3ToVectors(wall.points)).flat()
      ),
    [wallsData]
  );

  const xDirection = wallsData[0].points[0][0] <= wallsData[0].points[1][0];
  const zDirection = wallsData[0].points[0][2] <= wallsData[0].points[1][2];

  const wallsTotalHeight = round(
    Number(
      convertMetersToMillimeters(
        (minMaxWallsCoordinates.max.y - minMaxWallsCoordinates.min.y) /
          multiplyRate
      )
    ),
    2
  );

  const wallsTotalWidth = round(
    Number(
      convertMetersToMillimeters(
        convert2DPointsToDistanceInMeters(
          [minMaxWallsCoordinates.max.x, minMaxWallsCoordinates.max.z],
          [minMaxWallsCoordinates.min.x, minMaxWallsCoordinates.min.z],
          multiplyRate
        )
      )
    ),
    2
  );

  const scaleX = (placementWidth * 0.68) / wallsTotalWidth;
  const scaleY = (placementHeight - 100) / wallsTotalHeight;
  const scale = Math.min(scaleX, scaleY);

  const { wheelHandler } = useKonvaHandlers();

  const [stageParams, setStageParams] = useState({
    scale,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    //  Should not be called when width/height updates by user. Else it
    setStageParams({
      scale,
      x: placementWidth / 2 - (wallsTotalWidth * scale) / 2,
      y: placementHeight / 2 - (wallsTotalHeight * scale) / 2,
    });
  }, [modifiedWalls]);

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    const scaleData = wheelHandler(event, {
      maxScale: 1,
      minScale: Math.min(0.01, scale),
    });

    scaleData && setStageParams(scaleData);
  };

  const handleGridPlacement = () => {
    generateCustomGrids({ wallsData, startPoint });
  };

  const handleSaveWindowPlacements = (
    placements: WindowPlacementData[],
    wallData: WallSearchResults
  ) => {
    updateWallWindowPlacements({
      buildingGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Building
      )!.guid,
      blockGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Block
      )!.guid,
      storeyGUID: wallData.parentNodes.find(
        (node) => node.type === NodeType.Storey
      )!.guid,
      wallGUID: wallData.guid,
      windowPlacements: placements,
    });
  };

  const handleSaveWallPlacements = (
    newPlacements: {
      wallGUID: string;
      gridLines?: GridLineData[];
      windowPlacements?: WindowPlacementData[];
    }[]
  ) => {
    updateBuildingPlacements({
      buildingGUID,
      placementData: newPlacements,
    });
  };
  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (
        facadeDesignerMode === FacadeDesignerModes.Selection &&
        !isProjectLocked
      ) {
        const newPlacements: {
          wallGUID: string;
          gridLines?: GridLineData[];
          windowPlacements?: WindowPlacementData[];
        }[] = modifiedWalls.map((wallGUID) => {
          const newGridLines = wallsData
            .find((data) => data.guid === wallGUID)
            ?.gridLines.filter(
              (line) =>
                line.cornerAlign ||
                !selectedPlacedGridLines.some(
                  (selectedGridLine) => selectedGridLine.guid === line.guid
                )
            );
          const newWindowPlacements = wallsData
            .find((data) => data.guid === wallGUID)
            ?.windowPlacements.filter(
              (window) =>
                !selectedPlacedWindows.some(
                  (selectedWindow) => selectedWindow.guid === window.guid
                )
            );
          return {
            wallGUID,
            windowPlacements: newWindowPlacements,
            gridLines: newGridLines,
          };
        });

        handleSaveWallPlacements(newPlacements);

        dispatch(setSelectedPlacedWindow([]));
        dispatch(setHoveredPlacedWindow(null));
        dispatch(resetSelectedGridLines());
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [selectedPlacedWindows, wallsData, selectedPlacedGridLines]);

  const startPoint = {
    x: minMaxWallsCoordinates[xDirection ? 'max' : 'min'].x,
    z: minMaxWallsCoordinates[zDirection ? 'max' : 'min'].z,
  };

  const distanceToRightEdge = useMemo(() => {
    return Math.max(
      ...wallsData.map((wall) => {
        const wallBottomRightPoint: FlatVector2 = [
          wall.points[3][0],
          wall.points[3][2],
        ];
        return round(
          Number(
            convertMetersToMillimeters(
              convert2DPointsToDistanceInMeters(
                wallBottomRightPoint,
                [startPoint.x, startPoint.z],
                multiplyRate
              )
            )
          ),
          2
        );
      })
    );
  }, [wallsData, startPoint, multiplyRate]);

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (event.target.nodeType === 'Stage') {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
    }
  };

  const generateElevations = useCallback(() => {
    const uniqueWallsByY = wallsData.reduce(
      (acc, wall) =>
        acc.some((w) => w.points[0][1] === wall.points[0][1])
          ? acc
          : [...acc, wall],
      [] as WallSearchResults[]
    );

    return uniqueWallsByY.map((wall) => {
      const wallHeight = Number(
        convertMetersToMillimeters(getWallHeight(wall.points, multiplyRate))
      );
      const wallCoordinates = getMinMaxCoordinatesAtVector3(
        convertFlatVector3ToVectors(wall.points)
      );
      const wallOffsetY = round(
        Number(
          convertMetersToMillimeters(
            (wallCoordinates.max.y - minMaxWallsCoordinates.max.y) /
              multiplyRate
          )
        ),
        2
      );
      return (
        <WallElevations
          key={wall.guid}
          offsetY={wallOffsetY}
          distanceToRightEdge={distanceToRightEdge}
          wallHeight={wallHeight}
          scale={stageParams.scale}
          selectedWalls={selectedWalls}
          parentStoreyGuid={
            wall.parentNodes.find((p) => p.type === NodeType.Storey)!.guid
          }
          parentBlockGuid={
            wall.parentNodes.find((p) => p.type === NodeType.Block)!.guid
          }
        />
      );
    });
  }, [
    wallsData,
    minMaxWallsCoordinates,
    stageParams.scale,
    distanceToRightEdge,
  ]);

  return (
    <Stage
      onContextMenu={(e) => e.evt.preventDefault()}
      width={placementWidth}
      height={placementHeight}
      scaleX={stageParams.scale}
      scaleY={stageParams.scale}
      onClick={handleClick}
      x={stageParams.x}
      y={stageParams.y}
      draggable
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
    >
      <Layer>
        <FacadeDesignerBackground
          scale={scale}
          totalHeight={wallsTotalHeight}
          totalWidth={wallsTotalWidth}
          unitSystem={unitSystem}
        />
        {wallsData.map((wallData) => (
          <FacadeDesignerWallView
            facadeDesignerMode={facadeDesignerMode}
            startPoint={startPoint}
            scale={stageParams.scale}
            wallData={wallData}
            key={wallData.guid}
            minMaxWallsCoordinates={minMaxWallsCoordinates}
            onAddWindow={(data) => handleSaveWindowPlacements(data, wallData)}
            onMeasurementUpdate={(data) =>
              handleSaveWindowPlacements(data, wallData)
            }
            onGridPlacement={handleGridPlacement}
          />
        ))}
        {generateElevations()}
        <GridLinesInformationMeasurements
          wallsData={wallsData}
          scale={stageParams.scale}
          wallsTotalHeight={wallsTotalHeight}
          wallsTotalWidth={wallsTotalWidth}
          startPoint={startPoint}
        />
        {allPlacedWindows?.length > 0 && (
          <WindowsInformationMeasurements
            wallsData={wallsData}
            scale={stageParams.scale}
            startPoint={startPoint}
            wallsTotalWidth={wallsTotalWidth}
          />
        )}
      </Layer>
    </Stage>
  );
};

export default FacadeDesignerContainer;
