import React from 'react';

const MaterialIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 14.1667C9.16667 15.0507 8.81548 15.8986 8.19036 16.5237C7.56523 17.1488 6.71739 17.5 5.83333 17.5C4.94928 17.5 4.10143 17.1488 3.47631 16.5237C2.85119 15.8986 2.5 15.0507 2.5 14.1667V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5C7.94203 2.5 8.36595 2.67559 8.67851 2.98816C8.99107 3.30072 9.16667 3.72464 9.16667 4.16667V14.1667Z"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9167 10.834H15.8334C16.2754 10.834 16.6993 11.0096 17.0119 11.3221C17.3244 11.6347 17.5 12.0586 17.5 12.5007V15.834C17.5 16.276 17.3244 16.6999 17.0119 17.0125C16.6993 17.3251 16.2754 17.5007 15.8334 17.5007H5.83337M5.83337 14.1673H5.84171"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16667 6.66703L11.0833 4.75036C11.2694 4.56363 11.4906 4.41552 11.7341 4.31457C11.9776 4.21361 12.2387 4.1618 12.5024 4.16211C12.766 4.16242 13.0269 4.21485 13.2702 4.31637C13.5135 4.4179 13.7344 4.56652 13.92 4.7537L15.5 6.3337C15.6922 6.51854 15.8454 6.73994 15.9508 6.98487C16.0561 7.22981 16.1114 7.49334 16.1135 7.75997C16.1155 8.02659 16.0642 8.29093 15.9625 8.53743C15.8609 8.78394 15.711 9.00763 15.5217 9.19536L8.25 16.5004"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MaterialIcon;
