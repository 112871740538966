import React, { ReactNode } from 'react';

import { REPORT_HEIGHT, REPORT_WIDTH } from '@/models/report';
import ReportSidebar from './ReportSidebar';

const ReportWrapper = ({
  children,
  pageIndex,
  reportCreationDate,
  countOfPages,
  pageName,
}: {
  children: ReactNode;
  pageIndex?: number;
  reportCreationDate?: string;
  countOfPages?: number;
  pageName?: string;
}) => {
  const isReportRoute = location.pathname.endsWith('/report');
  const hasSidebar =
    pageIndex && reportCreationDate && countOfPages && pageName;
  return (
    <div
      className={`${isReportRoute ? 'box-content border border-solid border-black' : ''}  flex justify-between bg-white`}
      style={{
        width: `${REPORT_WIDTH}px`,
        height: `${REPORT_HEIGHT}px`,
      }}
    >
      {children}
      {hasSidebar && (
        <ReportSidebar
          pageIndex={pageIndex}
          reportCreationDate={reportCreationDate}
          countOfPages={countOfPages}
          pageName={pageName}
        />
      )}
    </div>
  );
};

export default ReportWrapper;
