import React from 'react';
import CubeAreaIcon from '@/shared/icons/CubeAreaIcon';
import PropertyList from '../PropertyList/PropertyList';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

interface TotalSurfaceProps {
  facadesArea: string;
  grossInternalArea?: string;
}

const TotalSurface: React.FC<TotalSurfaceProps> = ({
  facadesArea,
  grossInternalArea,
}) => {
  const { getAreaUnit } = useFrameProperties();

  const properties = [
    {
      name: 'Facades area',
      value: facadesArea,
      units: getAreaUnit(),
    },
  ];

  if (grossInternalArea) {
    properties.push({
      name: 'Gross internal area',
      value: grossInternalArea,
      units: getAreaUnit(),
    });
  }

  return (
    <div className="text-xs">
      <div className="px-3 pt-3 flex items-center justify-start gap-1 h-5">
        <CubeAreaIcon />
        <span className="font-medium ">TOTAL SURFACE</span>
      </div>
      <PropertyList properties={properties} />
    </div>
  );
};

export default TotalSurface;
