import React from 'react';
import { DirectionalInput } from '@/shared/elements';
import { useAppSelector } from '@/store/hooks';
import { getShowDirectionalInput } from '@/store/slices/canvasExternalElementsSlice';
import PropertyPanel from './PropertyPanel';
import { useParams } from 'react-router';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { getSelectedNodes } from '@/store/slices/canvasBuildingSlice';
import LeftPanel from './LeftPanel/LeftPanel';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes, NodeType } from '@/models';
import FacadeDesigner from '@/components/FacadeDesigner/FacadeDesigner';
import Toolbar from '@/components/Toolbar';

const CanvasExternalElements = () => {
  const { id } = useParams();

  const showDirectionalInput = useAppSelector(getShowDirectionalInput);
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const selectedNodes = Object.values(useAppSelector(getSelectedNodes));
  const mode = useAppSelector(getCanvasMode);

  const isDesignerMode = mode === CanvasActionsModes.facadeDesigner;

  return (
    <>
      {!isDesignerMode && <Toolbar />}
      <LeftPanel />
      {showDirectionalInput && (
        <DirectionalInput size="small" userUnitType={unitSystem} />
      )}
      <PropertyPanel selectedNodes={selectedNodes} />
      {isDesignerMode && (
        <FacadeDesigner
          selectedWalls={selectedNodes.filter(
            (node) => node.type === NodeType.Wall
          )}
        />
      )}
    </>
  );
};

export default CanvasExternalElements;
