import {
  addSelectedPlacedWindow,
  addToSelectedGridLines,
  getFacadeDesignerMode,
  resetSelectedGridLines,
  setSelectedGridLines,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FacadeDesignerModes } from '@/models/shared.model';
import { WindowPlacementData } from '@/models';
import { GridLineDataForFD } from '@/components/FacadeDesigner/models';

export const useFacadeDesignerSelection = () => {
  const dispatch = useAppDispatch();

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const handleSelectWindow = ({
    multiSelect,
    data,
  }: {
    multiSelect: boolean;
    data: WindowPlacementData;
  }) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedPlacedWindow([data]));
      dispatch(resetSelectedGridLines());
    } else {
      dispatch(addSelectedPlacedWindow(data));
    }
  };
  const handleSelectGridLine = ({
    multiSelect,
    data,
  }: {
    multiSelect: boolean;
    data: GridLineDataForFD;
  }) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedGridLines([data]));
      dispatch(setSelectedPlacedWindow([]));
    } else {
      dispatch(addToSelectedGridLines(data));
    }
  };

  return { handleSelectGridLine, handleSelectWindow };
};
