import { MouseEvent as ReactMouseEvent } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectOneNode,
  clearSelectedNodes,
  addToSelectNode,
  removeFromSelectNode,
  removeFromSelectedNodeArray,
  getExtrudeNode,
} from '@/store/slices/canvasBuildingSlice';
import {
  CanvasActionsModes,
  EditModes,
  NodeType,
  SelectedNode,
  SelectedNodeSource,
} from '@/models';
import { getCanvasMode, getEditMode } from '@/store/slices/canvasModesSlice';
import { ThreeEvent } from '@react-three/fiber';
import { isLeftClick } from '@/shared/helpers';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';

export const useSelectedNodes = () => {
  const dispatch = useAppDispatch();
  const canvasMode = useAppSelector(getCanvasMode);
  const isSelectionMode = canvasMode === CanvasActionsModes.selection;
  const isExtrudeMode = !!useAppSelector(getExtrudeNode);
  const isEditMode = useAppSelector(getEditMode) !== EditModes.Unset;

  const isAllowedToSelect = !isExtrudeMode && !isEditMode;

  const { getNodeData } = useFindNodeData();
  const selectNode = ({
    event,
    type,
    guid,
    source = SelectedNodeSource.Viewer,
    isLocked,
    isSelected,
    ignoreSelectionMode,
  }: {
    event?: ThreeEvent<PointerEvent> | ReactMouseEvent | MouseEvent;
    type: NodeType;
    guid: string;
    source?: SelectedNodeSource;
    isLocked: boolean;
    isSelected: boolean;
    ignoreSelectionMode?: boolean;
  }) => {
    event?.stopPropagation();
    if (event && !isLeftClick(event) && source !== SelectedNodeSource.Panel)
      return;
    if ((isSelectionMode || ignoreSelectionMode) && isAllowedToSelect) {
      const selectedNodePayload: SelectedNode = {
        type,
        guid,
        source,
      };

      if (!event?.shiftKey) {
        if (event && !isLeftClick(event) && isSelected) return;
        if (isLocked && source === SelectedNodeSource.Viewer) {
          dispatch(clearSelectedNodes());
          return;
        }
        dispatch(selectOneNode(selectedNodePayload));
        return;
      }

      if (isLocked && source === SelectedNodeSource.Viewer) return;

      if (isSelected && isLeftClick(event)) {
        dispatch(removeFromSelectNode(guid));
        return;
      }

      if (event?.shiftKey && isLeftClick(event)) {
        const nodeData = getNodeData({
          guid,
          nodeType: type,
        });

        nodeData?.parentNodes?.length &&
          dispatch(
            removeFromSelectedNodeArray(
              nodeData.parentNodes.map((node) => node.guid)
            )
          );
        nodeData?.childNodes?.length &&
          dispatch(
            removeFromSelectedNodeArray(
              nodeData.childNodes.map((node) => node.guid)
            )
          );

        nodeData && dispatch(addToSelectNode(selectedNodePayload));
        return;
      }

      dispatch(selectOneNode(selectedNodePayload));
    }
  };

  const clearAllSelectedNodes = () => {
    dispatch(clearSelectedNodes());
  };
  const addNodesToSelectedNodes = (nodeArray: SelectedNode[]) => {
    nodeArray.forEach((node) => {
      dispatch(addToSelectNode(node));
    });
  };

  return {
    clearAllSelectedNodes,
    addNodesToSelectedNodes,
    selectNode,
  };
};
