import React from 'react';

const FixedOperationTypeIcon = ({ size = '40' }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7H33V20V33H20H7V7Z"
        fill="#CAD7FF"
        fillOpacity="0.3"
        stroke="#B3B2B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 4H4V36H36V4ZM32.8 7.2H7.2V32.8H32.8V7.2Z"
        fill="white"
        stroke="#B3B2B4"
      />
    </svg>
  );
};

export default FixedOperationTypeIcon;
