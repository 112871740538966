import React from 'react';
import { Progress, ProgressProps } from 'antd';

import './AntdProgressBar.scss';

const IntusProgressBar: React.FC<ProgressProps> = ({ ...rest }) => {
  return <Progress {...rest} />;
};

export default IntusProgressBar;
