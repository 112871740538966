import React, { useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import {
  convertBufferGeometryTo3DVectorList,
  createLine2,
  generateLineGeometry,
  getDistanceBetweenInfiniteLineAndVector,
  getExtendedVector,
  getGeometryPointByIdx,
  getGeometryVectorPointByIdx,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  getXYZ,
  isVectorLeftSide,
  setGeometryPointPositionByIdx,
  setObjectPosition,
  triangulateGeometryAndUpdate,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  C_DashedFatLineContourMaterial,
  C_FatLineContourMaterial,
  C_FloorMaterial,
  C_LineContourMaterial,
} from '@/shared/materials';
import {
  getProcessingEntity,
  resetExternalElementsState,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import { DistanceInput, NumberedInput } from '@/models';
import {
  generateEdgePoint,
  pointTargetOnMap,
  PROJECT_CANVAS_ID,
} from '@/shared/helpers/canvas-verifiers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { hideSelectionBoxArea, isLeftClick } from '@/shared/helpers';
import { RootState, useThree } from '@react-three/fiber';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { getMapUUID } from '@/store/slices/canvasMapSlice';
import { useParams } from 'react-router';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import { publish, subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__ESCAPE,
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
  SET_BUILDING_CREATION_PARAMETERS,
} from '@/core/event-names';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import ScalableDot from '@/shared/components/Dot/ScalableDot';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  FLOORS_INITIAL_COUNT,
  INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS,
} from '@/shared/constants';
import { getMultiplyRate } from '@/store/slices/projectSlice';

const RectangleDrawMode = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const createdBuildingFromStore = !!useFetchProjectQuery(id!).data?.buildings
    ?.length;

  const mapUUID = useAppSelector(getMapUUID);
  const processingEntity = useAppSelector(getProcessingEntity)!;
  const isDirectionalInputActive = processingEntity.active;
  const [pointerPosition, setPointerPosition] = useState<THREE.Vector3 | null>(
    null
  );
  const [floorShape, setFloorShape] = useState<THREE.Mesh>(null!);
  const [finishedBuilding, setFinishedBuilding] = useState<THREE.Mesh | null>(
    null
  );
  const [nextPoint, setNextPoint] = useState<THREE.Mesh>(null!);

  const [initialLine, setInitialLine] = useState<THREE.Line | null>(null);
  const [secondaryLine, setSecondaryLine] = useState<THREE.Line | null>(null);

  const [fatInitialLine, setFatInitialLine] = useState<Line2>(null!);
  const [fatSecondLine, setFatSecondLine] = useState<Line2>(null!);
  const [closingLines, setClosingLines] = useState<Line2[]>([]);

  const [isDrawing, setIsDrawing] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isBuildingFinished, setIsBuildingFinished] = useState(false);

  const scene: RootState = useThree();
  const edges: THREE.Vector3[] = useMemo(() => [], []);
  const edgeOnPointer = useMemo(() => {
    const liveEdge = generateEdgePoint();
    !createdBuildingFromStore && setNextPoint(liveEdge);
    return liveEdge;
  }, [createdBuildingFromStore]);

  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const buildingFloorMaterial = useMemo(() => C_FloorMaterial.clone(), []);

  const updateDirectionalInputValueForDistance = () => {
    let distance: number;
    if (isClosing) {
      distance = getGeometryVectorPointByIdx(
        0,
        initialLine!.geometry
      ).distanceTo(getGeometryVectorPointByIdx(0, secondaryLine!.geometry));
    } else {
      distance = getGeometryVectorPointByIdx(
        0,
        initialLine!.geometry
      ).distanceTo(getGeometryVectorPointByIdx(1, initialLine!.geometry));
    }
    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          value: convertMetersToMillimeters(
            (distance / multiplyRate).toString()
          ).toString(),
        },
      ])
    );
  };

  const addNextPoint = (point: THREE.Vector3) => {
    if (!initialLine) {
      const geometry = generateLineGeometry(getXYZ(point), getXYZ(point));

      setInitialLine(new THREE.Line(geometry, C_LineContourMaterial));
      setSecondaryLine(new THREE.Line(geometry.clone(), C_LineContourMaterial));
      setIsDrawing(true);
      dispatch(
        setShowDirectionalInput({
          isShow: true,
        })
      );
      dispatch(
        setDirectionalInputValues([
          {
            type: DistanceInput.Width,
            display: true,
            processing: true,
          },
          {
            type: DistanceInput.Distance,
            display: false,
          },
        ])
      );
    } else if (!finishedBuilding) {
      setGeometryPointPositionByIdx(1, initialLine.geometry, getXYZ(point));
      dispatch(
        setDirectionalInputValues([
          {
            type: DistanceInput.Length,
            display: true,
            processing: true,
          },
        ])
      );
      setFirstFloor(true);
    } else {
      setIsBuildingFinished(true);
    }
    edges.push(point);
  };

  const handleAddingNextPoint = (point: THREE.Vector3) => {
    if (isClosing) {
      closeFirstFloor();
      return;
    }

    if (edges.length < 2) {
      addNextPoint(nextPoint.position);
    }
    if (edges.length > 1) {
      setIsClosing(true);

      // Creating 2 more edges for secondary Line
      edges.push(point, point);
    }
  };

  const onPointerDown = (event: PointerEvent) => {
    hideSelectionBoxArea();
    if (
      !isLeftClick(event) ||
      createdBuildingFromStore ||
      !pointTargetOnMap(event, scene, mapUUID)
    )
      return;

    handleAddingNextPoint(pointTargetOnMap(event, scene, mapUUID)!);
  };

  const closeFirstFloor = () => {
    if (!isClosing) return;

    (floorShape.material as THREE.Material).transparent = false;
    (floorShape.material as THREE.Material).needsUpdate = true;
    setIsDrawing(false);

    setFinishedBuilding(floorShape);
    publish(SET_BUILDING_CREATION_PARAMETERS, { floorShape });
    !!floorShape &&
      dispatch(
        setDirectionalInputValues([
          {
            type: NumberedInput.Floors,
            value: FLOORS_INITIAL_COUNT.toString(),
            processing: true,
            display: true,
          },
          {
            type: DistanceInput.FloorHeight,
            value: INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS.toString(),
            display: true,
          },
          { type: DistanceInput.Width, display: false },
          { type: DistanceInput.Length, display: false },
        ])
      );
  };

  const resetDraw = () => {
    setIsClosing(false);
    setIsDrawing(false);
    setPointerPosition(null);
    setIsBuildingFinished(false);
    setFinishedBuilding(null!);
    dispatch(resetExternalElementsState());
    dispatch(
      setDirectionalInputValues([
        { type: DistanceInput.Width, processing: true },
      ])
    );
    setFloorShape(null!);
    edges.length = 0;
  };

  const onKeydown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        if (isDrawing) {
          closeFirstFloor();
        }
        return;
      }
      case 'Escape': {
        resetDraw();
        return;
      }
    }
  };

  const updateEdges = () => {
    if (!secondaryLine || edges.length < 4 || !initialLine) return;

    edges[0] = convertFlatVector3ToVector(
      getGeometryPointByIdx(0, initialLine.geometry)
    );
    edges[1] = convertFlatVector3ToVector(
      getGeometryPointByIdx(1, initialLine.geometry)
    );

    edges[2] = convertFlatVector3ToVector(
      getGeometryPointByIdx(0, secondaryLine.geometry)
    );
    edges[3] = convertFlatVector3ToVector(
      getGeometryPointByIdx(1, secondaryLine.geometry)
    );
  };

  const setFirstFloor = (create: boolean = false) => {
    if (!initialLine || !secondaryLine) return;
    const floorGeometry = create
      ? new THREE.ShapeGeometry()
      : floorShape.geometry;
    const initialLineVectors = convertBufferGeometryTo3DVectorList(
      initialLine.geometry
    );
    const secondaryLineVectors = convertBufferGeometryTo3DVectorList(
      secondaryLine.geometry
    ).reverse();
    const vectors: THREE.Vector3[] = [
      ...initialLineVectors,
      ...secondaryLineVectors,
      // to close the contour
      initialLineVectors[0],
    ];
    floorGeometry.setFromPoints(vectors);
    floorGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(
        vectors.map((point) => [...getXYZ(point)]).flat(),
        3
      )
    );
    triangulateGeometryAndUpdate(floorGeometry, vectors);

    if (create) {
      const newFloorShape = new THREE.Mesh(
        floorGeometry,
        buildingFloorMaterial
      );
      if (floorShape) {
        scene.scene.remove(floorShape);
      }
      setFloorShape(newFloorShape);
    }
    if (isClosing) {
      updateEdges();
    }
  };

  const handleNextPointUpdate = (
    point: THREE.Vector3,
    isSquareMode = false
  ) => {
    if (!isClosing) {
      setObjectPosition(nextPoint, getXYZ(point));
    }

    if (!edges.length) {
      return;
    }

    if (processingEntity.type === DistanceInput.Width) {
      setGeometryPointPositionByIdx(1, initialLine!.geometry, getXYZ(point));
      initialLine!.computeLineDistances();
    }

    if (edges.length > 1) {
      const vectors: THREE.Vector3[] = convertBufferGeometryTo3DVectorList(
        initialLine!.geometry
      );
      const extendedPoint =
        processingEntity.type === DistanceInput.Width
          ? new THREE.Vector3(
              ...getGeometryPointByIdx(0, secondaryLine!.clone().geometry)
            )
          : point;
      const distance = isSquareMode
        ? vectors[0].distanceTo(vectors[1])
        : getDistanceBetweenInfiniteLineAndVector(
            new THREE.Line3(vectors[0], vectors[1]),
            extendedPoint
          );

      const normal = getPerpendicularVectorToVectors(
        vectors,
        isVectorLeftSide(vectors[0], vectors[1], extendedPoint)
      );
      const updatedStart = getTranslatedVector(
        new THREE.Vector3(
          ...getGeometryPointByIdx(0, initialLine!.clone().geometry)
        ),
        distance,
        normal
      );
      const updatedEnd = getTranslatedVector(
        new THREE.Vector3(
          ...getGeometryPointByIdx(1, initialLine!.clone().geometry)
        ),
        distance,
        normal
      );

      if (secondaryLine) {
        setGeometryPointPositionByIdx(
          0,
          secondaryLine.geometry,
          getXYZ(updatedStart)
        );
        setGeometryPointPositionByIdx(
          1,
          secondaryLine.geometry,
          getXYZ(updatedEnd)
        );
        secondaryLine.computeLineDistances();
        setFirstFloor(!floorShape);
      }
    }

    updateFatLines();
    updateClosingLines();
  };

  const updateFatLines = () => {
    if (!initialLine || !secondaryLine) return;
    const initialLinePosition = Array.from(
      initialLine.geometry.getAttribute('position').array
    );

    const secondaryLinePosition = Array.from(
      secondaryLine.geometry.getAttribute('position').array
    );

    if (!fatInitialLine) {
      setFatInitialLine(
        createLine2(initialLinePosition, C_FatLineContourMaterial)
      );
    } else {
      updateLine2Position(fatInitialLine, initialLinePosition);
    }
    if (!fatSecondLine) {
      setFatSecondLine(
        createLine2(secondaryLinePosition, C_FatLineContourMaterial)
      );
    } else {
      updateLine2Position(fatSecondLine, secondaryLinePosition);
    }
  };

  const updateClosingLines = () => {
    if (!initialLine || !secondaryLine) return;
    const initialLinePosition = convertBufferGeometryTo3DVectorList(
      initialLine.geometry
    );

    const secondaryLinePosition = convertBufferGeometryTo3DVectorList(
      secondaryLine.geometry
    );

    if (!closingLines.length) {
      setClosingLines([
        createLine2(
          [
            ...getXYZ(initialLinePosition[0]),
            ...getXYZ(secondaryLinePosition[0]),
          ],
          C_DashedFatLineContourMaterial
        ),
        createLine2(
          [
            ...getXYZ(secondaryLinePosition[1]),
            ...getXYZ(initialLinePosition[1]),
          ],
          C_DashedFatLineContourMaterial
        ),
      ]);
    } else {
      updateLine2Position(closingLines[0], [
        ...getXYZ(initialLinePosition[0]),
        ...getXYZ(secondaryLinePosition[0]),
      ]);
      updateLine2Position(closingLines[1], [
        ...getXYZ(secondaryLinePosition[1]),
        ...getXYZ(initialLinePosition[1]),
      ]);
    }
  };

  const onPointerMove = (event: PointerEvent) => {
    const pointOnMap = pointTargetOnMap(event, scene, mapUUID);
    if (!pointOnMap) return;
    setPointerPosition(pointOnMap);
    if (!isDirectionalInputActive) {
      handleNextPointUpdate(pointOnMap, event.shiftKey);
      initialLine && updateDirectionalInputValueForDistance();
    } else if (isDirectionalInputActive && !isClosing) {
      const extendedVector = getExtendedVector(
        getGeometryVectorPointByIdx(0, initialLine!.geometry),
        pointOnMap!,
        convertMillimetersToMeters(processingEntity.value) * multiplyRate
      );
      handleNextPointUpdate(extendedVector, event.shiftKey);
    }
  };

  const onEscape = () => {
    dispatch(
      setDirectionalInputValues([
        {
          type: edges.length > 1 ? DistanceInput.Length : DistanceInput.Width,
          processing: true,
        },
      ])
    );
  };

  const onInputUpdate = (evt: CustomEvent) => {
    if (isDrawing) {
      if (processingEntity.type === DistanceInput.Width) {
        const extendedVector = getExtendedVector(
          getGeometryVectorPointByIdx(0, initialLine!.geometry),
          secondaryLine
            ? getGeometryVectorPointByIdx(1, initialLine!.geometry)
            : pointerPosition!,
          convertMillimetersToMeters(evt.detail) * multiplyRate
        );
        handleNextPointUpdate(extendedVector);
      } else {
        const vectors: THREE.Vector3[] = convertBufferGeometryTo3DVectorList(
          initialLine!.geometry
        );
        handleNextPointUpdate(
          getTranslatedVector(
            new THREE.Vector3(
              ...getGeometryPointByIdx(0, initialLine!.clone().geometry)
            ),
            convertMillimetersToMeters(evt.detail) * multiplyRate,
            getPerpendicularVectorToVectors(
              vectors,
              isVectorLeftSide(vectors[0], vectors[1], pointerPosition!)
            )
          )
        );
      }
    }
  };

  const onInputSet = (evt: CustomEvent) => {
    if (isDrawing) {
      const extendedVector = getExtendedVector(
        getGeometryVectorPointByIdx(0, initialLine!.geometry),
        pointerPosition!,
        convertMillimetersToMeters(evt.detail) * multiplyRate
      );
      handleAddingNextPoint(extendedVector);
    }
  };

  useEffect(() => {
    subscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    subscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    return () => {
      unsubscribe(DIRECTIONAL_INPUT__SET, onInputSet);
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
      unsubscribe(DIRECTIONAL_INPUT__ESCAPE, onEscape);
    };
  }, [
    initialLine,
    secondaryLine,
    isDrawing,
    isClosing,
    finishedBuilding,
    floorShape,
    edgeOnPointer,
    pointerPosition,
    edges,
    processingEntity,
  ]);

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.addEventListener('pointerdown', onPointerDown);
    ((isDrawing && initialLine) || finishedBuilding) &&
      document.addEventListener('keydown', onKeydown);
    !finishedBuilding && canvas.addEventListener('pointermove', onPointerMove);

    return () => {
      document.removeEventListener('keydown', onKeydown);
      canvas.removeEventListener('pointerdown', onPointerDown);
      canvas.removeEventListener('pointermove', onPointerMove);
    };
  }, [
    initialLine,
    secondaryLine,
    isDrawing,
    isClosing,
    isBuildingFinished,
    floorShape,
    edgeOnPointer,
    pointerPosition,
    isDirectionalInputActive,
    processingEntity,
  ]);

  return (
    <>
      {pointerPosition && !isClosing && (
        <ScalableDot dotPosition={edgeOnPointer.position.clone()} />
      )}
      {fatInitialLine && <primitive object={fatInitialLine} />}
      {fatSecondLine && <primitive object={fatSecondLine} />}
      {floorShape && <primitive object={floorShape} />}
      {edges.length > 0 &&
        edges.map((edge, i) => <ScalableDot dotPosition={edge} key={i} />)}
      {closingLines &&
        closingLines.map((closingLine) => (
          <primitive object={closingLine} key={closingLine.id} />
        ))}
    </>
  );
};

export default RectangleDrawMode;
