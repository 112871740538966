import React, { useEffect } from 'react';
import WindowCard from '@/shared/elements/WindowCard/WindowCard';
import { useParams } from 'react-router';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { IntusButton } from '@/shared/elements';
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { publish } from '@/core/events';
import { OPEN_WINDOW_CREATOR } from '@/core/event-names';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes } from '@/models';
import { getFacadeDesignerMode } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  resetSelectedWindowFromLibrary,
  setSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';

const LibraryPanel = () => {
  const { id } = useParams();
  const projectData = useFetchProjectQuery(id!).data!;
  const dispatch = useAppDispatch();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const isDesignerMode =
    useAppSelector(getCanvasMode) === CanvasActionsModes.facadeDesigner;
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);

  useEffect(() => {
    facadeDesignerMode !== FacadeDesignerModes.WindowPlacement &&
      dispatch(resetSelectedWindowFromLibrary());
  }, [facadeDesignerMode]);

  const handleOpenWindowCreator = () => {
    publish(OPEN_WINDOW_CREATOR);
  };

  const handleSelect = (window: SavedWindow) => {
    dispatch(setSelectedWindowFromLibrary(window));
  };

  return (
    <>
      {isDesignerMode && (
        <div className="p-2 bg-white sticky top-0 z-30">
          <IntusButton
            small
            isActive={true}
            onClick={handleOpenWindowCreator}
            block
            disabled={projectData.locked}
            icon={<PlusOutlined />}
            id="LibraryPanel__new-window-type-button"
          >
            New window type
          </IntusButton>
        </div>
      )}

      {windowsData.map((windowData) => (
        <WindowCard
          isDesignerMode={isDesignerMode}
          facadeDesignerMode={facadeDesignerMode}
          key={windowData.id}
          windowData={windowData}
          onSelect={handleSelect}
        />
      ))}
    </>
  );
};

export default LibraryPanel;
