import { ProjectStructure, UnitSystemTypes } from '@/models';
import { ReportStatus } from '@/models/report';
import { CustomModalProps } from '@/models/shared.model';
import { IntusModal, IntusSwitcher } from '@/shared/elements';
import { Form, FormInstance } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import ErrorContent from './content/ErrorContent';
import ReadyContent from './content/ReadyContent';
import ProcessContent from './content/ProcessContent';
import GenerateContent from './content/GenerateContent';
import { getStatusReport } from '@/store/slices/reportSlice';
import { useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';

interface WrapperModalReportProps extends CustomModalProps {
  data: ProjectStructure;
  percent: number;
  setPercent: React.Dispatch<React.SetStateAction<number>>;
  stopGeneration: React.MutableRefObject<boolean>;
}

const WrapperModalReport: React.FC<WrapperModalReportProps> = ({
  data,
  percent,
  setIsOpen,
  setPercent,
  stopGeneration,
  ...rest
}) => {
  const { id } = useParams();

  if (!id) return <></>;

  const [form] = useForm<{ units: string }>();
  const link = document.createElement('a');
  const initialMeasurementSystem: UnitSystemTypes = data.unitSystem;
  const [currentStep, setCurrentStep] = useState(ReportStatus.DEFAULT);
  const statusReport = useAppSelector((state) => getStatusReport(state, id));

  const handlePDFExport = () => {
    if (data.report) {
      link.href = data.report;
      link.download = 'document.pdf';
      link.target = '_blank';
      link.click();
    }
  };

  const modals = {
    [ReportStatus.DEFAULT]: <GenerateContent />,
    [ReportStatus.PROCESSING]: (
      <ProcessContent
        percent={percent}
        setPercent={setPercent}
        stopGeneration={stopGeneration}
      />
    ),
    [ReportStatus.READY]: <ReadyContent handlePDFExport={handlePDFExport} />,
    [ReportStatus.ERROR]: <ErrorContent />,
  };

  useWatch(['units'], form);

  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setCurrentStep(statusReport || ReportStatus.DEFAULT);
  }, [statusReport]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        units: initialMeasurementSystem,
      });
    }
  }, []);

  return (
    <>
      <IntusModal
        centered
        open
        footer={null}
        styles={{ body: { padding: 0 } }}
        onCancel={() => handleCancel()}
        {...rest}
        width={580}
      >
        <Form
          layout="vertical"
          form={form}
          className="flex flex-col items-center pt-[60px]"
        >
          <Title status={currentStep} />
          {modals[currentStep]}
          <Footer form={form} status={currentStep} />
        </Form>
      </IntusModal>
    </>
  );
};

const Title = ({ status }: { status: ReportStatus }) => {
  switch (status) {
    case ReportStatus.PROCESSING:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Report generation in progress...
        </h3>
      );
    case ReportStatus.READY:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Report is ready
        </h3>
      );
    case ReportStatus.ERROR:
      return (
        <p className="flex flex-col items-center gap-4 m-0 mb-10">
          <span className="font-medium text-base leading-6 m-0 text-dark-gray-100">
            Generation error
          </span>
          <span className="text-xs text-normal leading-5 m-0 text-dark-gray-100">
            Error text
          </span>
        </p>
      );
    default:
      return (
        <h3 className="font-medium leading-6 m-0 mb-10 text-dark-gray-100">
          Report generation
        </h3>
      );
  }
};

const Footer = ({
  status,
  form,
}: {
  status: string;
  form: FormInstance<{
    units: string;
  }>;
}) => {
  switch (status) {
    case ReportStatus.PROCESSING:
      return (
        <div className="w-full mt-auto flex flex-col py-3 bg-light-gray-10">
          <p className="text-xs font-medium leading-5 m-0 px-6 text-dark-gray-10">
            Don&apos;t want to wait?
          </p>
          <p className="text-xs font-normal leading-5 m-0 px-6 text-dark-gray-10">
            Close this popup. You will receive an automatic email notification
            when the report is ready.
          </p>
        </div>
      );
    case ReportStatus.READY:
      return (
        <div className="w-full mt-auto flex flex-col py-[14px] bg-light-gray-10">
          <p className="text-xs leading-5 font-normal text-dark-gray-100 m-0 ml-6">
            The report was generated on October 30, 2024 at 3:50 PM
          </p>
        </div>
      );
    case ReportStatus.ERROR:
      return (
        <div className="w-full mt-auto flex flex-col py-[14px] bg-light-gray-10">
          <p className="text-xs font-normal leading-5 m-0 px-6 text-dark-gray-100">
            There was an error and report generation failed. Please try again
          </p>
        </div>
      );
    default:
      return (
        <div className="w-full mt-auto flex items-center justify-between py-2 bg-light-gray-10">
          <p className="text-xs leading-5 m-0 ml-6 text-dark-gray-100">
            Report units of measure
          </p>
          <div className="bg-light-gray-20 rounded-lg w-fit mr-6">
            <Form.Item className="m-0" name="units">
              <IntusSwitcher
                onChange={(val) =>
                  form.setFieldValue('units', val as UnitSystemTypes)
                }
                data={[
                  { name: 'ft inch', value: UnitSystemTypes.Imperial },
                  { name: 'mm', value: UnitSystemTypes.Metric },
                ]}
              />
            </Form.Item>
          </div>
        </div>
      );
  }
};

export default WrapperModalReport;
