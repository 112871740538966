import React from 'react';
import { UserBuildingPanel } from '@/models';
import panelView from './panelView.png';
import PanelMetricsTable from './PanelMetricsTable/PanelMetricTable';
import PanelColorsTable from './PanelColorsTable/PanelColorsTable';
import WindowPropertiesTable from './WindowsPropertiesTable/WindowsPropertiesTable';

interface PanelMetricsProps {
  panelData: UserBuildingPanel;
  panelIndex: number;
  panelCount: {
    [key: number]: number;
  };
  panelWindowCount: {
    [panelId: number]: {
      [windowId: number]: number;
    };
  };
}

const PanelMetrics: React.FC<PanelMetricsProps> = ({
  panelData,
  panelIndex,
  panelCount,
  panelWindowCount,
}) => {
  return (
    <div className="h-full w-full flex flex-col">
      <div className="px-3 py-1 flex gap-6 text-[11px] leading-4 bg-light-gray-10">
        <div className="text-light-green-100"># {panelIndex + 1}</div>
        <div className="to-dark-gray-100">P {panelData.id}</div>
        <div className="to-dark-gray-100">
          {panelData.isCorner ? 'Corner panel' : 'Straight panel'}
        </div>
      </div>
      <div className="min-h-[209px] flex gap-5 items-center mb-5">
        <div className="bg-light-gray-10  w-[209px] flex-shrink-0 h-full flex items-center justify-center">
          <img src={panelView} alt="Panel View" />
        </div>
        <div className="text-[10px] leading-4 flex-grow pr-3">
          <div className="pt-6 flex flex-col gap-2">
            <div className="font-medium uppercase">Panel metrics </div>
            <PanelMetricsTable panelData={panelData} panelCount={panelCount} />
          </div>
        </div>
      </div>
      <div className="flex flex-col px-3">
        <div className="uppercase font-medium text-[10px] leading-4 mb-2">
          Panel structure
        </div>
        <div className="mb-4">
          <PanelColorsTable panelData={panelData} />
        </div>
        {panelData.panelWindows.length === 1 && (
          <WindowPropertiesTable
            panelData={panelData}
            panelWindowCount={panelWindowCount}
          />
        )}
      </div>
    </div>
  );
};

export default PanelMetrics;
