import React from 'react';
import { Group, Line, Image } from 'react-konva';
import useImage from 'use-image';
import ElevationIcon from '@/images/ElevationIcon.svg';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';
import { getProjectUnits, getMultiplyRate } from '@/store/slices/projectSlice';
import { SelectedNode, UnitSystemTypes } from '@/models';
import {
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
} from '@/shared/helpers/distance';
import { addSpacesToThousands } from '@/shared/helpers/format-data';
import { Html } from 'react-konva-utils';

interface WallElevationsProps {
  distanceToRightEdge: number;
  wallHeight: number;
  offsetY: number;
  scale: number;
  parentStoreyGuid: string;
  parentBlockGuid: string;
  selectedWalls: SelectedNode[];
}

const WallElevations: React.FC<WallElevationsProps> = ({
  distanceToRightEdge,
  wallHeight,
  scale,
  parentStoreyGuid,
  parentBlockGuid,
  offsetY,
  selectedWalls,
}) => {
  const [elevationIcon] = useImage(ElevationIcon);

  const { findDataForBlock, findDataForStorey } = useFindNodeData();
  const blockData = findDataForBlock(parentBlockGuid);
  const storeyData = findDataForStorey(parentStoreyGuid);
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const elevationLineWidth = 120;
  const isLastFloor = storeyData?.storeyNumber === blockData?.storeys.length;
  const isUpperWallIsSelected = blockData?.storeys[
    storeyData!.storeyNumber
  ]?.walls.some((wall) =>
    selectedWalls.some((selectedWall) => selectedWall.guid === wall.guid)
  );

  const getFormattedElevationLevel = (
    point1Y: number,
    point2Y: number
  ): string => {
    const elevationLevelInMillimeters = Number(
      convertMetersToMillimeters((point1Y - point2Y) / multiplyRate)
    );

    return addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(elevationLevelInMillimeters)
        : Number(elevationLevelInMillimeters.toFixed(2)).toString(),
      isImperialUnits
    );
  };

  const formattedElevationLevelForFloorHeight = getFormattedElevationLevel(
    storeyData!.ceiling.points[0][1],
    blockData!.storeys[0].floor.points[0][1]
  );

  const formattedElevationLevelForFloor = getFormattedElevationLevel(
    storeyData!.floor.points[0][1],
    blockData!.storeys[0].floor.points[0][1]
  );

  const generateElevation = ({
    wallHeight,
    elevationElementName,
    elevationValue,
  }: {
    wallHeight?: number;
    elevationElementName: string;
    elevationValue: string;
  }) => (
    <Group
      x={distanceToRightEdge}
      y={wallHeight ? wallHeight - offsetY : -offsetY}
      scaleX={1 / scale}
      scaleY={1 / scale}
    >
      <Line
        points={[0, 0, elevationLineWidth, 0]}
        stroke="#B3B2B4"
        strokeWidth={1}
        strokeScaleEnabled={false}
        dash={[12, 3, 4, 3]}
      />
      <Html
        groupProps={{
          x: 0,
          y: -14,
        }}
      >
        <div
          style={{
            width: elevationLineWidth,
          }}
          className={`flex justify-end text-[11px] text-dark-gray-80 font-normal `}
        >
          <div className="flex-1 text-right truncate ... pl-0.5">
            {elevationElementName}&nbsp;
          </div>
          <div className="flex-none">- {elevationValue}</div>
        </div>
      </Html>
      {elevationIcon && (
        <Image
          image={elevationIcon}
          width={8}
          height={9}
          x={elevationLineWidth}
          y={-9 / 2}
        />
      )}
    </Group>
  );

  return (
    <>
      {generateElevation({
        wallHeight,
        elevationElementName: storeyData!.name,
        elevationValue: formattedElevationLevelForFloor,
      })}
      {(!isUpperWallIsSelected || isLastFloor) &&
        generateElevation({
          elevationElementName: isLastFloor
            ? 'Roof'
            : blockData!.storeys[storeyData!.storeyNumber].name,
          elevationValue: formattedElevationLevelForFloorHeight,
        })}
    </>
  );
};

export default WallElevations;
