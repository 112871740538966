import React from 'react';

const CasementLeftIcon = ({ size = '40' }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H36V36H4V4ZM7.2 7.2V32.8H32.8V7.2H7.2Z"
        fill="white"
      />
      <path d="M7.2 7.2H32.8V32.8H7.2V7.2Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H36V36H4V4ZM7.2 7.2V32.8H32.8V7.2H7.2Z"
        stroke="#B3B2B4"
      />
      <path d="M7.2 7.2H32.8V32.8H7.2V7.2Z" stroke="#B3B2B4" />
      <path d="M33 7L8.5 20L33 33" stroke="#0094FF" />
      <path
        d="M33 7H7V20V33H20H33V7Z"
        fill="#CAD7FF"
        fillOpacity="0.3"
        stroke="#B3B2B4"
      />
    </svg>
  );
};

export default CasementLeftIcon;
