import React from 'react';
import Header from '@/core/Header';
import { Outlet } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '@/store/hooks';
import { getHeaderMode } from '@/store/slices/sharedSlice';
import { HeaderModes } from '@/models/shared.model';
import { ProjectHeader } from '@/core/ProjectHeader';

const Layout = () => {
  const { isAuthenticated } = useAuth0();
  const headerMode = useAppSelector(getHeaderMode);
  const isReportRoute = location.pathname.endsWith('/report');

  const getHeader = () => {
    switch (headerMode) {
      case HeaderModes.Default:
        return <Header />;
      case HeaderModes.Project:
        return <ProjectHeader />;
    }
  };

  return (
    <>
      {isAuthenticated && !isReportRoute && getHeader()}
      <div className="flex-1 h-full max-h-[calc(100%-50px)]">
        <Outlet />
      </div>
    </>
  );
};
export default Layout;
