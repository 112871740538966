import * as THREE from 'three';
import {
  convertBufferGeometryTo3DVectorList,
  getDistanceBetweenInfiniteLineAndVector,
  getExistingCoordinates,
  getGeometryPointByIdx,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  getXYZ,
  isVectorLeftSide,
  setGeometryPointPositionByIdx,
  triangulateGeometryAndUpdate,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { C_FloorMaterial } from '@/shared/materials';
import { FlatVector3 } from '@/models';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

export const generateSectionPoints = (
  sections: THREE.Mesh[],
  currentLineSections: THREE.Vector3[],
  width: number,
  previousLineSectionPoint: THREE.Vector3
): THREE.Vector3[] => {
  if (sections.length > 1) {
    const { normal } = getCenterLineParamsForRightAngle(
      currentLineSections[1],
      [previousLineSectionPoint, ...currentLineSections]
    );

    const startingPoints = [
      getTranslatedVector(
        getTranslatedVector(currentLineSections[0], -width / 2, normal),
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, true)
      ),
      getTranslatedVector(
        getTranslatedVector(currentLineSections[0], -width / 2, normal),
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, false)
      ),
    ];
    const endPoints = [
      getTranslatedVector(
        currentLineSections[1],
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, false)
      ),
      getTranslatedVector(
        currentLineSections[1],
        width / 2,
        getPerpendicularVectorToVectors(currentLineSections, true)
      ),
    ];
    return [...startingPoints, ...endPoints];
  }

  const startingPoints = [
    getTranslatedVector(
      currentLineSections[0],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, true)
    ),
    getTranslatedVector(
      currentLineSections[0],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, false)
    ),
  ];
  const endPoints = [
    getTranslatedVector(
      currentLineSections[1],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, false)
    ),
    getTranslatedVector(
      currentLineSections[1],
      width / 2,
      getPerpendicularVectorToVectors(currentLineSections, true)
    ),
  ];
  return [...startingPoints, ...endPoints];
};

export const getCenterLineParamsForRightAngle = (
  point: THREE.Vector3,
  vectors: THREE.Vector3[]
) => {
  const lastCenterLineVectors = [
    vectors[vectors.length - 2],
    vectors[vectors.length - 3],
  ];

  const isCounterClockWise = isVectorLeftSide(
    lastCenterLineVectors[0],
    lastCenterLineVectors[1],
    point
  );

  const normal = getPerpendicularVectorToVectors(
    lastCenterLineVectors,
    isCounterClockWise
  );

  const distance = getDistanceBetweenInfiniteLineAndVector(
    new THREE.Line3(lastCenterLineVectors[0], lastCenterLineVectors[1]),
    point
  );
  return { normal, distance, isCounterClockWise };
};

export const updateSections = ({
  closeSection,
  nextPoint,
  sections,
  width,
  floor,
  activeCenterLine,
  previousCenterLinePoint,
  processingSection,
  generateFatCenterLine,
  setProcessingNewSection,
  setFloorShape,
  updateContour,
  setClosedFloor,
}: {
  closeSection: boolean;
  nextPoint: THREE.Vector3;
  sections: THREE.Mesh[];
  width: number;
  floor: THREE.Mesh;
  activeCenterLine: THREE.Vector3[];
  previousCenterLinePoint: THREE.Vector3;
  processingSection?: boolean;
  generateFatCenterLine: () => void;
  setProcessingNewSection: (val: boolean) => void;
  setFloorShape: (val: THREE.Mesh) => void;
  updateContour: (val: THREE.Mesh) => void;
  setClosedFloor?: (val: THREE.Mesh) => void;
}) => {
  const floorSectionGeometry =
    closeSection || !sections.length
      ? new THREE.ShapeGeometry()
      : sections[sections.length - 1].geometry;
  const section = generateSectionPoints(
    sections,
    activeCenterLine,
    width,
    previousCenterLinePoint
  );
  floorSectionGeometry.setFromPoints(section);
  triangulateGeometryAndUpdate(floorSectionGeometry, section);

  let updatedCoordinates;

  if (!floor) {
    const floorShapeGeometry = floorSectionGeometry.clone();
    // some time weird bug appears, that state has not been updated for floor shape, however sections were applied already
    if (!sections.length) {
      setProcessingNewSection(true);
      sections.push(new THREE.Mesh(floorSectionGeometry, C_FloorMaterial));
    }
    setFloorShape(new THREE.Mesh(floorShapeGeometry, C_FloorMaterial));
    return;
  }

  if (closeSection) {
    setProcessingNewSection(false);
    const closedFloor = new THREE.Mesh(floor.geometry.clone());
    setClosedFloor && setClosedFloor(closedFloor);
    generateFatCenterLine();
  }

  if (!processingSection) {
    setProcessingNewSection(true);
    sections.push(new THREE.Mesh(floorSectionGeometry, C_FloorMaterial));

    updatedCoordinates = [
      ...getExistingCoordinates(floor),
      getExistingCoordinates(floor)[getExistingCoordinates(floor).length - 1],
      getExistingCoordinates(floor)[getExistingCoordinates(floor).length - 1],
    ];
    floor.geometry.setFromPoints(updatedCoordinates);
    const coordinatesToSwap: { [k: number]: FlatVector3 } = {};
    const existingIdxToSwapIncrement = sections.length + 2;
    for (let i = 0; i <= sections.length - 3; i++) {
      coordinatesToSwap[i + existingIdxToSwapIncrement + 2] =
        getGeometryPointByIdx(i + existingIdxToSwapIncrement, floor.geometry);
    }
    if (Object.entries(coordinatesToSwap).length) {
      Object.entries(coordinatesToSwap).forEach(([idx, val]) => {
        setGeometryPointPositionByIdx(Number(idx), floor.geometry, val);
      });
    }
    updateContour(floor);
    return;
  }

  if (!closeSection) {
    if (sections.length <= 1) {
      updatedCoordinates = section;
    } else {
      const { isCounterClockWise } = getCenterLineParamsForRightAngle(
        nextPoint,
        [previousCenterLinePoint, ...activeCenterLine]
      );

      const idxIncrements = isCounterClockWise ? [3, 2, 1, 0] : [0, 1, 2, 3];

      setGeometryPointPositionByIdx(
        sections.length + idxIncrements[0],
        floor.geometry,
        getGeometryPointByIdx(isCounterClockWise ? 0 : 1, floorSectionGeometry)
      );

      setGeometryPointPositionByIdx(
        sections.length + idxIncrements[1],
        floor.geometry,
        getGeometryPointByIdx(isCounterClockWise ? 3 : 2, floorSectionGeometry)
      );

      setGeometryPointPositionByIdx(
        sections.length + idxIncrements[2],
        floor.geometry,
        getGeometryPointByIdx(isCounterClockWise ? 2 : 3, floorSectionGeometry)
      );

      const { normal } = getCenterLineParamsForRightAngle(nextPoint, [
        previousCenterLinePoint,
        ...activeCenterLine,
      ]);
      setGeometryPointPositionByIdx(
        sections.length + idxIncrements[3],
        floor.geometry,
        getXYZ(
          getTranslatedVector(
            convertFlatVector3ToVectors([
              getGeometryPointByIdx(
                isCounterClockWise ? 1 : 0,
                floorSectionGeometry
              ),
            ])[0],
            width,
            normal
          )
        )
      );

      updatedCoordinates = convertBufferGeometryTo3DVectorList(
        floor.geometry,
        sections.length * 2 + 2
      );
    }
    floor.geometry.setFromPoints(updatedCoordinates);
    triangulateGeometryAndUpdate(floor.geometry, updatedCoordinates);
  }
  updateContour(floor);
};
