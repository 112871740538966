import { UserBuildingWall } from '@/models';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { DEFAULT_CORNER_GRID_WIDTH } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { removeSpacesFromThousands } from '@/shared/helpers/format-data';
import { round } from 'lodash';

export const useBuildingInfo = (buildingGUID: string) => {
  const { findDataForBuilding } = useFindNodeData();
  const { getWallWidthMetric } = useFrameProperties();

  const building = findDataForBuilding(buildingGUID);

  const getBuildingWallsList = (): UserBuildingWall[] => {
    if (!building) return [];

    return building.blocks
      .map((block) => block.storeys.map((storey) => storey.walls.flat()).flat())
      .flat();
  };

  const hasCustomPlacedElements = (): boolean => {
    const walls = getBuildingWallsList();

    const hasUpdatedGridLine = (wall: UserBuildingWall) => {
      const defaultRightCornerGridOffset = round(
        Number(removeSpacesFromThousands(getWallWidthMetric([wall]), false)) -
          DEFAULT_CORNER_GRID_WIDTH,
        2
      );

      return wall.gridLines.some(
        (gridLine) =>
          !gridLine.cornerAlign ||
          (![DEFAULT_CORNER_GRID_WIDTH, defaultRightCornerGridOffset].includes(
            gridLine.offsetFromLeftEdge
          ) &&
            gridLine.cornerAlign)
      );
    };

    return walls.some(
      (wall) =>
        hasUpdatedGridLine(wall) ||
        wall.windowPlacements?.length > 0 ||
        wall.wallPanels?.length > 0
    );
  };

  return { getBuildingWallsList, hasCustomPlacedElements };
};
