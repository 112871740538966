import React from 'react';
import PanelView from '@/components/FacadeDesigner/elements/PanelView';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';

interface FacadeDesignerWallPlacementViewProps {
  scale: number;
  wallData: WallSearchResults;
}

const FacadeDesignerWallPanelsView: React.FC<
  FacadeDesignerWallPlacementViewProps
> = ({ scale, wallData }) => {
  return (
    <>
      {wallData.wallPanels.map((panel, i) => (
        <PanelView panelData={panel} key={`panel.panelId_${i}`} scale={scale} />
      ))}
    </>
  );
};

export default FacadeDesignerWallPanelsView;
