import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import { PanelPlacementData, UnitSystemTypes } from '@/models';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { Group, Rect } from 'react-konva';
import WindowView from '@/components/WindowView/WindowView';

interface PanelViewProps {
  panelData: PanelPlacementData;
  scale: number;
}

const PanelView: React.FC<PanelViewProps> = ({ panelData, scale }) => {
  const { id } = useParams();
  const panelInfo = useGetAllPanelsQuery(id!).data?.find(
    (panel) => panel?.id === panelData.panelId
  );
  const windowsInLibrary = useFetchWindowsQuery(id!).data!;

  const configColors = useFetchWindowConfigQuery().data!.colors;

  const panelColor = useMemo(() => {
    if (panelInfo?.fillerMaterial === 'Glass') return 'rgba(204,213,213,1)';
    return getHexByName(configColors, panelInfo?.frameOutsideColor || '');
  }, [configColors, panelInfo]);

  if (!panelInfo) return null;

  return (
    <Group>
      <Rect
        x={panelData.offsetFromLeftEdge}
        width={panelInfo.width}
        height={panelInfo.height}
        fill={panelColor}
      />
      {panelInfo.panelWindows.map((panelWindow, i) => {
        const windowData = windowsInLibrary.find(
          (window) => window.id === panelWindow.id
        );
        if (!windowData) return null;

        return (
          <WindowView
            data={windowData}
            scale={scale}
            offsetX={panelWindow.offsetFromLeftEdge}
            offsetY={panelInfo.height - windowData.distanceToFloor}
            units={UnitSystemTypes.Metric}
            key={`panel-window__${panelWindow.id}-${i}`}
          />
        );
      })}
    </Group>
  );
};

export default PanelView;
