import React, { useEffect, useState } from 'react';

import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import IntusSplit from '@/shared/elements/IntusSplit/IntusSplit';
import { BuildingsPanelItem } from './PanelItems/BuildingsPanelItem';
import EnvironmentPanelItem from './PanelItems/EnviromentPanelItem';
import { useAppSelector } from '@/store/hooks';
import { getLastSelectedNode } from '@/store/slices/canvasBuildingSlice';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useFetchUserQuery, useUpdateUserMutation } from '@/store/apis/userApi';

const BrowserPanel = () => {
  const { id } = useParams();
  const [updateFrameHeight] = useUpdateUserMutation();
  const userBuildings = useFetchProjectQuery(id!).data?.buildings;

  const lastSelectedNode = useAppSelector(getLastSelectedNode);

  const { getNodeData } = useFindNodeData();

  const [activeGUIDs, setActiveGUIDs] = useState<string[]>([]);

  const data = useFetchProjectQuery(id!).data!;

  useEffect(() => {
    if (lastSelectedNode) {
      const selectedNodeData = getNodeData({
        guid: lastSelectedNode.guid,
        nodeType: lastSelectedNode.type,
      });
      if (selectedNodeData) {
        setActiveGUIDs([
          ...new Set([
            ...activeGUIDs,
            ...selectedNodeData.parentNodes.map((parent) => parent.guid),
          ]),
        ]);
      }
    }
  }, [lastSelectedNode]);

  const handleCollapseChange = (newIds: string | string[]) => {
    typeof newIds === 'string'
      ? setActiveGUIDs([newIds])
      : setActiveGUIDs(newIds);
  };

  const environmentFrameHeight = useFetchUserQuery().data?.leftPanelHeight;
  const sizes = environmentFrameHeight
    ? [environmentFrameHeight, 100 - environmentFrameHeight]
    : [10, 90];

  return (
    <>
      {userBuildings?.length ? (
        <IntusSplit
          snapOffset={0}
          cursor="row-resize"
          direction="vertical"
          className="h-full"
          minSize={104}
          sizes={sizes}
          onDragEnd={(sizes) => {
            updateFrameHeight({ leftPanelHeight: sizes[0] });
          }}
          gutter={(index, direction) => {
            const gutter = document.createElement('div');
            gutter.className = `gutter gutter-${direction}`;
            return gutter;
          }}
          gutterStyle={() => {
            return {
              height: '2px',
              borderBottom: '1px solid #0000000f',
            };
          }}
        >
          <div className="overflow-y-auto overflow-x-hidden">
            <EnvironmentPanelItem
              depthLevel={1}
              keys={activeGUIDs}
              onCollapseChange={handleCollapseChange}
              environment={data.environment}
            />
          </div>
          <div className="overflow-y-auto overflow-x-hidden relative">
            <BuildingsPanelItem
              depthLevel={1}
              onCollapseChange={handleCollapseChange}
              keys={activeGUIDs}
              buildings={data.buildings}
            />
          </div>
        </IntusSplit>
      ) : (
        <>
          <EnvironmentPanelItem
            depthLevel={1}
            keys={activeGUIDs}
            onCollapseChange={handleCollapseChange}
            environment={data.environment}
          />
        </>
      )}
    </>
  );
};

export default BrowserPanel;
