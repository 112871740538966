import React from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  getFacadeDesignerMode,
  getGridPlacementAbsoluteOffset,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import FacadeDesignerGridLine from '@/components/FacadeDesigner/elements/FacadeDesignerGridLine';
import { UnitSystemTypes } from '@/models';

interface FacadeDesignerBackgroundProps {
  totalHeight: number;
  totalWidth: number;
  scale: number;
  unitSystem: UnitSystemTypes;
}
const FacadeDesignerBackground = ({
  scale,
  totalHeight,
  totalWidth,
  unitSystem,
}: FacadeDesignerBackgroundProps) => {
  const isGridPlacementMode =
    useAppSelector(getFacadeDesignerMode) ===
    FacadeDesignerModes.GridLinePlacement;

  const absoluteOffset = useAppSelector(getGridPlacementAbsoluteOffset);

  return (
    <>
      {isGridPlacementMode &&
        !!absoluteOffset &&
        absoluteOffset > 0 &&
        absoluteOffset < totalWidth && (
          <FacadeDesignerGridLine
            gridPlacement={true}
            gridLine={{
              offsetFromLeftEdge: absoluteOffset,
              guid: '',
              cornerAlign: null,
            }}
            unitSystem={unitSystem}
            scale={scale}
            wallHeight={totalHeight}
            isHidden={true}
          />
        )}
    </>
  );
};

export default FacadeDesignerBackground;
