import { BaseIconProps } from '@/models';
import React, { FC } from 'react';

export const FacadeDesignerIcon: FC<BaseIconProps> = ({ fill, className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 17.9964L2.14706 16.3057C2.0559 16.2517 2 16.1536 2 16.0477V4.36674M5 17.9964L8.39381 16.8791C8.51685 16.8386 8.6 16.7236 8.6 16.5941V4.95933M5 17.9964V6.14452M8.6 4.95933L5 6.14452M8.6 4.95933L8.27274 4.76677C8.19829 4.72296 8.10857 4.71337 8.02655 4.74045L5.18499 5.67855C5.10251 5.70578 5.01228 5.69593 4.93762 5.65154L2.6174 4.27193C2.54282 4.22758 2.45268 4.2177 2.37026 4.24484L2 4.36674M5 6.14452L2 4.36674M11 4.15125L10.9742 15.9877M11 4.15125L17 1.99637M11 4.15125L10.5556 3.88914M10.9742 15.9877L16.8004 13.9191C16.92 13.8766 17 13.7634 17 13.6364V1.99637M10.9742 15.9877L10.6522 15.7976C10.5604 15.7434 10.5043 15.6446 10.5047 15.538L10.5556 3.88914M17 1.99637L16.6769 1.80573C16.6001 1.76038 16.507 1.7516 16.423 1.78176L10.5556 3.88914"
        stroke={fill}
        strokeLinejoin="round"
      />
    </svg>
  );
};
