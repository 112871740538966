import { BaseIconProps } from '@/models';
import React from 'react';

export const PDFIcon: React.FC<BaseIconProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50269 13.0021C7.22654 13.0021 7.00269 13.2259 7.00269 13.5021V16.5021C7.00269 16.7782 7.22654 17.0021 7.50269 17.0021C7.77883 17.0021 8.00269 16.7782 8.00269 16.5021V16.0025H8.50004C9.32858 16.0025 10.0002 15.3308 10.0002 14.5023C10.0002 13.6737 9.32858 13.0021 8.50004 13.0021H7.50269ZM8.50004 15.0025H8.00269V14.0021H8.50004C8.77629 14.0021 9.00024 14.226 9.00024 14.5023C9.00024 14.7785 8.7763 15.0025 8.50004 15.0025ZM14.9977 13.5014C14.998 13.2255 15.2218 13.0021 15.4977 13.0021H17.0027C17.2788 13.0021 17.5027 13.2259 17.5027 13.5021C17.5027 13.7782 17.2788 14.0021 17.0027 14.0021H15.997L15.9958 15.0038H17.0027C17.2788 15.0038 17.5027 15.2277 17.5027 15.5038C17.5027 15.7799 17.2788 16.0038 17.0027 16.0038H15.9964L15.9977 16.5008C15.9984 16.7769 15.7751 17.0014 15.4989 17.0021C15.2228 17.0028 14.9984 16.7795 14.9977 16.5033L14.9951 15.5051L14.9951 15.5032L14.9977 13.5014ZM11.5 13.0021H11.9976C13.1021 13.0021 13.9976 13.8975 13.9976 15.0021C13.9975 16.1067 13.1021 17.0021 11.9976 17.0021H11.5C11.2239 17.0021 11 16.7782 11 16.5021V13.5021C11 13.2259 11.2239 13.0021 11.5 13.0021ZM12 16.0021C12.5512 16.0008 12.9975 15.5535 12.9976 15.0021C12.9976 14.4506 12.5512 14.0034 12 14.0021V16.0021ZM12 8V2H6C4.89543 2 4 2.89543 4 4V10.6684C3.40876 10.9493 3 11.5519 3 12.25V17.75C3 18.4481 3.40876 19.0507 4 19.3316V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V19.3316C20.5912 19.0507 21 18.4481 21 17.75V12.25C21 11.5519 20.5912 10.9493 20 10.6684V10H14C12.8954 10 12 9.10457 12 8ZM4.75 12H19.25C19.3881 12 19.5 12.1119 19.5 12.25V17.75C19.5 17.8881 19.3881 18 19.25 18H4.75C4.61193 18 4.5 17.8881 4.5 17.75V12.25C4.5 12.1119 4.61193 12 4.75 12ZM13.5 8V2.5L19.5 8.5H14C13.7239 8.5 13.5 8.27614 13.5 8Z"
        fill="#D23736"
      />
    </svg>
  );
};
