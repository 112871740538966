import { Color } from 'three';

export enum Side {
  FRONT = 'front',
  BACK = 'back',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

export interface Background {
  color: Color;
  alpha: number;
}

export interface Filter {
  side: Side;
  targetGUID: string;
  background?: Background;
  includeEnvironment?: string[];
}
