import React, { useEffect, useMemo, useRef } from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Group } from 'react-konva';

import { calculateInnerWindowPoints } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getIsWindowNodeSelected,
  getMultiMeasurementActiveStatus,
  getWindowCreatorMode,
  resetHoverArea,
  setHoverArea,
  setMullionArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { FlatVector2 } from '@/models';
import { WindowElementType } from '@/components/WindowCreator/models/konva-model';
import WindowGlass from '@/components/WindowCreator/elements/WindowGlass';
import { WindowCreatorModes } from '@/models/shared.model';
import { useWindowCreatorSelect } from '@/components/WindowCreator/elements/shared/useWindowCreatorSelect';

interface FixedWindowProps {
  points: FlatVector2[];
  outerFramePoints: FlatVector2[];
  independent?: boolean;
}

export const FixedWindow = ({
  points,
  independent,
  outerFramePoints,
}: FixedWindowProps) => {
  const glassRef = useRef<Konva.Line>(null);
  const dispatch = useAppDispatch();
  const multiMeasurementStatus = useAppSelector(
    getMultiMeasurementActiveStatus
  );
  const isSelected = useAppSelector(
    getIsWindowNodeSelected(glassRef?.current?._id ?? 0)
  );

  const { selectElementInWindowCreator } = useWindowCreatorSelect(
    glassRef?.current?._id ?? 0,
    points
  );
  const mode = useAppSelector(getWindowCreatorMode);
  const isSelectionMode = mode === WindowCreatorModes.Selection;

  const glassPoints = useMemo(
    () => calculateInnerWindowPoints(points, outerFramePoints),
    [points, outerFramePoints]
  );

  const handleSelect = (event?: KonvaEventObject<MouseEvent>) => {
    if (!independent) return;
    selectElementInWindowCreator(WindowElementType.Window, glassPoints, event);
  };

  useEffect(() => {
    isSelected && handleSelect();
  }, [glassPoints]);

  const handleMouseOver = () => {
    independent && isSelectionMode && dispatch(setHoverArea(glassPoints));
    mode === WindowCreatorModes.MullionCreator &&
      !multiMeasurementStatus &&
      dispatch(setMullionArea(points));
  };

  const handleMouseLeave = () => {
    independent && isSelectionMode && dispatch(resetHoverArea());
  };

  return (
    <Group onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <WindowGlass
        points={glassPoints.flat()}
        onClick={handleSelect}
        ref={glassRef}
      />
    </Group>
  );
};
