export const REPORT_WIDTH = 914;
export const REPORT_HEIGHT = 610;
export const REPORT_SIDEBAR_WIDTH = 215;

export enum ReportStatus {
  DEFAULT = 'default',
  PROCESSING = 'processing',
  READY = 'ready',
  ERROR = 'error',
}
