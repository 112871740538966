import React, { useState } from 'react';
import IntusPopover from '@/shared/elements/Popover/Popover';
import InfoIcon from '@/shared/icons/InfoIcon';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import { UnitSystemTypes } from '@/models';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import {
  calculateHeightFromFloor,
  calculateWindowDimensions,
} from '@/components/WindowCreator/helpers/windowMetric';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import {
  getColorDescriptionByName,
  getHexByName,
} from '@/components/WindowCreator/helpers/config';

interface WindowInfoPopoverProps {
  disabled: boolean;
  windowData: SavedWindow;
  windowUsageAmount: number;
}

const WindowInfoPopover: React.FC<WindowInfoPopoverProps> = ({
  disabled,
  windowData,
  windowUsageAmount,
}) => {
  const { id } = useParams();
  const [isOpened, setIsOpened] = useState(false);
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const heightFromFloor = calculateHeightFromFloor(windowData);
  const windowDimensions = calculateWindowDimensions(windowData);
  const configColors = useFetchWindowConfigQuery().data!.colors;

  const innerColor = getHexByName(configColors, windowData.innerColor);
  const outerColor = getHexByName(configColors, windowData.outerColor);

  const popoverTitle = (
    <div className="py-2 pl-3">
      <span className="text-[11px] font-medium leading-[16px]">
        Window properties
      </span>
    </div>
  );

  const popoverContent = (
    <div className=" box-content p-3 w-[208px]">
      <div className="flex justify-between mb-2">
        <span className="text-xs font-light leading-5">
          Instances in the model
        </span>
        <span className="text-xs font-normal leading-5">
          {windowUsageAmount}
        </span>
      </div>
      <div className="flex justify-between mb-2">
        <span className="text-xs font-light leading-5">Height</span>
        <div className="flex gap-1">
          <span className="text-xs font-normal leading-5">
            {isImperialUnits
              ? convertMillimetersToFtInch(windowDimensions.height)
              : windowDimensions.height}
          </span>
          {!isImperialUnits ? (
            <span className="font-light text-black-40 leading-5">mm</span>
          ) : null}
        </div>
      </div>
      <div className="flex justify-between mb-2">
        <span className="text-xs font-light leading-5">Width</span>
        <div className="flex gap-1">
          <span className="text-xs font-normal leading-5">
            {isImperialUnits
              ? convertMillimetersToFtInch(windowDimensions.width)
              : windowDimensions.width}
          </span>
          {!isImperialUnits ? (
            <span className="font-light text-black-40 leading-5">mm</span>
          ) : null}
        </div>
      </div>
      <div className="flex justify-between mb-2">
        <span className="text-xs font-light leading-5">Height from floor</span>
        <div className="flex gap-1">
          <span className="text-xs font-normal leading-5">
            {isImperialUnits
              ? convertMillimetersToFtInch(heightFromFloor)
              : heightFromFloor}
          </span>
          {!isImperialUnits ? (
            <span className="font-light text-black-40 leading-5">mm</span>
          ) : null}
        </div>
      </div>
      <div className="flex justify-between mb-2">
        <span className="text-xs font-light leading-5">Glazing type</span>
        <span className="text-xs font-normal leading-5">Double</span>
        {/* Currently this metric is static */}
      </div>
      <div className="mb-2">
        <div className="text-xs font-light leading-5 mb-1">Outside color</div>
        <div className="flex justify-start gap-2">
          <div
            style={{ backgroundColor: outerColor }}
            className="w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full"
          />
          <span className="text-xs font-normal leading-5">
            {getColorDescriptionByName(configColors, windowData.outerColor)}
          </span>
        </div>
      </div>
      <div>
        <div className="text-xs font-light leading-5 mb-1">Inside color</div>
        <div className="flex justify-start gap-2">
          <div
            className="w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full"
            style={{ backgroundColor: innerColor }}
          />
          <span className="text-xs font-normal leading-5">
            {getColorDescriptionByName(configColors, windowData.innerColor)}
          </span>
        </div>
      </div>
    </div>
  );

  const handlePopoverOpenChange = (e: boolean) => {
    if (disabled) return;
    setIsOpened(e);
  };

  return (
    <IntusPopover
      placement="right"
      title={popoverTitle}
      content={popoverContent}
      open={isOpened}
      onOpenChange={handlePopoverOpenChange}
    >
      <div
        className={`flex justify-center items-center p-0 m-0 border-0 bg-transparent`}
      >
        <InfoIcon fill={isOpened ? '#65BD51' : '#CCCDCE'} />
      </div>
    </IntusPopover>
  );
};

export default WindowInfoPopover;
