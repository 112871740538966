import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const CrossIcon: React.FC<BaseIconProps> = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.95236 3.26257L3.01022 3.19332C3.18378 3.01976 3.45321 3.00047 3.64808 3.13547L3.71732 3.19332L8.36377 7.83988L13.0102 3.19332C13.2055 2.99806 13.5221 2.99806 13.7173 3.19332C13.9126 3.38858 13.9126 3.70517 13.7173 3.90043L9.07077 8.54688L13.7173 13.1933C13.8909 13.3669 13.9102 13.6363 13.7752 13.8312L13.7173 13.9004C13.5438 14.074 13.2743 14.0933 13.0795 13.9583L13.0102 13.9004L8.36377 9.25387L3.71732 13.9004C3.52206 14.0957 3.20548 14.0957 3.01022 13.9004C2.81495 13.7052 2.81495 13.3886 3.01022 13.1933L7.65677 8.54688L3.01022 3.90043C2.83665 3.72686 2.81736 3.45744 2.95236 3.26257L3.01022 3.19332L2.95236 3.26257Z"
        fill="#414042"
      />
    </svg>
  );
};
