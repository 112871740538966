import React, { useMemo } from 'react';
import * as THREE from 'three';
import { C_DashedSplitLine } from '@/shared/materials';
import {
  createLine2,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { ExtendedMinMaxCoordinatesPairs } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { RENDER_ORDERS } from '@/shared/constants';

const SplitLine = ({
  position,
  minMaxCoordinates,
}: {
  position: THREE.Vector3;
  minMaxCoordinates: ExtendedMinMaxCoordinatesPairs;
}) => {
  const start = useMemo(
    () => new THREE.Vector3(position.x, minMaxCoordinates.min.y, position.z),
    [position, minMaxCoordinates]
  );
  const end = useMemo(
    () => new THREE.Vector3(position.x, minMaxCoordinates.max.y, position.z),
    [position, minMaxCoordinates]
  );

  const splitLine = useMemo(() => {
    const material = C_DashedSplitLine.clone();
    material.depthTest = false;
    const line = createLine2([...getXYZ(start), ...getXYZ(end)], material);
    line.renderOrder = RENDER_ORDERS.EDIT_NODE_LINE;
    return line;
  }, [start, end]);

  const endDot = useMemo(() => {
    return new THREE.Mesh(
      new THREE.SphereGeometry(0.0003, 32),
      new THREE.MeshBasicMaterial({
        color: splitLine.material.color,
        depthTest: false,
        toneMapped: false,
      })
    );
  }, [end, splitLine.material]);

  return (
    <>
      <primitive object={endDot} position={end} />
      <primitive object={splitLine} />
    </>
  );
};

export default SplitLine;
