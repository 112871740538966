import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { FlatVector3, NodeType, WindowPlacementData } from '@/models';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { RENDER_ORDERS } from '@/shared/constants';
import { getWindowHeight, getWindowWidth } from '@/shared/helpers';
import { scaleMesh } from '@/shared/helpers/canvas';

interface ViewerWindowProps {
  scale: number;
  placementData: WindowPlacementData;
  wallData: FlatVector3[];
}

const ViewerWindow: React.FC<ViewerWindowProps> = ({
  scale,
  placementData,
  wallData,
}) => {
  const { id } = useParams();

  const fetchedWindowData = useFetchWindowsQuery(id!).data;
  if (!fetchedWindowData) return null;

  const data = fetchedWindowData.find(
    (windowData) => windowData.id === placementData.windowId
  );

  const leftSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallData[1]),
    [wallData]
  );
  const rightSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallData[0]),
    [wallData]
  );

  const perpendicularForWall = useMemo(
    () =>
      getPerpendicularVectorToVectors([leftSideBottom, rightSideBottom], true),
    [leftSideBottom, rightSideBottom]
  );

  if (!data) return null;

  const windowWidth = getWindowWidth(data);
  const windowHeight = getWindowHeight(data);

  //window position is in the center of the window model
  const getWindowPosition = () => {
    const position = getExtendedVector(
      leftSideBottom,
      rightSideBottom,
      convertMillimetersToMeters(
        placementData.offsetFromLeftEdge + windowWidth / 2
      ) * scale
    );
    position.setY(
      position.y +
        convertMillimetersToMeters(data.distanceToFloor + windowHeight / 2) *
          scale
    );
    return getTranslatedVector(position, 0.0001, perpendicularForWall);
  };

  const getLookAtPosition = () => {
    return getTranslatedVector(getWindowPosition(), 100, perpendicularForWall);
  };

  let windowModel: THREE.Group;

  //if the model is not loaded, then load the default window model
  try {
    const gltf = useGLTF(data.model);
    windowModel = gltf.scene.clone();
  } catch {
    return null;
  }

  if (!windowModel) return null;

  scaleMesh(windowModel, convertMillimetersToMeters(scale));

  windowModel.position.copy(getWindowPosition());
  windowModel.lookAt(getLookAtPosition());
  windowModel.renderOrder = RENDER_ORDERS.WINDOW_MODEL;
  windowModel.userData = {
    nodeType: NodeType.Window,
  };

  return <primitive object={windowModel} />;
};

export default ViewerWindow;
