import React from 'react';

import { NodeType } from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import TotalSurface from '@/shared/elements/TotalSurface/TotalSurface';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';

import useFrameProperties from '@/shared/hooks/useFrameProperties';

const WallFrame = ({ wallsGUID }: { wallsGUID: string[] }) => {
  const { findDataForWall, findDataForStorey } = useFindNodeData();

  const selectedWallsData = wallsGUID.map(
    (wallGUID) => findDataForWall(wallGUID)!
  );
  const storeysOfSelectedWalls = selectedWallsData.map(
    (wall) =>
      findDataForStorey(
        wall.parentNodes.find((node) => node.type === NodeType.Storey)!.guid
      )!
  );

  const getWallName = (wall: WallSearchResults) => {
    return wall.name;
  };

  const {
    getFloorHeightMetric,
    getWallWidthMetric,
    getFacadesAreaMetricForWalls,
  } = useFrameProperties();

  return (
    <>
      <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {selectedWallsData.length > 1
            ? `Wall (${selectedWallsData.length})`
            : getWallName(selectedWallsData[0])}
        </span>
      </div>

      <div
        className={
          'flex flex-col text-xs border border-l-0 border-solid border-light-gray-20 !bg-white overflow-y-auto text-dark-gray-100'
        }
      >
        <MetricsHeader />
        <PropertyList
          alignValueLeft
          properties={[
            {
              name: 'Height',
              value: getFloorHeightMetric(storeysOfSelectedWalls),
            },
            { name: 'Width', value: getWallWidthMetric(selectedWallsData) },
          ]}
        />
      </div>

      <TotalSurface
        facadesArea={getFacadesAreaMetricForWalls(selectedWallsData)}
      />
    </>
  );
};

export default WallFrame;
