import React, { ReactNode, useRef } from 'react';

interface TextTooltipProps {
  text?: string | ReactNode;
  bindToMouse?: boolean;
  visible: boolean;
}

const TextTooltip: React.FC<TextTooltipProps> = ({ text, visible }) => {
  const ref = useRef(null);

  // Could be returned later if we will make this element related to mouse position again

  // const [pos, setPos] = useState<{ x: number; y: number }>(null!);
  // const mouse = useMouse(ref);
  //
  // bindToMouse && useMouseOverCanvas(setPos);
  //
  // useEffect(() => {
  //   bindToMouse && mouse.docX !== 0 && setPos({ x: mouse.docX, y: mouse.docY });
  // }, [mouse, bindToMouse]);
  // if (!pos) return null;

  return (
    <div
      className={`text-xs z-10
        ${visible ? 'visible' : 'hidden'}`}
      ref={ref}
    >
      <div>
        <div className="flex items-center">{text}</div>
      </div>
    </div>
  );
};

export default TextTooltip;
