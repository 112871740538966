import { WallSearchResults } from '@/shared/hooks/useFindNodeData';

import {
  FlatVector2Axis,
  SavedWindow,
} from '@/components/WindowCreator/models';
import { useMemo } from 'react';
import { removeSpacesFromThousands } from '@/shared/helpers/format-data';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useParams } from 'react-router';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { getWindowWidth } from '@/shared/helpers';
import { round } from 'mathjs';

export const useCustomElementPlacement = ({
  wallData,
  yPosition,
}: {
  wallData: WallSearchResults;
  yPosition: number;
}) => {
  const { id } = useParams();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });

  const wallWidth = useMemo(
    () =>
      Number(removeSpacesFromThousands(getWallWidthMetric([wallData]), false)),
    [wallData]
  );

  const getWallOffsetList = () => [0, wallWidth];

  const getGridOffsetList = () => {
    return wallData.gridLines
      .map((gridLineData) => gridLineData.offsetFromLeftEdge)
      .sort((a, b) => a - b);
  };

  const getWindowOffsetList = (ignoredOffset: number) => {
    return wallData.windowPlacements
      .filter((data) => data.offsetFromLeftEdge !== ignoredOffset)
      .map((windowPlacementData) => {
        const windowOffset = windowPlacementData.offsetFromLeftEdge;
        const windowData = windowsData.find(
          (wd) => wd.id === windowPlacementData.windowId
        )!;
        const windowWidth = getWindowWidth(windowData);
        return [windowOffset, windowOffset + windowWidth];
      })
      .flat()
      .sort((a, b) => a - b);
  };

  const findNearestOffsetPoints = (
    offsetList: number[],
    relativeXOffset: number
  ): { left: number; right: number } => {
    return offsetList.reduce(
      (acc, cur) => {
        if (cur > relativeXOffset && cur < acc.right) {
          acc.right = cur;
        }
        if (cur < relativeXOffset && cur > acc.left) {
          acc.left = cur;
        }

        return acc;
      },
      { left: -Infinity, right: Infinity }
    );
  };

  const handleWindowMovePlacement = (
    relativeXOffset: number,
    windowData: SavedWindow
  ): FlatVector2Axis[] => {
    const offsets = [
      ...getWindowOffsetList(relativeXOffset),
      ...getWallOffsetList(),
      ...getGridOffsetList(),
    ];
    const nearestPoints = findNearestOffsetPoints(offsets, relativeXOffset);
    if (nearestPoints.left < 0 || nearestPoints.right > wallWidth) return [];

    return [
      [
        [nearestPoints.left, yPosition],
        [relativeXOffset, yPosition],
      ],
      [
        [round(relativeXOffset + getWindowWidth(windowData), 2), yPosition],
        [nearestPoints.right, yPosition],
      ],
    ];
  };
  const handleGridMovePlacement = (
    relativeXOffset: number
  ): FlatVector2Axis[] => {
    const gridOffsets = [...getGridOffsetList(), ...getWallOffsetList()];
    const nearestPoints = findNearestOffsetPoints(gridOffsets, relativeXOffset);

    if (nearestPoints.left < 0 || nearestPoints.right > wallWidth) return [];
    return [
      [
        [nearestPoints.left, yPosition],
        [relativeXOffset, yPosition],
      ],
      [
        [relativeXOffset, yPosition],
        [nearestPoints.right, yPosition],
      ],
    ];
  };

  return { handleGridMovePlacement, handleWindowMovePlacement };
};
