import { BaseIconProps } from '@/models';
import React from 'react';

export const ReportGenerationIcon: React.FC<BaseIconProps> = ({
  className,
}) => {
  return (
    <svg
      width="154"
      height="112"
      viewBox="0 0 154 112"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.2589" cy="46.2286" r="2.96296" fill="#E8F5E5" />
      <circle cx="5.33333" cy="97.7786" r="5.33333" fill="#E8F5E5" />
      <circle cx="80.5931" cy="100.74" r="5.33333" fill="#E8F5E5" />
      <ellipse
        cx="121.481"
        cy="42.079"
        rx="3.55556"
        ry="3.55556"
        fill="#E8F5E5"
      />
      <circle cx="123.852" cy="27.8547" r="2.37037" fill="#E8F5E5" />
      <circle cx="41.4817" cy="109.636" r="2.37037" fill="#E8F5E5" />
      <circle cx="151.511" cy="72.3704" r="2.37037" fill="#E8F5E5" />
      <path
        d="M89.7378 62.3728H90.2432C90.856 62.3728 91.2744 61.8762 91.1772 61.2635L86.7393 33.2422C86.6421 32.6294 86.0667 32.1328 85.4539 32.1328H84.9484H16.8329L21.6222 62.3734H89.7378V62.3728Z"
        fill="#C8CED5"
      />
      <path
        opacity="0.5"
        d="M89.7378 62.3728H90.2432C90.856 62.3728 91.2744 61.8762 91.1772 61.2635L86.7393 33.2422C86.6421 32.6294 86.0667 32.1328 85.4539 32.1328H84.9484H16.8329L21.6222 62.3734H89.7378V62.3728Z"
        fill="white"
      />
      <path
        d="M21.8749 62.374C21.1223 62.374 20.416 61.7642 20.2969 61.0116L15.9389 33.4952C15.8198 32.7426 16.333 32.1328 17.0856 32.1328C17.8382 32.1328 18.5445 32.7426 18.6637 33.4952L23.0216 61.0116C23.1407 61.7642 22.6275 62.374 21.8749 62.374Z"
        fill="#C8CED5"
      />
      <path
        opacity="0.2"
        d="M21.8749 62.374C21.1223 62.374 20.416 61.7642 20.2969 61.0116L15.9389 33.4952C15.8198 32.7426 16.333 32.1328 17.0856 32.1328C17.8382 32.1328 18.5445 32.7426 18.6637 33.4952L23.0216 61.0116C23.1407 61.7642 22.6275 62.374 21.8749 62.374Z"
        fill="white"
      />
      <path
        d="M116.756 99.8885H28.5868L33.6238 55.3125H115.756C118.974 55.3125 121.476 58.1131 121.115 61.3107L116.756 99.8885Z"
        fill="#E8EAEE"
      />
      <path
        opacity="0.2"
        d="M115.756 55.3125H35.0585V60.3264H121.13C120.939 57.5602 118.638 55.3125 115.756 55.3125Z"
        fill="white"
      />
      <path
        d="M38.138 99.8885H13.9999V59.8251C13.9999 57.3327 16.02 55.3125 18.5125 55.3125H37.1388C40.3572 55.3125 42.8591 58.1131 42.4977 61.3107L38.138 99.8885Z"
        fill="#E8EAEE"
      />
      <path
        opacity="0.4"
        d="M38.138 99.8885H13.9999V59.8251C13.9999 57.3327 16.02 55.3125 18.5125 55.3125H37.1388C40.3572 55.3125 42.8591 58.1131 42.4977 61.3107L38.138 99.8885Z"
        fill="#B7C0C6"
      />
      <path
        d="M111.18 98.3381L112.773 84.2344H46.5357L44.9423 98.3381H111.18Z"
        fill="#C8CED5"
      />
      <path
        d="M138.835 98.337H71.828V96.0703H138.835C139.394 96.0703 139.847 96.5231 139.847 97.0819V97.3254C139.847 97.8842 139.394 98.337 138.835 98.337Z"
        fill="#C8CED5"
      />
      <path
        opacity="0.1"
        d="M138.835 98.337H71.828V96.0703H138.835C139.394 96.0703 139.847 96.5231 139.847 97.0819V97.3254C139.847 97.8842 139.394 98.337 138.835 98.337Z"
        fill="white"
      />
      <path
        d="M111.435 96.0664H129.543C129.543 93.4235 127.4 91.2812 124.757 91.2812H111.976L111.435 96.0664Z"
        fill="#C8CED5"
      />
      <path
        opacity="0.3"
        d="M111.435 96.0664H129.543C129.543 93.4235 127.4 91.2812 124.757 91.2812H111.976L111.435 96.0664Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M32.609 55.3168H73.7729L70.9788 32.1328H29.8143L32.609 55.3168Z"
        fill="#B7C0C6"
      />
      <path
        d="M100.806 96.0574C105.676 96.0574 109.209 92.1096 108.697 87.2397C108.185 82.3697 111.718 78.4219 116.588 78.4219H78.8472C73.9773 78.4219 70.4443 82.3697 70.9563 87.2397C71.4683 92.1096 67.9352 96.0574 63.0653 96.0574H100.806Z"
        fill="#E8EAEE"
      />
      <path
        opacity="0.7"
        d="M100.806 96.0574C105.676 96.0574 109.209 92.1096 108.697 87.2397C108.185 82.3697 111.718 78.4219 116.588 78.4219H78.8472C73.9773 78.4219 70.4443 82.3697 70.9563 87.2397C71.4683 92.1096 67.9352 96.0574 63.0653 96.0574H100.806Z"
        fill="white"
      />
      <path
        d="M134.639 112.183C131.5 112.183 128.687 109.638 128.358 106.499L126.334 87.2397C125.822 82.3697 121.459 78.4219 116.589 78.4219H78.8485C83.7184 78.4219 88.0811 82.3697 88.5931 87.2397L90.6174 106.499C90.9475 109.638 93.7599 112.183 96.8989 112.183H134.639Z"
        fill="#E8EAEE"
      />
      <path
        opacity="0.9"
        d="M134.639 112.183C131.5 112.183 128.687 109.638 128.358 106.499L126.334 87.2397C125.822 82.3697 121.459 78.4219 116.589 78.4219H78.8485C83.7184 78.4219 88.0811 82.3697 88.5931 87.2397L90.6174 106.499C90.9475 109.638 93.7599 112.183 96.8989 112.183H134.639Z"
        fill="white"
      />
      <path
        d="M134.635 112.184C137.774 112.184 140.052 109.639 139.722 106.5H101.981C102.311 109.64 100.034 112.184 96.8944 112.184H134.635Z"
        fill="#E8EAEE"
      />
      <path
        opacity="0.6"
        d="M134.635 112.184C137.774 112.184 140.052 109.639 139.722 106.5H101.981C102.311 109.64 100.034 112.184 96.8944 112.184H134.635Z"
        fill="white"
      />
      <path
        d="M74.0089 96.0664H68.9037C68.9037 93.4235 66.7615 91.2812 64.1186 91.2812H45.7387L45.1983 96.0664L44.9423 98.3331H74.0089C74.4842 98.3331 74.8688 97.9479 74.8688 97.4732V96.9263C74.8688 96.4516 74.4836 96.0664 74.0089 96.0664Z"
        fill="#C8CED5"
      />
      <path
        opacity="0.3"
        d="M74.0089 96.0664H68.9037C68.9037 93.4235 66.7615 91.2812 64.1186 91.2812H45.7387L45.1983 96.0664L44.9423 98.3331H74.0089C74.4842 98.3331 74.8688 97.9479 74.8688 97.4732V96.9263C74.8688 96.4516 74.4836 96.0664 74.0089 96.0664Z"
        fill="white"
      />
      <path
        d="M77.5196 55.3162H36.3551L31.3951 24H72.5596L77.5196 55.3162Z"
        fill="#E8EAEE"
      />
      <path
        opacity="0.9"
        d="M77.5196 55.3162H36.3551L31.3951 24H72.5596L77.5196 55.3162Z"
        fill="white"
      />
      <path
        d="M52.5563 58.5403H111.356C110.527 56.6541 108.645 55.3125 106.417 55.3125H47.6171C49.8446 55.3125 51.7267 56.6541 52.5563 58.5403Z"
        fill="#E8EAEE"
      />
      <path
        opacity="0.5"
        d="M52.5563 58.5403H111.356C110.527 56.6541 108.645 55.3125 106.417 55.3125H47.6171C49.8446 55.3125 51.7267 56.6541 52.5563 58.5403Z"
        fill="white"
      />
      <g clipPath="url(#clip0_16291_183215)">
        <path
          d="M70.3103 28.28C70.4288 29.6608 69.4095 30.7808 68.0288 30.7808H66.5058L62.1444 35.563L56.9414 30.7808H49.5873C48.2066 30.7808 46.9918 29.6608 46.8673 28.28L45.2555 9.83857C45.1369 8.46375 46.1562 7.34375 47.537 7.34375H65.9784C67.3592 7.34375 68.574 8.46375 68.6925 9.83857L70.3103 28.28Z"
          stroke="#DADEE5"
          strokeWidth="0.592593"
          strokeMiterlimit="10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.8299 11.5937L65.0012 26.7233C65.0546 27.4119 64.5102 28 63.8196 28H53.5519C52.9328 28 52.418 27.5235 52.3702 26.9063L51.1989 11.7767C51.1456 11.0881 51.69 10.5 52.3806 10.5H62.6483C63.2674 10.5 63.7821 10.9765 63.8299 11.5937ZM61.1482 15.0953L61.7131 22.525C61.7654 23.2129 61.2213 23.8 60.5314 23.8H58.4952H58.2377H55.9537C55.334 23.8 54.8189 23.3226 54.772 22.7047L54.207 15.275C54.1547 14.5871 54.6988 14 55.3888 14H57.4925H57.7501H59.9664C60.5861 14 61.1012 14.4774 61.1482 15.0953Z"
          fill="#DADEE5"
        />
        <path
          d="M57.4925 14H55.3888C54.6988 14 54.1547 14.5871 54.207 15.275L54.772 22.7047C54.8189 23.3226 55.334 23.8 55.9537 23.8H58.2377M57.4925 14H59.9664M57.4925 14H57.7501M58.2377 23.8H60.5314M58.2377 23.8H58.4952M57.7501 14L58.4952 23.8M57.7501 14H59.9664M58.4952 23.8H60.5314M60.5314 23.8C61.2213 23.8 61.7654 23.2129 61.7131 22.525L61.1482 15.0953C61.1012 14.4774 60.5861 14 59.9664 14M52.3702 26.9063L51.1989 11.7767C51.1456 11.0881 51.69 10.5 52.3806 10.5H62.6483C63.2674 10.5 63.7821 10.9765 63.8299 11.5937L65.0012 26.7233C65.0546 27.4119 64.5102 28 63.8196 28H53.5519C52.9328 28 52.418 27.5235 52.3702 26.9063Z"
          stroke="#DADEE5"
          strokeWidth="0.592593"
        />
        <path
          d="M42.6009 19.9199C42.6898 20.951 41.9313 21.7866 40.8942 21.7866H39.075L35.4364 25.7747L31.0986 21.7866H24.3372C23.3001 21.7866 22.3935 20.951 22.2987 19.9199L20.8527 3.35697C20.7638 2.32586 21.5283 1.48438 22.5594 1.48438H39.1224C40.1535 1.48438 41.0601 2.32586 41.1549 3.35697L42.6009 19.9199Z"
          stroke="#DADEE5"
          strokeWidth="0.592593"
          strokeMiterlimit="10"
        />
        <path
          d="M27.6172 6.29367C27.5767 5.77697 27.9851 5.33536 28.5033 5.33536H31.647H35.2249C35.6889 5.33536 36.0748 5.69225 36.1111 6.15482L36.9003 16.2289C36.9407 16.7456 36.5324 17.1872 36.0141 17.1872H32.6219H29.2925C28.8286 17.1872 28.4426 16.8303 28.4064 16.3677L27.6172 6.29367Z"
          stroke="#DADEE5"
          strokeWidth="0.592593"
        />
      </g>
      <defs>
        <clipPath id="clip0_16291_183215">
          <rect
            width="49.4741"
            height="34.0797"
            fill="white"
            transform="translate(20.8457 1.48438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
