import { BaseIconProps } from '@/models';
import React from 'react';

export const WarningIcon: React.FC<BaseIconProps> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.68149 2.78544C9.24892 1.73813 10.752 1.73821 11.3193 2.78557L17.8198 14.7865C18.3612 15.786 17.6375 17.0009 16.5009 17.0009H3.4982C2.36147 17.0009 1.63783 15.7858 2.17934 14.7864L8.68149 2.78544ZM10.5 7.5C10.5 7.22386 10.2761 7 10 7C9.72386 7 9.5 7.22386 9.5 7.5V11.5C9.5 11.7761 9.72386 12 10 12C10.2761 12 10.5 11.7761 10.5 11.5V7.5ZM10.75 13.75C10.75 13.3358 10.4142 13 10 13C9.58579 13 9.25 13.3358 9.25 13.75C9.25 14.1642 9.58579 14.5 10 14.5C10.4142 14.5 10.75 14.1642 10.75 13.75Z"
        fill="#FFCC02"
      />
    </svg>
  );
};
