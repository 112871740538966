import React from 'react';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import {
  calculateHeightFromFloor,
  calculateWindowDimensions,
} from '@/components/WindowCreator/helpers/windowMetric';
import { SavedWindow } from '@/components/WindowCreator/models';
import { UnitSystemTypes } from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import ColorsIcon from '@/shared/icons/ColorsIcon';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';

const WindowFrame = ({
  selectedWindows,
}: {
  selectedWindows: SavedWindow[];
}) => {
  const configData = useFetchWindowConfigQuery().data!;
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const windowDimensions = calculateWindowDimensions(selectedWindows[0]);
  const heightFromFloor = calculateHeightFromFloor(selectedWindows[0]);
  const areWindowsIdentical = selectedWindows.every(
    (window) => window.id === selectedWindows[0].id
  );

  return (
    <div className="text-xs">
      <div className="flex justify-between  px-3  font-medium items-center border-box border-b border-0 border-solid border-light-gray-20 h-8">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {selectedWindows.length > 1
            ? `Windows (${selectedWindows.length})`
            : selectedWindows[0].name}
        </span>
      </div>
      {areWindowsIdentical && (
        <>
          <div
            className={
              'flex flex-col border-b border-0 border-solid border-light-gray-20 !bg-white overflow-y-auto text-dark-gray-100'
            }
          >
            <MetricsHeader />
            <PropertyList
              key={selectedWindows[0].id}
              properties={[
                {
                  name: 'Height',
                  value: isImperialUnits
                    ? convertMillimetersToFtInch(windowDimensions.width)
                    : windowDimensions.width.toString(),
                  units: isImperialUnits ? '' : 'mm',
                },
                {
                  name: 'Width',
                  value: isImperialUnits
                    ? convertMillimetersToFtInch(windowDimensions.height)
                    : windowDimensions.height.toString(),
                  units: isImperialUnits ? '' : 'mm',
                },
                {
                  name: 'Height from floor',
                  value: isImperialUnits
                    ? convertMillimetersToFtInch(heightFromFloor)
                    : heightFromFloor.toString(),
                  units: isImperialUnits ? '' : 'mm',
                },
              ]}
            />
          </div>
          <div className="border-0 border-b border-solid border-light-gray-20 py-3 mb-3">
            <div className="flex gap-1 mb-2 px-3">
              <ColorsIcon />
              <div className="font-medium leading-5 uppercase">colors</div>
            </div>
            <div className="px-3">
              <div className="flex justify-between mb-1">
                <span className="font-light leading-5">Outside color</span>
                <div
                  className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                  style={{
                    backgroundColor: getHexByName(
                      configData.colors,
                      selectedWindows[0].outerColor
                    ),
                  }}
                />
              </div>
              <div className="flex justify-between">
                <span className="font-light leading-5">Inside color</span>
                <div
                  className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                  style={{
                    backgroundColor: getHexByName(
                      configData.colors,
                      selectedWindows[0].innerColor
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between px-3">
            <span className="leading-5 font-light">Glazing type</span>
            <span className="leading-5">Double</span>
          </div>
        </>
      )}
    </div>
  );
};

export default WindowFrame;
