import React, { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls';
import { gsap } from 'gsap';
import {
  CANVAS_PROJECT_MAX_ZOOM,
  CANVAS_PROJECT_MIN_ZOOM,
} from './project-canvas.helpers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import {
  CanvasActionsModes,
  CanvasCameraCenterMode,
  CanvasCameraPolarAngles,
  CanvasCameraType,
} from '@/models';
import { useCameraAnimations } from '@/shared/hooks/useCameraAnimations';
import { useSavedCameras } from '@/shared/hooks/useSavedCameras';
import {
  resetCameraPolarAngle,
  setCameraCenterPosition,
  resetAppliedCamera,
  setCameraPolarAngle,
  setCameraDirection,
  getCameraType,
} from '@/store/slices/canvasCamerasSlice';
import {
  createOrthographicCamera,
  createPerspectiveCamera,
  getformattedMomentDate,
  makeScreenshotDelay,
} from '@/shared/helpers/camera';
import { NotificationService } from '@/shared/services/error-handler.service';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { useThree } from '@react-three/fiber';
import { subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT, ROTATE_CAMERA_NORTH } from '@/core/event-names';
import { sleep } from '@/shared/helpers/sleep';

const CameraControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const [shiftPressed, setShiftPressed] = useState(false);
  const [isOrbitControlsEnabled, setIsOrbitControlsEnabled] = useState(true);
  const [cameraTarget, setCameraTarget] = useState(new THREE.Vector3());
  const [initialDistanceToCamera, setInitialDistanceToCamera] = useState(0);
  const [isInAction, setIsInAction] = useState(false);
  const cameraControlRef = useRef<OrbitControlsImpl>(null);
  const { size, camera, set, gl, scene } = useThree();
  const currentCamera = useAppSelector(getCameraType);

  useCameraAnimations(cameraControlRef);
  useSavedCameras(cameraControlRef);

  const mode = useAppSelector(getCanvasMode);

  const getLeftMouseMode = (mode: CanvasActionsModes) => {
    switch (mode) {
      case CanvasActionsModes.move: {
        return THREE.MOUSE.PAN;
      }
      case CanvasActionsModes.rotate: {
        return THREE.MOUSE.ROTATE;
      }
      default: {
        return undefined;
      }
    }
  };

  const handleShift = (e: KeyboardEvent) => {
    setShiftPressed(e.shiftKey);
  };

  const handleCameraAngle = () => {
    const currentPolarAngle = cameraControlRef.current!.getPolarAngle();
    if (currentPolarAngle <= 0.2) {
      dispatch(setCameraPolarAngle(CanvasCameraPolarAngles.topView));
    } else {
      dispatch(resetCameraPolarAngle());
    }
  };

  const isRotationEnabled = shiftPressed || mode === CanvasActionsModes.rotate;

  const handleCameraMove = () => {
    dispatch(setCameraCenterPosition(CanvasCameraCenterMode.custom));
    dispatch(resetAppliedCamera());
  };

  useEffect(() => {
    isInAction && setIsOrbitControlsEnabled(false);
  }, [mode]);

  const handlePointerDown = () => {
    setIsInAction(true);
  };

  const handlePointerUp = () => {
    setIsInAction(false);
    setIsOrbitControlsEnabled(true);
  };

  const pointCameraNorth = () => {
    const controls = cameraControlRef.current!;
    const spherical = new THREE.Spherical();
    spherical.setFromVector3(camera.position);

    spherical.set(spherical.radius, spherical.phi, 0);

    const position = new THREE.Vector3().setFromSpherical(spherical);
    gsap.to(camera.position, {
      x: position.x,
      y: position.y,
      z: position.z,
      duration: 0.5,
    });

    gsap.to(controls!.target, {
      x: controls.target0.x,
      y: controls.target0.y,
      z: controls.target0.z,
      duration: 0.5,
    });

    camera.updateProjectionMatrix();
  };

  const updateCameraDirection = () => {
    requestAnimationFrame(() => {
      const dir = new THREE.Vector3();
      const sph = new THREE.Spherical();
      camera.getWorldDirection(dir);
      sph.setFromVector3(dir);
      dispatch(setCameraDirection({ theta: sph.theta }));
    });
  };

  useUpdateEffect(() => {
    if (!cameraControlRef.current) return;

    const currentDistanceToCamera =
      cameraControlRef.current.object.position.distanceTo(
        cameraControlRef.current.target
      );

    if (currentCamera === CanvasCameraType.Perspective) {
      setInitialDistanceToCamera(currentDistanceToCamera);
      const orthographicCamera = camera as THREE.OrthographicCamera;
      const newCamera = createPerspectiveCamera(orthographicCamera, size);
      set({ camera: newCamera });
    } else if (currentCamera === CanvasCameraType.Orthographic) {
      const perspectiveCamera = camera as THREE.PerspectiveCamera;
      const newCamera = createOrthographicCamera(
        perspectiveCamera,
        size,
        initialDistanceToCamera,
        currentDistanceToCamera
      );
      set({ camera: newCamera });
    }
  }, [currentCamera]);

  useEffect(() => {
    subscribe(ROTATE_CAMERA_NORTH, pointCameraNorth);
    return () => {
      unsubscribe(ROTATE_CAMERA_NORTH, pointCameraNorth);
    };
  }, [camera, cameraControlRef]);

  const downloadScreenshot = async () => {
    await sleep(makeScreenshotDelay);
    try {
      const formattedDate = getformattedMomentDate();

      scene.background = new THREE.Color('#f4f4f4');
      gl.render(scene, camera);

      const screenshot = gl.domElement.toDataURL();

      const response = await fetch(screenshot);
      const blob = await response.blob();

      const handle = await window.showSaveFilePicker({
        suggestedName: `Screen capture ${formattedDate}.jpg`,
        types: [
          {
            description: 'JPEG file',
            accept: {
              'image/jpeg': ['.jpg'],
            },
          },
        ],
      });

      const writableStream = await handle.createWritable();
      await writableStream.write(blob);
      await writableStream.close();

      scene.background = null;
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('User canceled the save file operation.');
      } else {
        NotificationService.error(error);
      }
    }
  };

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, downloadScreenshot);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, downloadScreenshot);
    };
  }, []);

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.addEventListener('pointerdown', handlePointerDown);
    window.addEventListener('pointerup', handlePointerUp);
    window.addEventListener('keydown', handleShift);
    window.addEventListener('keyup', handleShift);
    return () => {
      canvas.removeEventListener('pointerdown', handlePointerDown);
      window.removeEventListener('pointerup', handlePointerUp);
      window.removeEventListener('keydown', handleShift);
      window.removeEventListener('keyup', handleShift);
    };
  }, [mode]);

  useUpdateEffect(() => {
    if (cameraControlRef.current) {
      setCameraTarget(cameraControlRef.current.target.clone());
      camera.updateProjectionMatrix();
    }
  }, [currentCamera]);

  return (
    <OrbitControls
      mouseButtons={{
        LEFT: getLeftMouseMode(mode),
        RIGHT: THREE.MOUSE.PAN,
      }}
      onStart={handleCameraMove}
      onEnd={handleCameraAngle}
      onChange={updateCameraDirection}
      enabled={isOrbitControlsEnabled}
      enableRotate={isRotationEnabled}
      enableDamping={false}
      makeDefault
      zoomSpeed={0.3}
      minPolarAngle={0}
      maxPolarAngle={Math.PI / 2}
      maxZoom={CANVAS_PROJECT_MAX_ZOOM}
      minZoom={CANVAS_PROJECT_MIN_ZOOM}
      ref={cameraControlRef}
      target={cameraTarget}
    />
  );
};

export default CameraControl;
