import { MeasurementProps } from '@/models/measurements.model';
import { FlatVector2Axis } from '@/components/WindowCreator/models';
import { Measurement } from '@/shared/components/MeasurementLine';

import React, { useState } from 'react';
import { useKey } from 'react-use';
import { FlatVector2 } from '@/models';
import { round } from 'mathjs';

export interface MultiMeasurementProps extends MeasurementProps {
  multiPoints: FlatVector2Axis[];
  onSubmit?: () => void;
  onChange?: (points: FlatVector2Axis[]) => void;
  onActiveStatusChange?: (isActive: boolean) => void;
  onEscape?: () => void;
  horizontal?: boolean;
}
const MultiMeasurementLine = ({
  // That functionality will only work for sorted points from left to right, and from top to bottom
  multiPoints,
  scale,
  units,
  type,
  onActiveStatusChange,
  onChange,
  onSubmit,
  onEscape,
  horizontal = true,
}: MultiMeasurementProps) => {
  const [activeMeasurementIdx, setActiveMeasurementIdx] = useState<number>(-1);
  const xSpace = round(multiPoints[1][0][0] - multiPoints[0][1][0], 2);
  // const ySpace = round(multiPoints[1][0][1] - multiPoints[0][1][1], 2);

  const handleEscape = () => {
    onEscape && onEscape();
    setActiveMeasurementIdx(-1);
  };

  const handleChange = (distance: number) => {
    const firstValuePair: [FlatVector2, FlatVector2] = [...multiPoints[0]];
    const secondValuePair: [FlatVector2, FlatVector2] = [...multiPoints[1]];
    // No handling for vertical change, where ySpace exist.
    if (horizontal) {
      if (activeMeasurementIdx === 0) {
        firstValuePair[1] = [
          firstValuePair[0][0] + distance,
          firstValuePair[1][1],
        ];
        secondValuePair[0] = [
          firstValuePair[1][0] + xSpace,
          firstValuePair[1][1],
        ];
      } else {
        secondValuePair[0] = [
          secondValuePair[1][0] - distance,
          secondValuePair[0][1],
        ];
        firstValuePair[1] = [
          secondValuePair[0][0] - xSpace,
          secondValuePair[0][1],
        ];
      }
    } else {
      if (activeMeasurementIdx === 0) {
        firstValuePair[1] = [
          firstValuePair[1][0],
          firstValuePair[0][1] + distance,
        ];
        secondValuePair[0] = firstValuePair[1];
      } else {
        secondValuePair[0] = [
          secondValuePair[1][0],
          secondValuePair[1][1] - distance,
        ];
        firstValuePair[1] = secondValuePair[0];
      }
    }

    onChange && onChange([firstValuePair, secondValuePair]);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit();
  };

  const handleTab = (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveMeasurementIdx(
      activeMeasurementIdx === multiPoints.length - 1
        ? 0
        : activeMeasurementIdx + 1
    );
    onActiveStatusChange && onActiveStatusChange(true);
  };

  useKey('Escape', handleEscape, { event: 'keydown' }, []);

  useKey('Tab', handleTab, { event: 'keydown' }, [
    multiPoints,
    activeMeasurementIdx,
  ]);

  return (
    <>
      {multiPoints.map((points, idx) => (
        <Measurement
          key={idx}
          points={points}
          scale={scale}
          units={units}
          type={type}
          level={0}
          forceActive={idx === activeMeasurementIdx}
          listening={false}
          onEscape={handleEscape}
          onChange={handleChange}
          onMeasurementSubmit={handleSubmit}
        ></Measurement>
      ))}
    </>
  );
};

export default MultiMeasurementLine;
