import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FlatVector2, WindowPlacementData } from '@/models';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { FacadeDesignerModes, PointerPosition } from '@/models/shared.model';
import {
  getSelectedWindowFromLibrary,
  resetSelectedGridLines,
  setGridPlacementAbsoluteOffset,
  setSelectedPlacedWindow,
  setMeasurementActiveWall,
  getHoveredWall,
  setHoveredWall,
  getMeasurementActiveWall,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  limitValue,
  removeSpacesFromThousands,
} from '@/shared/helpers/format-data';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import {
  convert2DPointsToDistanceInMeters,
  getWallHeight,
} from '@/shared/helpers/metrics';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { KonvaEventObject } from 'konva/lib/Node';
import { get2DDistance } from '@/shared/helpers/konva';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models';
import { round } from 'mathjs';
import { isLeftClick } from '@/shared/helpers';
import { Group } from 'react-konva';
import WindowView from '@/components/WindowView/WindowView';
import WallView from '@/components/FacadeDesigner/elements/WallView';

import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useParams } from 'react-router';
import {
  ExtendedMinMaxCoordinatesPairs,
  getMinMaxCoordinatesAtVector3,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import FacadeDesignerWindows from '@/components/FacadeDesigner/elements/FacadeDesignerWindows';
import MultiMeasurementLine from '@/shared/components/MultiMeasurementLine/MultiMeasurementLine';
import { useCustomElementPlacement } from '@/components/FacadeDesigner/hooks';
import FacadeDesignerGridLines from '@/components/FacadeDesigner/elements/FacadeDesignerGridLines';
import { uuidv7 } from 'uuidv7';
import FacadeDesignerWallPanelsView from '@/components/FacadeDesigner/elements/FacadeDesignerWallPanelsView';

interface FacadeDesignerFloorViewProps {
  startPoint: { x: number; z: number };
  wallData: WallSearchResults;
  scale: number;
  minMaxWallsCoordinates: ExtendedMinMaxCoordinatesPairs;
  onMeasurementUpdate: (data: WindowPlacementData[]) => void;
  onAddWindow: (data: WindowPlacementData[]) => void;
  facadeDesignerMode: FacadeDesignerModes;
  onGridPlacement: () => void;
}
const FacadeDesignerWallView = ({
  startPoint,
  wallData,
  scale,
  minMaxWallsCoordinates,
  onMeasurementUpdate,
  onAddWindow,
  facadeDesignerMode,
  onGridPlacement,
}: FacadeDesignerFloorViewProps) => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const windowsData = useFetchWindowsQuery(id!).data!;
  const hoveredWall = useAppSelector(getHoveredWall);
  const [measurementPoints, setMeasurementPoints] = useState<FlatVector2Axis[]>(
    []
  );
  const pointerPositionRef = useRef<PointerPosition | null>(null);
  const activeWall = useAppSelector(getMeasurementActiveWall);
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );
  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });
  const [placedWindowOffset, setPlacedWindowOffset] = useState<number>(-1);
  const dispatch = useAppDispatch();

  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;
  const isGridPlacementMode =
    facadeDesignerMode === FacadeDesignerModes.GridLinePlacement;

  const isWindowPlacementProcessing = !!selectedWindowFromLibrary;

  const wallWidth = useMemo(
    () =>
      Number(removeSpacesFromThousands(getWallWidthMetric([wallData]), false)),
    [wallData]
  );

  const wallHeight = useMemo(() => {
    return Number(
      convertMetersToMillimeters(getWallHeight(wallData.points, multiplyRate))
    );
  }, [wallData]);

  const placedWindows: WindowPlacementData[] = useMemo(() => {
    return wallData.windowPlacements || [];
  }, [wallData]);

  const handleMouseEnter = () => {
    dispatch(setHoveredWall(wallData.guid));
  };

  useEffect(() => {
    !hoveredWall && !activeWall && resetMeasurementTool();

    hoveredWall === wallData.guid &&
      !activeWall &&
      pointerPositionRef.current &&
      updateMeasurementTool(pointerPositionRef.current);
  }, [activeWall, hoveredWall, pointerPositionRef.current]);

  useEffect(() => {
    setMeasurementPoints([]);
  }, [facadeDesignerMode]);

  const handleMouseLeave = () => {
    !activeWall && dispatch(setGridPlacementAbsoluteOffset(0));
    dispatch(setHoveredWall(null));
  };

  const onWindowPlacement = () => {
    if (!selectedWindowFromLibrary) return;
    const placements: WindowPlacementData[] = [
      ...placedWindows,
      {
        windowId: selectedWindowFromLibrary.id,
        offsetFromLeftEdge: placedWindowOffset,
        guid: uuidv7(),
      },
    ];
    onAddWindow(placements);
  };

  // Should be pointer down event, to go faster than cancelling active measurement
  const handlePointerDown = (event: KonvaEventObject<PointerEvent>) => {
    if (activeWall || !isLeftClick(event.evt)) return;
    if (isWindowPlacementProcessing && measurementPoints?.length) {
      onWindowPlacement();
    } else if (isGridPlacementMode && measurementPoints?.length) {
      onGridPlacement();
    }
  };

  const wallCoordinates = getMinMaxCoordinatesAtVector3(
    convertFlatVector3ToVectors(wallData.points)
  );

  const wallBottomLeftPoint: FlatVector2 = [
    wallData.points[1][0],
    wallData.points[1][2],
  ];

  const wallOffset = useMemo(
    () => ({
      x: round(
        Number(
          convertMetersToMillimeters(
            convert2DPointsToDistanceInMeters(
              wallBottomLeftPoint,
              [startPoint.x, startPoint.z],
              multiplyRate
            )
          )
        ),
        2
      ),
      y: round(
        Number(
          convertMetersToMillimeters(
            (wallCoordinates.max.y - minMaxWallsCoordinates.max.y) /
              multiplyRate
          )
        ),
        2
      ),
    }),
    [
      multiplyRate,
      minMaxWallsCoordinates,
      wallCoordinates,
      wallBottomLeftPoint,
      startPoint,
    ]
  );

  const { handleGridMovePlacement, handleWindowMovePlacement } =
    useCustomElementPlacement({
      wallData,
      yPosition: wallHeight / 2,
    });

  const updateMeasurementTool = (pointerPosition: PointerPosition) => {
    const roundedPointerPosition = {
      x: round(pointerPosition.x, 0),
      y: round(pointerPosition.y, 0),
    };
    if (isWindowPlacementProcessing) {
      const windowWidth = get2DDistance(
        selectedWindowFromLibrary.points[0],
        selectedWindowFromLibrary.points[1]
      );

      const maxXPosition = limitValue(wallWidth - windowWidth, 0, wallWidth);
      const xPosition = roundedPointerPosition.x - windowWidth / 2;

      const offset = limitValue(xPosition, 0, maxXPosition);
      setPlacedWindowOffset(offset);
      setMeasurementPoints(
        handleWindowMovePlacement(offset, selectedWindowFromLibrary)
      );
    } else if (isGridPlacementMode) {
      const offset = round(wallOffset.x + roundedPointerPosition.x, 0);
      dispatch(setGridPlacementAbsoluteOffset(offset));
      setMeasurementPoints(handleGridMovePlacement(roundedPointerPosition.x));
    }
  };

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const pointerPosition = event.currentTarget?.getRelativePointerPosition();

    if (!pointerPosition) return;
    pointerPositionRef.current = pointerPosition;

    if (activeWall) return;
    updateMeasurementTool(pointerPosition);
  };

  const handleActiveMeasurementStatus = (isActive: boolean) => {
    dispatch(setMeasurementActiveWall(isActive ? wallData.guid : null));
  };

  const handleMeasurementEscape = () => {
    dispatch(setMeasurementActiveWall(null));
  };

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    setMeasurementPoints(points);

    if (isGridPlacementMode) {
      dispatch(
        setGridPlacementAbsoluteOffset(round(points[1][0][0] + wallOffset.x, 2))
      );
    } else if (isWindowPlacementProcessing) {
      setPlacedWindowOffset(points[0][1][0]);
      setMeasurementPoints(
        handleWindowMovePlacement(points[0][1][0], selectedWindowFromLibrary)
      );
    }
  };

  const resetMeasurementTool = () => {
    dispatch(setMeasurementActiveWall(null));
    dispatch(setGridPlacementAbsoluteOffset(0));
    pointerPositionRef.current = null;
    setMeasurementPoints([]);
  };

  const handleMeasurementSubmit = () => {
    if (isGridPlacementMode) {
      onGridPlacement();
    } else if (isWindowPlacementProcessing) {
      onWindowPlacement();
    }
    resetMeasurementTool();
  };

  const resetSelectedWindow = (e: KonvaEventObject<MouseEvent>) => {
    if (!isSelectionMode || !isLeftClick(e.evt)) return;
    e.cancelBubble = true;
    dispatch(setSelectedPlacedWindow([]));
    dispatch(resetSelectedGridLines());
  };

  return (
    <Group
      offsetX={-wallOffset.x}
      offsetY={wallOffset.y}
      onPointerDown={handlePointerDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <Group onClick={resetSelectedWindow}>
        <WallView
          wallWidth={wallWidth}
          wallHeight={wallHeight}
          scale={scale}
          initialScale={scale}
        />
      </Group>

      {wallData.wallPanels.length > 0 ? (
        <FacadeDesignerWallPanelsView wallData={wallData} scale={scale} />
      ) : (
        <FacadeDesignerWindows
          placedWindows={wallData.windowPlacements}
          scale={scale}
          windowsData={windowsData}
          unitSystem={unitSystem}
          wallData={wallData}
          isWindowPlacementProcessing={isWindowPlacementProcessing}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          facadeDesignerMode={facadeDesignerMode}
          onMeasurementUpdate={onMeasurementUpdate}
        />
      )}

      <FacadeDesignerGridLines
        isGridPlacementMode={isGridPlacementMode}
        scale={scale}
        unitSystem={unitSystem}
        wallWidth={wallWidth}
        wallHeight={wallHeight}
        wallData={wallData}
        xWallOffset={wallOffset.x}
      />

      {isWindowPlacementProcessing &&
        (activeWall
          ? activeWall === wallData.guid
          : hoveredWall === wallData.guid) && (
          <Group opacity={0.75} listening={false}>
            <WindowView
              data={selectedWindowFromLibrary}
              scale={scale}
              units={unitSystem}
              offsetX={placedWindowOffset}
              offsetY={wallHeight - selectedWindowFromLibrary.distanceToFloor}
              viewOnly
            />
          </Group>
        )}

      {!!measurementPoints?.length &&
        (activeWall
          ? activeWall === wallData.guid
          : hoveredWall === wallData.guid) && (
          <MultiMeasurementLine
            multiPoints={measurementPoints}
            scale={scale}
            units={unitSystem}
            type={MeasurementElementType.WindowDistance}
            onActiveStatusChange={handleActiveMeasurementStatus}
            onEscape={handleMeasurementEscape}
            onChange={handleMeasurementChange}
            onSubmit={handleMeasurementSubmit}
          />
        )}
    </Group>
  );
};

export default FacadeDesignerWallView;
