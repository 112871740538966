import { RootState, Size, ThreeEvent } from '@react-three/fiber';
import { getCursorCoordinatesOnOrthographicSystem } from '@/shared/helpers/select-area';
import { Vector3 } from 'three';
import * as THREE from 'three';
import { C_MeshBaseColorMaterial } from '@/shared/materials';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { calculateZoomFromPerspectiveCamera } from '@/shared/helpers/camera';
import { FlatVector3 } from '@/models';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

export const PROJECT_CANVAS_ID = 'intus-project-canvas';

export const pointTargetOnMap = (
  event: PointerEvent,
  scene: RootState,
  mapUUID: string
): Vector3 | null => {
  const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
    event,
    scene.gl
  );
  scene.raycaster.setFromCamera(cursorCoordinates, scene.camera);
  const intersects = scene.raycaster.intersectObjects(
    scene.scene.children,
    false
  );

  const mapObject = intersects?.find((obj) => obj.object.uuid === mapUUID);
  if (!mapObject || mapObject?.point.y < 0) return null;

  return new Vector3(
    mapObject.point.x,
    mapObject.point.y + 0.002,
    mapObject.point.z
  );
};

export const getLineVertexes = (line: THREE.Line): [Vector3, Vector3] => {
  const pos = line.geometry.getAttribute('position');
  return [
    new Vector3(pos.array[0], pos.array[1], pos.array[2]),
    new Vector3(pos.array[3], pos.array[4], pos.array[5]),
  ];
};

export const getMidPointOfLine = (line: THREE.Line): Vector3 => {
  const vertexes = getLineVertexes(line);
  return new THREE.Vector3()
    .subVectors(vertexes[1], vertexes[0])
    .multiplyScalar(0.5)
    .add(vertexes[0]);
};

export const getPerpendicularLine = (line: THREE.Line) => {
  const midPoint = getMidPointOfLine(line);
  const vertexes = getLineVertexes(line);

  const normal = new THREE.Vector3()
    .subVectors(vertexes[1], vertexes[0])
    .applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI * 0.5)
    .normalize();

  const normalVertices = [
    normal.clone().setLength(10).add(midPoint),
    normal.clone().negate().setLength(10).add(midPoint),
  ];

  const normalGeom = new THREE.BufferGeometry().setFromPoints(normalVertices);
  const normalMat = new THREE.LineBasicMaterial({ color: 0xff0000 });
  // normalMat.transparent = true;
  return new THREE.Line(normalGeom, normalMat);
};

export const createSvgElement = (svg: string) => {
  const group = new THREE.Group();
  const loader = new SVGLoader();
  loader.load(svg, function (data) {
    const paths = data.paths;

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];

      const material = new THREE.MeshBasicMaterial({
        color: path.color,
        side: THREE.DoubleSide,
      });

      const shapes = SVGLoader.createShapes(path);

      for (let j = 0; j < shapes.length; j++) {
        const shape = shapes[j];
        const geometry = new THREE.ShapeGeometry(shape);
        geometry.computeVertexNormals();
        geometry.computeBoundingBox();
        geometry.center();
        const mesh = new THREE.Mesh(geometry, material);
        group.add(mesh);
      }
    }
  });
  return group;
};

export const generateEdgePoint = (pointOnMap?: Vector3): THREE.Mesh => {
  const circleGeometry = new THREE.CircleGeometry(0.01, 32);
  circleGeometry.rotateX(Math.PI / 2);
  circleGeometry.rotateY(Math.PI / 2);
  circleGeometry.rotateZ(Math.PI);
  circleGeometry.computeBoundingBox();
  const circle = new THREE.Mesh(circleGeometry, C_MeshBaseColorMaterial);

  if (pointOnMap) {
    circle.position.set(pointOnMap.x, pointOnMap.y + 0.001, pointOnMap.z);
  }
  return circle;
};

export const getScaleRatioForCameraZoom = (
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
  size: Size,
  objectPosition: THREE.Vector3,
  scaleFactor: number
) => {
  const scaleVector = new THREE.Vector3();
  if (camera.type === 'OrthographicCamera') {
    return (
      scaleVector.subVectors(objectPosition, camera.position).length() /
      scaleFactor /
      camera.zoom
    );
  } else {
    const perspectiveCamera = camera as THREE.PerspectiveCamera;

    const zoom = calculateZoomFromPerspectiveCamera(
      perspectiveCamera,
      size.height
    );

    const restrictedZoom = () => {
      if (zoom > 300) return 300;
      if (zoom < 150) return 150;
      return zoom;
    };

    const restrictedDistance = () => {
      const distance =
        scaleVector.subVectors(objectPosition, camera.position).length() * 5;
      if (distance < 0.01) return 0.01;
      if (distance > 100) return 100;
      return distance;
    };

    return restrictedDistance() / scaleFactor / 2 / restrictedZoom();
  }
};

export const pointTargetOnMesh = (
  event: PointerEvent | ThreeEvent<PointerEvent>,
  scene: RootState,
  mesh: THREE.Mesh
): THREE.Vector3 | null => {
  const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
    event as PointerEvent,
    scene.gl
  );
  scene.raycaster.setFromCamera(cursorCoordinates, scene.camera);
  const intersects = scene.raycaster.intersectObjects([mesh], false);

  const intersectedObject = intersects?.find(
    (obj) => obj.object.uuid === mesh.uuid
  );
  if (!intersectedObject || intersectedObject?.point.y < 0) return null;

  return intersectedObject.point;
};

export const distanceToSegment = (
  p1: THREE.Vector3,
  p2: THREE.Vector3,
  p: THREE.Vector3
) => {
  const l2 = p1.distanceToSquared(p2);
  if (l2 === 0) return p.distanceTo(p1);
  let t =
    ((p.x - p1.x) * (p2.x - p1.x) +
      (p.y - p1.y) * (p2.y - p1.y) +
      (p.z - p1.z) * (p2.z - p1.z)) /
    l2;
  t = Math.max(0, Math.min(1, t));
  return p.distanceTo(
    new THREE.Vector3(p1.x + t * (p2.x - p1.x), p.y, p1.z + t * (p2.z - p1.z))
  );
};

export const findInsertionIndex = (
  points: FlatVector3[],
  newPoint: THREE.Vector3
) => {
  let minDistance = Infinity;
  let insertionIndex = 0;

  for (let i = 0; i < points.length - 1; i++) {
    const p1 = convertFlatVector3ToVector(points[i]);
    const p2 = convertFlatVector3ToVector(points[i + 1]);

    const dist = distanceToSegment(p1, p2, newPoint);

    if (dist < minDistance) {
      minDistance = dist;
      insertionIndex = i + 1;
    }
  }

  return insertionIndex;
};
