import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { FacadeDesignerModes } from '@/models/shared.model';
import { WindowPlacementData } from '@/models';
import { GridLineDataForFD } from '@/components/FacadeDesigner/models';

export enum FacadeDesignerPlacementType {
  Window = 'window',
  GridLine = 'gridLine',
}

interface FacadeDesignerState {
  modifiedWalls: string[]; // GUID's
  selectedWindowFromLibrary?: SavedWindow;
  selectedWindowPosition?: { x: number; y: number };
  facadeDesignerMode: FacadeDesignerModes;
  selectedPlacedWindow: WindowPlacementData[];
  hoveredPlacedWindow: WindowPlacementData | null;
  selectedGridLines: GridLineDataForFD[];
  hoveredGridLine: GridLineDataForFD | null;
  draggedWindowFromLibrary?: SavedWindow;
  gridPlacementOffset?: number;
  // Wall, which currently has Active MultiMeasurement input (Active === user can input something)
  measurementActiveWall: string | null;
  // Wall, which is currently hovered by user
  hoveredWall: string | null;
}

const initialState: FacadeDesignerState = {
  modifiedWalls: [],
  selectedPlacedWindow: [],
  hoveredPlacedWindow: null,
  hoveredWall: null,
  hoveredGridLine: null,
  selectedGridLines: [],
  measurementActiveWall: null,
  facadeDesignerMode: FacadeDesignerModes.WindowPlacement,
};

export const facadeDesignerSlice = createSlice({
  name: 'facadeDesigner',
  initialState,
  reducers: {
    setSelectedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow>
    ) => {
      state.selectedWindowFromLibrary = action.payload;
    },
    resetFacadeDesignerSlice: () => initialState,
    resetSelectedWindowFromLibrary: (state) => {
      state.selectedWindowFromLibrary = undefined;
    },
    addSelectedPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementData>
    ) => {
      state.selectedPlacedWindow.push(action.payload);
    },
    setSelectedPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementData[]>
    ) => {
      state.selectedPlacedWindow = action.payload;
    },
    setModifiedWalls: (state, action: PayloadAction<string[]>) => {
      state.modifiedWalls = action.payload;
    },
    setHoveredWall: (state, action: PayloadAction<string | null>) => {
      state.hoveredWall = action.payload;
    },
    setHoveredPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementData | null>
    ) => {
      state.hoveredPlacedWindow = action.payload;
    },
    setFacadeDesignerMode: (
      state,
      action: PayloadAction<FacadeDesignerModes>
    ) => {
      state.facadeDesignerMode = action.payload;
    },
    setDraggedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow | undefined>
    ) => {
      state.draggedWindowFromLibrary = action.payload;
    },

    setGridPlacementAbsoluteOffset: (state, action: PayloadAction<number>) => {
      state.gridPlacementOffset = action.payload;
    },
    setSelectedGridLines: (
      state,
      action: PayloadAction<GridLineDataForFD[]>
    ) => {
      state.selectedGridLines = action.payload;
    },
    addToSelectedGridLines: (
      state,
      action: PayloadAction<GridLineDataForFD>
    ) => {
      state.selectedGridLines.push(action.payload);
    },
    resetSelectedGridLines: (state) => {
      state.selectedGridLines = [];
    },
    setMeasurementActiveWall: (state, action: PayloadAction<string | null>) => {
      state.measurementActiveWall = action.payload;
    },
    setHoveredGridLine: (
      state,
      action: PayloadAction<GridLineDataForFD | null>
    ) => {
      state.hoveredGridLine = action.payload;
    },
  },
});

export const {
  setSelectedWindowFromLibrary,
  resetSelectedWindowFromLibrary,
  resetFacadeDesignerSlice,
  setSelectedPlacedWindow,
  setHoveredPlacedWindow,
  addSelectedPlacedWindow,
  setModifiedWalls,
  setFacadeDesignerMode,
  setDraggedWindowFromLibrary,
  setGridPlacementAbsoluteOffset,
  setSelectedGridLines,
  addToSelectedGridLines,
  setHoveredGridLine,
  setMeasurementActiveWall,
  setHoveredWall,
  resetSelectedGridLines,
} = facadeDesignerSlice.actions;

export const getSelectedWindowFromLibrary = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedWindowFromLibrary;

export const isWindowFromLibrarySelected = (id: number) => (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedWindowFromLibrary?.id === id;

export const isSomeWindowFromLibrarySelected = (state: RootState) =>
  !!state.windowsReducer.facadeDesigner.selectedWindowFromLibrary;

export const getSelectedPlacedWindows = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedPlacedWindow;

export const getHoveredPlacedWindow = (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredPlacedWindow;

export const getModifiedWalls = (state: RootState) =>
  state.windowsReducer.facadeDesigner.modifiedWalls;

export const getDraggedWindowFromLibrary = (state: RootState) =>
  state.windowsReducer.facadeDesigner.draggedWindowFromLibrary;

export const getFacadeDesignerMode = (state: RootState) =>
  state.windowsReducer.facadeDesigner.facadeDesignerMode;

export const getGridPlacementAbsoluteOffset = (state: RootState) =>
  state.windowsReducer.facadeDesigner.gridPlacementOffset;

export const getSelectedGridlines = (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedGridLines;

export const isGridLineSelected = (guid: string) => (state: RootState) =>
  state.windowsReducer.facadeDesigner.selectedGridLines.some(
    (line) => guid === line.guid
  );

export const getHoveredGridLine = (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredGridLine;

export const getMeasurementActiveWall = (state: RootState) =>
  state.windowsReducer.facadeDesigner.measurementActiveWall;

export const getHoveredWall = (state: RootState) =>
  state.windowsReducer.facadeDesigner.hoveredWall;
