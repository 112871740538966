import { useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import arrowUpImage from '@/images/ArrowUp.svg';
import { getCanvasObjectByUUID } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  createSvgElement,
  getScaleRatioForCameraZoom,
} from '@/shared/helpers/canvas-verifiers';
import { FlatVector3 } from '@/models';
import { scaleMesh } from '@/shared/helpers/canvas';

export const useArrowUp = () => {
  const { camera, scene, controls, size } = useThree();
  const [baseMesh, setBaseMesh] = useState<THREE.Mesh | null>(null);
  const arrowUp = useMemo(() => createSvgElement(arrowUpImage), []);
  const [buildingHeight, setBuildingHeight] = useState(0);

  const rotateArrowUp = () => {
    arrowUp.lookAt(camera.position);
    arrowUp.rotateX(Math.PI);
  };

  const setArrowUpPosition = (mesh: THREE.Mesh, height?: number) => {
    const center = new THREE.Vector3();
    mesh.geometry.boundingBox?.getCenter(center);

    const position: FlatVector3 = [
      center.x,
      center.y + 0.15 + (height || buildingHeight),
      center.z,
    ];

    height && setBuildingHeight(height);

    calculateArrowPositionAndScale(position);
  };

  const translateArrowUp = (multiply: number) => {
    if (baseMesh) {
      setArrowUpPosition(baseMesh, multiply);
    }
  };

  const changeArrowVisibility = (value: boolean) => {
    arrowUp.visible = value;
  };

  useEffect(() => {
    if (!getCanvasObjectByUUID(scene, arrowUp.uuid)?.uuid && baseMesh) {
      scaleMesh(arrowUp, 0.005);
      setArrowUpPosition(baseMesh);
      rotateArrowUp();
      scene.add(arrowUp);
    }
    return () => {
      scene.remove(arrowUp);
    };
  }, [baseMesh]);

  const calculateArrowPositionAndScale = (position: FlatVector3) => {
    const scale = getScaleRatioForCameraZoom(camera, size, arrowUp.position, 8);

    const getRestrictedScaleValue = () => {
      if (scale > 0.01) return 0.01;
      if (scale < 0.005) return 0.005;
      return scale;
    };

    const restrictedScaleValue = getRestrictedScaleValue();

    const target = new THREE.Vector3();
    (arrowUp?.children[0] as THREE.Mesh)?.geometry.boundingBox?.getSize(target);

    arrowUp.position.set(
      position[0] - restrictedScaleValue,
      position[1] + (restrictedScaleValue / 2) * target.y,
      position[2] + restrictedScaleValue * target.z
    );
    scaleMesh(arrowUp, restrictedScaleValue);
  };

  useEffect(() => {
    const handleCameraChange = () => {
      baseMesh && setArrowUpPosition(baseMesh);
    };
    getCanvasObjectByUUID(scene, arrowUp.uuid)?.uuid &&
      controls?.addEventListener('change', handleCameraChange);
    return () => {
      controls?.removeEventListener('change', handleCameraChange);
    };
  }, [controls, camera, baseMesh, arrowUp, buildingHeight]);

  return {
    rotateArrowUp,
    translateArrowUp,
    setBaseMesh,
    changeArrowVisibility,
  };
};
