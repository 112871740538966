import React from 'react';
import { Rect } from 'react-konva';
import { WINDOW_GLASS_SELECTED_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { SavedWindow } from '@/components/WindowCreator/models';
import { getWindowHeight, getWindowWidth } from '@/shared/helpers';

interface HighlightedRectProps {
  data: SavedWindow;
  isHovered: boolean;
  isSelected?: boolean;
  offsetX: number;
  offsetY: number;
}

const PlacedWindowStates: React.FC<HighlightedRectProps> = ({
  data,
  isHovered,
  isSelected,
  offsetX,
  offsetY,
}) => {
  const windowWidth = getWindowWidth(data);
  const windowHeight = getWindowHeight(data);

  return (
    <Rect
      x={offsetX - 1}
      y={offsetY - 1 - windowHeight}
      width={windowWidth + 2}
      height={windowHeight + 2}
      stroke={
        isHovered || isSelected ? WINDOW_GLASS_SELECTED_COLOR : 'transparent'
      }
      strokeScaleEnabled={false}
      strokeWidth={2}
      fill={isSelected ? '#0094FF1A' : 'transparent'}
    />
  );
};

export default PlacedWindowStates;
