import { SavedWindow } from '@/components/WindowCreator/models';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { useMemo } from 'react';

export const useWindowProperties = (windowData: SavedWindow) => {
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data?.buildings;

  const windowUsageAmount = useMemo(() => {
    let amount = 0;

    userBuildings?.forEach((building) => {
      building.blocks.forEach((block) => {
        block.storeys.forEach((storey) => {
          storey.walls.forEach((wall) => {
            const amountAtStorey = wall.windowPlacements?.reduce(
              (acc, curr) => (curr.windowId === windowData.id ? acc + 1 : acc),
              0
            );
            if (amountAtStorey) {
              amount += amountAtStorey;
            }
          });
        });
      });
    });
    return amount;
  }, [userBuildings]);
  return { windowUsageAmount };
};
