import React from 'react';
import firstView from './BuildingViews/buildingFirstView.png';
import secondView from './BuildingViews/buildingSecondView.png';
import thirdView from './BuildingViews/buildingThirdView.png';
import fourthView from './BuildingViews/buildingFourthView.png';
import topView from './BuildingViews/buildingTopView.png';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { UnitSystemTypes } from '@/models';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { Position } from '@turf/turf';
import { formatAreaValue } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';

const BuildingProperties = () => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const projectData = useFetchProjectQuery(id!).data!;
  const { data: panelsData } = useGetAllPanelsQuery(id!);
  const constructionSite = projectData.environment.constructionSite;
  const {
    getBlockStoreysCountMetric,
    getBlockHeightMetric,
    getGrossInternalAreaForBlocks,
    getFacadesAreaMetricForBlocks,
    calculateConstructionSiteArea,
    getUsedConstructionSiteArea,
    getAreaUnit,
    calculateGlazedAreaData,
  } = useFrameProperties();
  const constructionSiteArea = calculateConstructionSiteArea(
    constructionSite?.points as Position[]
  );
  const usedConstructionSiteArea = getUsedConstructionSiteArea(projectData);
  const glazedAreaData = calculateGlazedAreaData(
    projectData.buildings!,
    panelsData ?? []
  );

  return (
    <div className="h-full w-full flex flex-col">
      <div className="p-6 box-border grid grid-cols-2 grid-rows-2 gap-3 bg-[#ECEEEE] flex-1 overflow-hidden">
        <div className="flex items-center justify-center">
          <img className="h-full" src={firstView} alt="First View" />
        </div>
        <div className="overflow-hidden flex items-center justify-center">
          <img className="h-full" src={secondView} alt="Second View" />
        </div>
        <div className="overflow-hidden flex items-center justify-center">
          <img className="h-full" src={thirdView} alt="Third View" />
        </div>
        <div className="overflow-hidden flex items-center justify-center">
          <img className="h-full" src={fourthView} alt="Fourth View" />
        </div>
      </div>
      <div className="flex mt-auto text-[10px] ">
        <div className="w-[339px] box-border border-0 border-r border-solid border-light-gray-20 px-5 py-4 flex flex-col gap-1 leading-4">
          <div className="font-medium text-dark-gray-100 uppercase">
            building
          </div>

          <div className="flex justify-between">
            <div className="font-light">Floor count</div>
            <div className="pr-6">
              {getBlockStoreysCountMetric(projectData.buildings![0].blocks)}
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Building height</div>

              <div className="flex gap-1">
                {getBlockHeightMetric(projectData.buildings![0].blocks)}
                <div className="text-dark-gray-60 font-light w-5">
                  {!isImperialUnits && 'mm'}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Gross internal area</div>
              <div className="flex gap-1">
                {getGrossInternalAreaForBlocks(
                  projectData.buildings![0].blocks
                )}
                <div className="text-dark-gray-60 font-light w-5">
                  {getAreaUnit()}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Facades area</div>
              <div className="flex gap-1">
                {getFacadesAreaMetricForBlocks(
                  projectData.buildings![0].blocks
                )}
                <div className="text-dark-gray-60 font-light w-5">
                  {getAreaUnit()}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Glazed facade area</div>
              <div className="flex gap-1">
                <div className="flex gap-1">
                  {glazedAreaData.glazedArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {' '}
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {glazedAreaData.glazedAreaUsage}{' '}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Non-glazed facade area</div>
              <div className="flex gap-1">
                <div className="flex gap-1">
                  {glazedAreaData.nonGlazeArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {' '}
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {glazedAreaData.nonGlazeAreaUsage}{' '}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow flex justify-between px-5 gap-4 leading-4">
          <div className="flex-grow py-4 flex flex-col gap-1">
            <div className="uppercase font-medium">site</div>
            <div className="flex flex-col gap-0.5">
              <div className="flex justify-between">
                <div className="font-light">Site area</div>
                <div className="flex gap-1">
                  {formatAreaValue(constructionSiteArea, isImperialUnits)}
                  <div className="text-dark-gray-60 font-light w-5">
                    {getAreaUnit()}
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-light">Area used</div>
                <div className="flex gap-1">
                  <div className="flex gap-1">
                    {formatAreaValue(
                      usedConstructionSiteArea.occupiedArea,
                      isImperialUnits
                    )}
                    <div className="font-light text-dark-gray-60 w-5">
                      {' '}
                      {getAreaUnit()}
                    </div>
                  </div>
                  <div className="flex gap-1">
                    {usedConstructionSiteArea.usageRate}
                    <div className="font-light text-dark-gray-60 w-5">%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 py-[15px]">
            <img src={topView} className="w-[130px] h-[130px]" alt="top view" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingProperties;
