import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import coloredLogo from '@/images/logo-small-colored.svg';
import { IntusButton, IntusIconButton, IntusTooltip } from '@/shared/elements';
import { SettingsIcon } from '@/shared/icons';
import { useAppSelector } from '@/store/hooks';
import { getCanvasMode, getDrawMode } from '@/store/slices/canvasModesSlice';
import { getProjectName } from '@/store/slices/projectSlice';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import ProjectCardSettings from '@/routes/dashboard/projects/ProjectCardSettings';
import EditToolbarButton from '@/shared/elements/EditToolbar/EditToolbarButton';
import ProjectHeaderToolbar from '@/core/ProjectHeader/ProjectHeaderToolbar';
import ProjectModalReport from '@/routes/dashboard/projects/ProjectModalReport';
import { LockClosedIcon } from '@/shared/icons/LockClosedIcon';
import './ProjectHeader.scss';

const ProjectHeader = () => {
  const { id } = useParams();

  if (!id) return <></>;

  const [isSettingsOpened, setIsSettingsOpened] = useState(false);

  const data = useFetchProjectQuery(id).data!;
  const mode = useAppSelector(getCanvasMode);
  const drawMode = useAppSelector(getDrawMode);
  const projectData = useFetchProjectQuery(id!).data!;
  const [showReportModal, setShowReportModal] = useState(false);

  const projectName = useAppSelector((state) =>
    getProjectName(state, id ?? '')
  );

  return (
    <>
      <div
        id="project-header"
        className="relative flex items-center justify-between bg-white border-0 border-b border-b-light-gray-20 border-solid"
      >
        <div className="flex">
          <Link to="/">
            <div className="bg-white w-12 h-12 flex justify-center items-center hover:!bg-light-gray-10">
              <img src={coloredLogo} alt="logo" />
            </div>
          </Link>
          <ProjectHeaderToolbar
            mode={mode}
            drawMode={drawMode}
            projectId={id}
          />
        </div>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-1 items-center">
          {projectData.locked && (
            <IntusTooltip title="Locked for editing" placement={'bottom'}>
              <IntusIconButton
                type="text"
                transparent
                icon={<LockClosedIcon />}
                id="header__locker"
                className="m-0 p-0 h-fit"
              />
            </IntusTooltip>
          )}
          <span className={'font-medium text-sm leading-[22px]'}>
            {projectName}
          </span>
          <EditToolbarButton
            onClick={() => {
              setIsSettingsOpened(true);
            }}
            icon={<SettingsIcon />}
          />
        </div>
        <IntusButton
          id="report-button"
          disabled={!data.buildings?.length}
          onClick={() => setShowReportModal((prev) => !prev)}
          className="rounded-lg mr-6"
        >
          Report
        </IntusButton>
      </div>
      <ProjectModalReport
        data={data}
        isOpen={showReportModal}
        setIsOpen={setShowReportModal}
      />
      {data && isSettingsOpened && (
        <ProjectCardSettings
          //TODO: Remove isOpen from modals in future. Should be operated on higher level
          isOpen={true}
          data={data}
          setIsOpen={setIsSettingsOpened}
        />
      )}
    </>
  );
};

export default ProjectHeader;
