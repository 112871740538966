import {
  getColorData,
  getMaterialDescription,
} from '@/components/WindowCreator/helpers/config';
import { UserBuildingPanel } from '@/models';
import { PanelMaterial, WindowColor } from '@/models/window-configurator.model';
import IntusTable from '@/shared/elements/IntusTable/IntusTable';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import React from 'react';

const PanelColorsTable = ({ panelData }: { panelData: UserBuildingPanel }) => {
  const configData = useFetchWindowConfigQuery().data!;

  const dataSource = [
    {
      key: `panel-panel-id-${panelData.id}-colors-frameAndMullions`,
      panelElement: 'Frame and mullions',
      insideColor: (
        <ColorDisplay
          colorName={panelData.frameInsideColor}
          colors={configData.colors}
        />
      ),
      outsideColor: (
        <ColorDisplay
          colorName={panelData.frameOutsideColor}
          colors={configData.colors}
        />
      ),
      material: (
        <MaterialDisplay
          materialName={panelData.frameMaterial}
          materials={configData.panelMaterials}
        />
      ),
    },
    {
      key: `panel-panel-id-${panelData.id}-colors-fillers`,
      panelElement: 'Filler',
      insideColor: (
        <ColorDisplay
          colorName={panelData.fillerInsideColor}
          colors={configData.colors}
        />
      ),
      outsideColor: (
        <ColorDisplay
          colorName={panelData.fillerOutsideColor}
          colors={configData.colors}
        />
      ),
      material: (
        <MaterialDisplay
          materialName={panelData.fillerMaterial}
          materials={configData.panelMaterials}
        />
      ),
    },
  ];

  const columns = [
    {
      key: `panel-element-column-${panelData.id}`,
      title: '',
      dataIndex: 'panelElement',
      rowScope: 'row' as const, //   For some reason without it the engine throws an error Type 'string' is not assignable to type 'RowScopeType | undefined'
    },
    {
      key: `panel-inside-color-column-${panelData.id}`,
      title: <div className="text-start">Inside Color </div>,
      dataIndex: 'insideColor',
      width: '28%',
    },
    {
      key: `panel-outside-color-column-${panelData.id}`,
      title: <div className="text-start">Outside Color</div>,
      dataIndex: 'outsideColor',
      width: '28%',
    },
    {
      key: `panel-material-column-${panelData.id}`,
      title: <div className="text-start"> Material</div>,
      dataIndex: 'material',
      width: '28%',
    },
  ];

  return (
    <IntusTable
      dataSource={dataSource}
      columns={columns}
      bordered={false}
      pagination={false}
    />
  );
};

export default PanelColorsTable;

export const ColorDisplay = ({
  colorName,
  colors,
}: {
  colorName: string;
  colors: WindowColor[];
}) => {
  return (
    <div className="flex items-center h-full w-full gap-2">
      <div
        className="w-3 h-3 leading-4 box-border border-solid border border-light-gray-20 rounded-full"
        style={{
          backgroundColor: getColorData(colorName, colors)?.hex,
        }}
      />
      <div className="flex flex-col text-[10px]">
        <div className="font-light leading-4">{colorName}</div>
        <div className="font-light text-light-gray-60 leading-[14px]">
          {getColorData(colorName, colors)?.description.split(' - ')[1]}
        </div>
      </div>
    </div>
  );
};

const MaterialDisplay = ({
  materialName,
  materials,
}: {
  materialName: string;
  materials: PanelMaterial[];
}) => {
  const materialDescription = getMaterialDescription(materialName, materials);
  return (
    <div className="flex flex-col font-light text-[10px]">
      <div className="leading-4">{materialName}</div>
      {materialDescription && (
        <div className="text-dark-gray-60 leading-[14px]">
          {materialDescription}
        </div>
      )}
    </div>
  );
};
