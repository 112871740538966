import React, { useEffect, useMemo, useRef, useState } from 'react';
import IntusTooltip from '../Tooltip/Tooltip';
import WindowInfoPopover from '@/routes/dashboard/projects/project/CanvasExternalElements/LeftPanel/LibraryPanel/WindowInfoPopover/WindowInfoPopover';
import WindowContextMenu from '@/routes/dashboard/projects/project/CanvasExternalElements/LeftPanel/LibraryPanel/WindowContextMenu/WindowContextMenu';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { FacadeDesignerModes } from '@/models/shared.model';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getDraggedWindowFromLibrary,
  getModifiedWalls,
  isWindowFromLibrarySelected,
  resetFacadeDesignerSlice,
  setDraggedWindowFromLibrary,
  setFacadeDesignerMode,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useWindowProperties } from '@/shared/hooks/useWindowProperties';
import {
  clearSelectedNodes,
  getHoveredNode,
  getSelectedNodes,
} from '@/store/slices/canvasBuildingSlice';
import { SelectedNode } from '@/models';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useUpdateEffect } from 'react-use';
import { useParams } from 'react-router';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';

interface WindowCardProps {
  facadeDesignerMode: FacadeDesignerModes;
  isDesignerMode: boolean;
  windowData: SavedWindow;
  onSelect: (id: SavedWindow) => void;
}

const WindowCard: React.FC<WindowCardProps> = ({
  facadeDesignerMode,
  isDesignerMode,
  windowData,
  onSelect,
}) => {
  const { id } = useParams();
  const [dragPosition, setDragPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isCardHighlighted, setIsCardHighlighted] = useState(false);
  const [shouldCheckHighlightStatus, setShouldCheckHighlightStatus] =
    useState(true);
  const windowRef = useRef<HTMLDivElement>(null);
  const projectData = useFetchProjectQuery(id!).data!;
  const [isWindowNameHovered, setIsWindowNameHovered] = useState(false);
  const selected = useAppSelector(isWindowFromLibrarySelected(windowData.id));
  const imageRef = useRef<HTMLImageElement>(null);
  const draggedWindowFromLibrary = useAppSelector(getDraggedWindowFromLibrary);
  const isWindowCardDragging =
    !!draggedWindowFromLibrary && draggedWindowFromLibrary.id === windowData.id;
  const dispatch = useAppDispatch();
  const selectedNodes = useAppSelector(getSelectedNodes);
  const modifiedWallsInFacadeDesigner = useAppSelector(getModifiedWalls);
  const { addNodesToSelectedNodes } = useSelectedNodes();
  const [previousSelectedNodes, setPreviousSelectedNodes] = useState<{
    [guid: string]: SelectedNode;
  } | null>(null);
  const hoveredNode = useAppSelector(getHoveredNode);

  const { windowUsageAmount } = useWindowProperties(windowData);
  const isLocked = projectData.locked;

  const disabled = useMemo(() => {
    return isDesignerMode && !modifiedWallsInFacadeDesigner?.length;
  }, [facadeDesignerMode, isDesignerMode, modifiedWallsInFacadeDesigner]);

  const handleMouseDown = (e: React.MouseEvent) => {
    // Usual drag and drop for some reason prevent hover in Viewer
    if (isDesignerMode || isLocked) return;
    e.preventDefault();
    document.body.style.cursor = 'grabbing';
    dispatch(setDraggedWindowFromLibrary(windowData));
    setIsTooltipVisible(false);
    setPreviousSelectedNodes(selectedNodes);
    dispatch(clearSelectedNodes());
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isWindowCardDragging) {
      setDragPosition({ x: e.clientX, y: e.clientY });
      onSelect(windowData);
    }
  };

  const handleMouseUp = () => {
    dispatch(setDraggedWindowFromLibrary(undefined));
    setIsTooltipVisible(false);
    setDragPosition(null);
    document.body.style.cursor = 'default';
  };

  const handleCardClick = () => {
    if (isDesignerMode && !disabled && !isLocked) {
      dispatch(setFacadeDesignerMode(FacadeDesignerModes.WindowPlacement));
      onSelect(windowData);
    }
  };

  useUpdateEffect(() => {
    if (!hoveredNode && !isWindowCardDragging) {
      dispatch(resetFacadeDesignerSlice());
    }
  }, [isWindowCardDragging]);

  useEffect(() => {
    if (isWindowCardDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    if (!modifiedWallsInFacadeDesigner?.length && !isWindowCardDragging) {
      addNodesToSelectedNodes(Object.values(previousSelectedNodes || {}));
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isWindowCardDragging]);

  const disableHighlightStatus = () => {
    setIsCardHighlighted(false);
    setShouldCheckHighlightStatus(false);
  };

  useEffect(() => {
    if (!shouldCheckHighlightStatus) return;
    const checkHighlightStatus = () => {
      const timeDiff =
        (new Date().getTime() - new Date(windowData.createdAt).getTime()) /
        1000;

      if (timeDiff <= 30) {
        setIsCardHighlighted(true);
      } else {
        setIsCardHighlighted(false);
      }
    };

    checkHighlightStatus();
    const interval = setInterval(checkHighlightStatus, 1000);

    return () => clearInterval(interval);
  }, [windowData.createdAt, shouldCheckHighlightStatus]);

  useEffect(() => {
    isCardHighlighted &&
      document.addEventListener('mousedown', disableHighlightStatus);

    return () => {
      isCardHighlighted &&
        document.removeEventListener('mousedown', disableHighlightStatus);
    };
  }, [isCardHighlighted]);

  const handleTooltipVisibleChange = (visible: boolean) => {
    if (!isWindowCardDragging && !isDesignerMode) {
      setIsTooltipVisible(visible);
    }
  };

  const getBorderClass = () => {
    if (disabled) return '';

    if (selected) {
      return 'outline outline-2 outline-offset-[-1.5px] outline-light-green-100';
    }
    if (isCardHighlighted) {
      return 'outline outline-light-green-60 outline-offset-[-1.5px]';
    }
    return '';
  };

  useEffect(() => {
    selected && onSelect(windowData);
  }, [windowData]);

  return (
    <>
      <div
        className={`h-[152px] box-border ${getBorderClass()} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <IntusTooltip
          title={
            <span>
              To apply drag and drop <br />
              the window to the wall
            </span>
          }
          trigger="hover"
          open={isTooltipVisible && !disabled && !isDesignerMode}
          onOpenChange={handleTooltipVisibleChange}
          placement="left"
          mouseEnterDelay={0.5}
          overlayStyle={{ marginRight: '4px' }}
        >
          <div
            onMouseDown={handleMouseDown}
            className={`h-[118px] bg-light-gray-10 flex justify-center items-center ${disabled || isDesignerMode || isWindowCardDragging || isLocked ? '' : 'cursor-grab'}`}
            onClick={handleCardClick}
          >
            <div className="h-24 mx-4" ref={windowRef} draggable={false}>
              <img
                draggable={false}
                className="object-contain w-full h-full"
                src={windowData.image}
                alt="generated window image"
              />
            </div>
          </div>
        </IntusTooltip>
        <IntusTooltip
          title={isWindowNameHovered ? windowData.name : ''}
          placement="right"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0}
          overlayStyle={{ marginLeft: '4px' }}
        >
          <div className="py-1 px-2 flex justify-between items-center">
            <div className="flex items-center h-5">
              <div
                onMouseEnter={() => setIsWindowNameHovered(true)}
                onMouseLeave={() => setIsWindowNameHovered(false)}
                className={`font-normal leading-5 text-xs mr-1 max-w-[114px] truncate ...`}
              >
                {windowData.name}
              </div>
              <WindowInfoPopover
                windowData={windowData}
                disabled={disabled}
                windowUsageAmount={windowUsageAmount}
              />
            </div>
            <WindowContextMenu
              disabled={disabled || isLocked}
              windowData={windowData}
              windowUsageAmount={windowUsageAmount}
            />
          </div>
        </IntusTooltip>
      </div>
      {/*Divider in this element counts as a part of a window card*/}
      <div
        className={`bg-light-gray-20 h-[2px] ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      />
      {isWindowCardDragging && dragPosition && (
        <img
          style={{
            position: 'fixed',
            left: dragPosition.x,
            top: dragPosition.y,
            pointerEvents: 'none',
            zIndex: 1000,
          }}
          ref={imageRef}
          className="w-12"
          src={windowData.image}
        />
      )}
    </>
  );
};

export default WindowCard;
