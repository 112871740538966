import React, { FC } from 'react';
import IntusButton from '../Button/Button';

interface ShadowButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const ShadowButton: FC<ShadowButtonProps> = ({ onClick, children }) => {
  return (
    <IntusButton id="shadow-button" onClick={onClick} type="text">
      {children}
    </IntusButton>
  );
};

export default ShadowButton;
