import React, { useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { NodeType } from '@/models';
import {
  getCursorCoordinatesOnOrthographicSystem,
  isLeftClick,
} from '@/shared/helpers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getSelectedNodes,
  resetHoveredNode,
  selectNodes,
  setHoveredNode as setHoveredNodeReducer,
} from '@/store/slices/canvasBuildingSlice';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';

const CanvasFacadeDesignerActionHandlers = () => {
  const dispatch = useAppDispatch();
  const { raycaster, scene, gl, camera } = useThree();

  const selectedGuids = Object.keys(useAppSelector(getSelectedNodes));
  const { getFacadeWallsByWallGuid, getFacadeContourLines } = useFacadeData();
  const { selectNode } = useSelectedNodes();

  const [tabPressed, setTabPressed] = useState(false);
  const [facadeGuids, setFacadeGuids] = useState<string[]>([]);
  const [hoveredWallGuid, setHoveredWallGuid] = useState<string | undefined>();
  const [facadeContour, setFacadeContour] = useState<THREE.Group>(
    new THREE.Group()
  );
  const getWallUnderMouse = (event: PointerEvent) => {
    const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
      event,
      gl
    );

    raycaster.setFromCamera(cursorCoordinates, camera);

    const intersectsFirst = raycaster
      .intersectObjects(scene.children, true)
      .filter(
        (intersect) =>
          ![NodeType.Window, NodeType.Panel].includes(
            intersect.object.userData.nodeType
          )
      )[0];

    if (
      !intersectsFirst ||
      intersectsFirst.object?.userData?.nodeType !== NodeType.Wall
    )
      return;

    return intersectsFirst.object.userData?.guid
      ? {
          guid: intersectsFirst.object.userData?.guid,
          isLocked: intersectsFirst.object.userData?.isLocked,
          isSelected: intersectsFirst.object.userData?.isSelected,
        }
      : undefined;
  };

  const isAbleToSelectWall = (wallGuid: string, shiftKey: boolean) => {
    if (!shiftKey) return true;

    return (
      facadeGuids.some((guid) => guid === wallGuid) || facadeGuids.length === 0
    );
  };

  const handlePointerDown = (event: PointerEvent) => {
    if (!isLeftClick(event)) return;

    const wall = getWallUnderMouse(event);

    if (!wall) {
      return;
    }
    if (selectedGuids.length === 0 || !event.shiftKey) {
      const facade = getFacadeWallsByWallGuid(wall.guid);
      facade && setFacadeGuids(facade.map((wall) => wall.guid));
    }

    if (tabPressed) {
      const facade = getFacadeWallsByWallGuid(wall.guid);

      facade &&
        dispatch(
          selectNodes(
            facade.map((wall) => ({ guid: wall.guid, type: NodeType.Wall }))
          )
        );
      return;
    }

    if (isAbleToSelectWall(wall.guid, event.shiftKey)) {
      selectNode({
        type: NodeType.Wall,
        guid: wall.guid,
        isLocked: wall.isLocked,
        isSelected: wall.isSelected,
        ignoreSelectionMode: true,
        event,
      });
    }
  };

  const showFacadeContour = (wallGuid: string) => {
    const contourLines = getFacadeContourLines(wallGuid);
    const group = new THREE.Group();

    contourLines.forEach((line) => group.add(line));

    dispatch(resetHoveredNode());
    setFacadeContour(group);
  };

  const hideFacadeContour = () => setFacadeContour(new THREE.Group());

  const handlePointerMove = (event: PointerEvent) => {
    const wall = getWallUnderMouse(event);

    if (!wall) {
      hoveredWallGuid && setHoveredWallGuid('');
      return;
    }

    if (
      wall.guid !== hoveredWallGuid &&
      isAbleToSelectWall(wall.guid, event.shiftKey)
    ) {
      setHoveredWallGuid(wall.guid);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      if (!tabPressed) {
        setTabPressed(true);
      }
    }
    if (event.shiftKey && hoveredWallGuid) {
      isAbleToSelectWall(hoveredWallGuid, event.shiftKey)
        ? dispatch(setHoveredNodeReducer(hoveredWallGuid))
        : dispatch(resetHoveredNode());
    }
  };
  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      event.stopPropagation();
      setTabPressed(false);
      hoveredWallGuid && dispatch(setHoveredNodeReducer(hoveredWallGuid));
      hideFacadeContour();
    }
    if (!event.shiftKey && hoveredWallGuid) {
      isAbleToSelectWall(hoveredWallGuid, event.shiftKey)
        ? dispatch(setHoveredNodeReducer(hoveredWallGuid))
        : dispatch(resetHoveredNode());
    }
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;

    canvas.addEventListener('pointerdown', handlePointerDown);
    canvas.addEventListener('pointermove', handlePointerMove);

    return () => {
      canvas.removeEventListener('pointerdown', handlePointerDown);
      canvas.removeEventListener('pointermove', handlePointerMove);
    };
  }, [tabPressed, facadeGuids, hoveredWallGuid]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [tabPressed, hoveredWallGuid]);

  useEffect(() => {
    if (hoveredWallGuid) {
      dispatch(setHoveredNodeReducer(hoveredWallGuid));
    }
  }, [hoveredWallGuid]);

  useEffect(() => {
    selectedGuids && setFacadeGuids(selectedGuids);
  }, []);

  useEffect(() => {
    tabPressed && hoveredWallGuid && showFacadeContour(hoveredWallGuid);
    !tabPressed && hideFacadeContour();
  }, [hoveredWallGuid, tabPressed]);

  return <>{facadeContour && <primitive object={facadeContour} />}</>;
};

export default CanvasFacadeDesignerActionHandlers;
