import React from 'react';

const CursorClickIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.625 2C6.83211 2 7 2.16789 7 2.375V3.875C7 4.08211 6.83211 4.25 6.625 4.25C6.41789 4.25 6.25 4.08211 6.25 3.875V2.375C6.25 2.16789 6.41789 2 6.625 2ZM3.70818 3.20818C3.85463 3.06174 4.09207 3.06174 4.23851 3.20818L5.29918 4.26884C5.44562 4.41529 5.44562 4.65273 5.29918 4.79917C5.15273 4.94562 4.91529 4.94562 4.76885 4.79917L3.70818 3.73851C3.56174 3.59207 3.56174 3.35463 3.70818 3.20818ZM9.54182 3.20818C9.68826 3.35463 9.68826 3.59207 9.54182 3.73851L8.48116 4.79917C8.33471 4.94562 8.09727 4.94562 7.95083 4.79917C7.80438 4.65273 7.80438 4.41529 7.95083 4.26884L9.01149 3.20818C9.15793 3.06174 9.39537 3.06174 9.54182 3.20818ZM2.5 6.125C2.5 5.91789 2.66789 5.75 2.875 5.75H4.375C4.58211 5.75 4.75 5.91789 4.75 6.125C4.75 6.33211 4.58211 6.5 4.375 6.5H2.875C2.66789 6.5 2.5 6.33211 2.5 6.125ZM7.48014 5.97448C6.99165 5.5674 6.25 5.91476 6.25 6.55064V13.377C6.25 14.0695 7.1084 14.392 7.56443 13.8708L9.084 12.1342C9.29763 11.89 9.60624 11.75 9.93065 11.75H12.3392C13.0407 11.75 13.3583 10.8729 12.8194 10.4238L7.48014 5.97448ZM7 13.377L7 6.55064L12.3392 11H9.93065C9.38997 11 8.87561 11.2334 8.51957 11.6403L7 13.377Z"
        fill="#414042"
        stroke="#414042"
        strokeWidth="0.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CursorClickIcon;
