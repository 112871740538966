import React from 'react';
import { EditableMetric } from '../EditableMetric/EditableMetric';
import { InputProps } from 'antd';
import { IntusIconButton } from '@/shared/elements';

export type FrameProperty = {
  name: string;
  value: string;
  units?: string;
  isEditable?: boolean;
  inputActionProps?: {
    display: boolean;
    onActionClick: () => void;
    id: string;
    icon: React.ReactNode;
  };
  onEdit?: (value: string) => void;
  onSubmit?: () => void;
  inputProps?: InputProps;
  min?: number;
  max?: number;
  validationValueDecimalPlaces?: number;
  staticValue?: boolean;
  isDisabled?: boolean;
};

export interface PropertyListProps {
  properties: FrameProperty[];
  alignValueLeft?: boolean;
}

const PropertyList: React.FC<PropertyListProps> = ({
  properties,
  alignValueLeft,
}) => {
  return (
    <div
      className={`flex flex-col justify-center mb-3 ${alignValueLeft ? 'mt-2' : 'mt-3'}`}
    >
      {properties.map((property) => {
        return (
          <div
            key={property.name}
            className={`flex flex-wrap justify-between min-h-6 px-3`}
          >
            <span className="font-light leading-6 whitespace-nowrap flex mb-[2px] mr-auto">
              {property.name}
            </span>
            <div
              className={`font-normal mb-[2px] ${
                alignValueLeft ? 'text-left w-24' : 'text-right'
              } ${property.units || alignValueLeft ? 'flex gap-1' : ''} ${
                !alignValueLeft && !property.units ? 'mr-3' : ''
              } break-words flex items-center`}
            >
              {property.isEditable &&
              property.onEdit &&
              property.onSubmit &&
              !property.isDisabled ? (
                <>
                  <EditableMetric
                    value={property.value}
                    onEdit={property.onEdit}
                    onSubmit={property.onSubmit}
                    inputProps={property.inputProps}
                    min={property.min}
                    max={property.max}
                    staticValue={property.staticValue}
                    validationValueDecimalPlaces={
                      property.validationValueDecimalPlaces
                    }
                  />
                </>
              ) : (
                <>
                  <span
                    className={`inline-block w-full text-left ${alignValueLeft && !property.isEditable ? 'pl-1' : ''} ${property.isDisabled ? 'opacity-50' : 'opacity-100'}`}
                  >
                    {property.value}
                  </span>
                  {property.units ? (
                    <span className="font-light text-black-40">
                      {property.units}
                    </span>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
            {property.inputActionProps && (
              <div className={`mx-1 w-6 h-6`}>
                <IntusIconButton
                  type="default"
                  size="small"
                  simplified
                  className={`${property.inputActionProps.display ? 'block' : '!hidden'}`}
                  icon={property.inputActionProps.icon}
                  id={property.inputActionProps.id}
                  onClick={property.inputActionProps.onActionClick}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PropertyList;
