import { Group, Image, Line } from 'react-konva';
import useImage from 'use-image';
import React from 'react';
import ManContourSVG from '@/images/man-contour.svg';
import {
  BORDERLINE_DEFAULT_POINTS,
  INITIAL_SCALE,
  MAN_CONTOUR_PARAMS,
  STAGE_BOTTOM_PADDING_TO_FLOOR_0,
  STAGE_HEIGHT,
} from '@/components/WindowCreator/constants';
import { Measurement } from '@/components/WindowCreator/elements/Measurements';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models/konva-model';
import { FlatVector2, UnitSystemTypes } from '@/models';

interface WindowManContourProps {
  scale: number;
  units: UnitSystemTypes;
  outerFramePoints: FlatVector2[];
}

export const WindowManContour = ({
  scale,
  units,
  outerFramePoints,
}: WindowManContourProps) => {
  const [image] = useImage(ManContourSVG);

  const xOffset =
    outerFramePoints[0][0] -
    (MAN_CONTOUR_PARAMS.offsetFromWindowFrame + MAN_CONTOUR_PARAMS.width) /
      INITIAL_SCALE;

  const points: FlatVector2Axis = [
    [xOffset, (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE],
    [xOffset, (STAGE_HEIGHT - MAN_CONTOUR_PARAMS.height) / INITIAL_SCALE],
  ];

  return (
    <Group listening={false}>
      <Line
        dashEnabled
        points={BORDERLINE_DEFAULT_POINTS.flat()}
        stroke={'#B3B2B4'}
        strokeWidth={1 / INITIAL_SCALE}
        dash={[5 / INITIAL_SCALE, 5 / INITIAL_SCALE]}
      />
      <Image
        image={image}
        scaleX={1 / INITIAL_SCALE}
        scaleY={1 / INITIAL_SCALE}
        x={xOffset}
        y={(STAGE_HEIGHT - MAN_CONTOUR_PARAMS.height) / INITIAL_SCALE}
      />
      <Measurement
        type={MeasurementElementType.Contour}
        units={units}
        scale={scale}
        disabled
        key={`measurement_offset`}
        points={points}
      ></Measurement>
    </Group>
  );
};
