import React from 'react';
import PanelViewItem, { PanelView } from './PanelViewItem/PanelViewItem';
import eastView from './PanelScreens/east.png';
import northView from './PanelScreens/north.png';
import southView from './PanelScreens/south.png';
import westView from './PanelScreens/west.png';
import { Sides, UnitSystemTypes } from '@/models';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { convertSquareMetersToSquareFeet } from '@/shared/helpers/distance';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

const panelViews: PanelView[] = [
  {
    imageUrl: northView,
    side: Sides.North,
  },
  {
    imageUrl: eastView,
    side: Sides.East,
  },
  {
    imageUrl: southView,
    side: Sides.South,
  },
  {
    imageUrl: westView,
    side: Sides.West,
  },
];

const PanelProperties = () => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const { getAreaUnit } = useFrameProperties();
  return (
    <div className="h-full w-full flex flex-col ">
      <div className="p-6 box-border grid grid-cols-2 grid-rows-2 gap-3 bg-[#ECEEEE] flex-1 overflow-hidden relative">
        {panelViews.map((view) => {
          return (
            <PanelViewItem
              key={view.side}
              imageUrl={view.imageUrl}
              side={view.side}
            />
          );
        })}
        <div className="absolute bottom-0 left-0 bg-[#F9F9F9] px-2 py-1 font-light leading-4 text-[10px] text-dark-gray-100">
          Areas marked in red are out of scope and not included into quote
        </div>
      </div>
      <div className="flex mt-auto text-[10px] px-5 leading-4 gap-5">
        <div className="flex-grow min-w-[196px] py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            Panel metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Panel count</div>
              <div>850</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Straight panels</div>
              <div>860</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Corner panels</div>
              <div>170</div>
            </div>
          </div>
        </div>
        <div className="flex-grow  py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            Panels area metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Facades area</div>
              <div className="flex gap-1">
                <div>
                  {isImperialUnits
                    ? convertSquareMetersToSquareFeet(1734.9)
                    : '1 734.9'}
                </div>
                <div className="font-light text-dark-gray-60 w-5">
                  {getAreaUnit()}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Panelized area</div>
              <div className="flex gap-1">
                <div className="flex gap-1">
                  {isImperialUnits
                    ? convertSquareMetersToSquareFeet(173.9)
                    : 173.49}
                  <div className="font-light text-dark-gray-60 w-5">
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  10 <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Non-panelized area</div>
              <div className="flex gap-1">
                <div className="flex gap-1">
                  {isImperialUnits
                    ? convertSquareMetersToSquareFeet(1561.41)
                    : '1 561.41'}
                  <div className="font-light text-dark-gray-60 w-5">
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  10 <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-px h-full bg-light-gray-20" />
        <div className="flex-1 min-w-[196px]  py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            Window metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Windows count</div>
              <div>30</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Operable windows</div>
              <div>20</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Non-operable windows</div>
              <div>10</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelProperties;
