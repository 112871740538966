import { GridLineData, UserBuildingWall } from '@/models';

export interface WallToCornerAlignments {
  wallData: UserBuildingWall;
  left: boolean;
  right: boolean;
}

export interface GridLineDataForFD extends GridLineData {
  absoluteOffset: number;
  wallGUID: string;
}

export enum GridLineConditions {
  placing = 'placing',
  placingEmpty = 'placingEmpty',
  placed = 'placed',
  placedEmpty = 'placedEmpty',
  placedHover = 'placedHover',
  placedSelected = 'placedSelected',
  corner = 'corner',
  error = 'error',
}
export interface GridLineSettings {
  stroke: string;
  dash?: number[];
}

export const GridLineConfig: { [key in GridLineConditions]: GridLineSettings } =
  {
    [GridLineConditions.placing]: {
      stroke: '#65BD51',
      dash: [15, 15],
    },
    [GridLineConditions.placingEmpty]: {
      stroke: '#CCCDCE',
      dash: [15, 15],
    },
    [GridLineConditions.placed]: {
      stroke: '#808285',
    },
    [GridLineConditions.placedEmpty]: {
      stroke: '#CCCDCE',
    },
    [GridLineConditions.placedHover]: {
      stroke: '#529F43',
    },
    [GridLineConditions.placedSelected]: {
      stroke: '#65BD51',
    },

    [GridLineConditions.corner]: {
      stroke: '#B3B4B6',
    },
    [GridLineConditions.error]: {
      stroke: '#D23736',
      dash: [15, 15],
    },
  };
