import React, { useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import {
  convertBufferGeometryTo3DVectorList,
  createLine2,
  getExistingCoordinates,
  getExtendedVector,
  getTranslatedVector,
  getXYZ,
  setGeometryPointPositionByIdx,
  setObjectPosition,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  C_DashedFatLineContourMaterial,
  C_DashedLineMaterial,
  C_FatLineContourMaterial,
} from '@/shared/materials';
import {
  DirectionalInputEntity,
  getProcessingEntity,
  resetExternalElementsState,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import { DistanceInput, MetricLimits, NumberedInput } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import { getMapUUID } from '@/store/slices/canvasMapSlice';
import {
  generateEdgePoint,
  pointTargetOnMap,
  PROJECT_CANVAS_ID,
} from '@/shared/helpers/canvas-verifiers';
import { hideSelectionBoxArea, isLeftClick } from '@/shared/helpers';
import { RootState, useThree } from '@react-three/fiber';
import { publish, subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
  SET_BUILDING_CREATION_PARAMETERS,
} from '@/core/event-names';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { flatten } from 'lodash';
import { LineGeometry } from 'three-stdlib';
import ScalableDot from '@/shared/components/Dot/ScalableDot';
import {
  BUILDING_WIDTH_IN_METERS,
  FLOORS_INITIAL_COUNT,
  INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS,
} from '@/shared/constants';
import {
  getCenterLineParamsForRightAngle,
  updateSections,
} from '@/shared/helpers/rectangle-mode';
import { useParams } from 'react-router';
import { getMultiplyRate } from '@/store/slices/projectSlice';

const MAX_POINTS = 50;

const CenterLineDrawMode = () => {
  const dispatch = useAppDispatch();
  const processingEntity: DirectionalInputEntity =
    useAppSelector(getProcessingEntity)!;
  const isDirectionalInputActive = processingEntity.active;
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const mapUUID = useAppSelector(getMapUUID);
  const scene: RootState = useThree();
  const [buildingWidth, setBuildingWidth] = useState<number>(
    BUILDING_WIDTH_IN_METERS * multiplyRate
  );

  const [centerLine, setCenterLine] = useState<THREE.Line>(null!);
  const [fatCenterLine, setFatCenterLine] = useState<Line2>(null!);
  const [fatDashedCenterLine, setFatDashedCenterLine] = useState<Line2>(null!);
  const [nextPoint, setNextPoint] = useState<THREE.Mesh>(null!);
  // Coordinates of mouse pointer, not a real object
  const [pointerPosition, setPointerPosition] = useState<THREE.Vector3 | null>(
    null
  );
  const [isDrawing, setIsDrawing] = useState(false);
  const [isBuildingFinished, setIsBuildingFinished] = useState(false);
  const [processingNewSection, setProcessingNewSection] =
    useState<boolean>(false);

  const [firstFloorSections, setFirstFloorSections] = useState<THREE.Mesh[]>(
    []
  );
  const [floorShape, setFloorShape] = useState<THREE.Mesh>(null!);

  // Temporary solution, as requested by ticket IGIG-382.
  // Will be removed later, when we decide to refactor center line drawing
  // Need to call creation of CenterLined building without counting new section, which user did not close
  const [closedFloor, setClosedFloor] = useState<THREE.Mesh>(null!);

  const [contour, setContour] = useState<Line2>(null!);

  const edges: THREE.Vector3[] = useMemo(() => [], []);

  const edgeOnPointer = useMemo(() => {
    const liveEdge = generateEdgePoint();
    setNextPoint(liveEdge);
    return liveEdge;
  }, []);

  const updateBuildingWidth = (val: string) => {
    const width = convertMillimetersToMeters(val) * multiplyRate;

    const centerLineVectors = convertBufferGeometryTo3DVectorList(
      centerLine!.geometry
    );

    const newSections: THREE.Mesh[] = [];

    for (let i = 0; i < centerLineVectors.length - 1; i++) {
      updateSections({
        closeSection: false,
        nextPoint: centerLineVectors[i + 1],
        sections: newSections,
        width,
        generateFatCenterLine,
        updateContour,
        setProcessingNewSection,
        setFloorShape,
        processingSection: false,
        floor: floorShape,
        activeCenterLine: [centerLineVectors[i], centerLineVectors[i + 1]],
        previousCenterLinePoint:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
      });
      updateSections({
        closeSection: false,
        nextPoint: centerLineVectors[i + 1],
        sections: newSections,
        width,
        processingSection: true,
        floor: floorShape,
        generateFatCenterLine,
        updateContour,
        setProcessingNewSection,
        setFloorShape,
        activeCenterLine: [centerLineVectors[i], centerLineVectors[i + 1]],
        previousCenterLinePoint:
          i === 0 ? new THREE.Vector3() : centerLineVectors[i - 1],
      });

      i < centerLineVectors.length - 2 && setProcessingNewSection(false);
    }

    setFirstFloorSections(newSections);
    setBuildingWidth(width);
  };

  const addNextPoint = (point: THREE.Vector3) => {
    edges.push(new THREE.Vector3(point.x, point.y + 0.002, point.z));
    if (!centerLine) {
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(MAX_POINTS * 3); // 3 vertices per point
      geometry.setAttribute(
        'position',
        new THREE.BufferAttribute(positions, 3)
      );

      setGeometryPointPositionByIdx(0, geometry, getXYZ(point));
      setGeometryPointPositionByIdx(1, geometry, getXYZ(point));
      geometry.setDrawRange(0, 2);

      // line
      const line = new THREE.Line(geometry, C_DashedLineMaterial);
      setCenterLine(line);
      setIsDrawing(true);
      dispatch(
        setShowDirectionalInput({
          isShow: true,
        })
      );
      dispatch(
        setDirectionalInputValues([
          {
            type: DistanceInput.Distance,
            display: true,
            processing: true,
            min: MetricLimits.WidthLengthMin,
            max: MetricLimits.CenterLineBuildingWidthLengthMax,
          },
          {
            type: DistanceInput.BuildingWidth,
            display: true,
            processing: true,
            value: convertMetersToMillimeters(buildingWidth / multiplyRate),
          },
        ])
      );
    } else if (isDrawing) {
      const centerLinePosition = centerLine.geometry.getAttribute('position');

      centerLinePosition.setXYZ(
        centerLine.geometry.drawRange.count - 1,
        ...getXYZ(point)
      );
      centerLinePosition.setXYZ(
        centerLine.geometry.drawRange.count,
        ...getXYZ(point)
      );
      centerLine.geometry.setDrawRange(
        0,
        (centerLine.geometry.drawRange.count % MAX_POINTS) + 1
      );
      centerLinePosition.needsUpdate = true;

      const centerLineVectors = convertBufferGeometryTo3DVectorList(
        centerLine!.geometry
      );
      updateSections({
        closeSection: true,
        setClosedFloor,
        nextPoint: point,
        sections: firstFloorSections,
        width: buildingWidth,
        floor: floorShape,
        processingSection: processingNewSection,
        generateFatCenterLine,
        updateContour,
        setProcessingNewSection,
        setFloorShape,
        activeCenterLine: getActiveCenterLineSection(),
        previousCenterLinePoint:
          centerLineVectors.length < 3
            ? new THREE.Vector3()
            : centerLineVectors[centerLineVectors.length - 3],
      });
      updateDirectionalInputValueForDistance();

      dispatch(
        setDirectionalInputValues([{ ...processingEntity, active: false }])
      );
    }
  };

  const updateDirectionalInputValueForDistance = () => {
    const distance = getActiveCenterLineSection()[0].distanceTo(
      getActiveCenterLineSection()[1]
    );
    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          value: convertMetersToMillimeters(
            (distance / multiplyRate).toString()
          ).toString(),
        },
      ])
    );
  };

  const getActiveCenterLineSection = () => {
    const vectors: THREE.Vector3[] = convertBufferGeometryTo3DVectorList(
      centerLine!.geometry
    );
    return [vectors[vectors.length - 2], vectors[vectors.length - 1]];
  };

  const onPointerDown = (event: PointerEvent) => {
    hideSelectionBoxArea();
    if (!isLeftClick(event)) return;

    if (!pointTargetOnMap(event, scene, mapUUID)) return;

    if (firstFloorSections.length > 1) {
      const { distance } = getCenterLineParamsForRightAngle(
        nextPoint.position,
        convertBufferGeometryTo3DVectorList(centerLine.geometry)
      );
      if (distance < buildingWidth / 2) {
        closeFirstFloor();
        return;
      }
    }
    addNextPoint(nextPoint.position);
  };

  const updateCenterLine = (point: THREE.Vector3, fixedDistance?: number) => {
    if (isBuildingFinished) return;
    // Updating View of Center line
    let nextCenterLinePointPosition = getXYZ(point);

    // When we move mouse on map, while not having any section started yet (setting up initial point)
    if (!centerLine) {
      setObjectPosition(nextPoint, nextCenterLinePointPosition);
      return;
    }

    const centerLinePosition = centerLine.geometry.getAttribute('position');

    // Center Line end will be different in 90' angle mode (when 1 section already being created,
    // and we're in process of creation next sections
    const centerLineVectors = convertBufferGeometryTo3DVectorList(
      centerLine.geometry
    );
    if (firstFloorSections.length > 0) {
      if (centerLineVectors.length > 2) {
        const { normal, distance } = getCenterLineParamsForRightAngle(
          point,
          centerLineVectors ||
            convertBufferGeometryTo3DVectorList(centerLine.geometry)
        );

        if (distance < buildingWidth / 2) return;

        nextCenterLinePointPosition = getXYZ(
          getTranslatedVector(
            centerLineVectors[centerLineVectors.length - 2],
            fixedDistance || distance,
            normal
          )
        );
      }
    }

    centerLinePosition.setXYZ(
      centerLine.geometry.drawRange.count - 1,
      ...nextCenterLinePointPosition
    );
    setObjectPosition(nextPoint, nextCenterLinePointPosition);

    centerLine.computeLineDistances();
    centerLinePosition.needsUpdate = true;

    if (!isDirectionalInputActive) {
      centerLine && updateDirectionalInputValueForDistance();
    }

    updateSections({
      closeSection: false,
      nextPoint: point,
      sections: firstFloorSections,
      width: buildingWidth,
      floor: floorShape,
      processingSection: processingNewSection,
      activeCenterLine: getActiveCenterLineSection(),
      generateFatCenterLine,
      updateContour,
      setProcessingNewSection,
      setFloorShape,
      previousCenterLinePoint:
        centerLineVectors.length < 3
          ? new THREE.Vector3()
          : centerLineVectors[centerLineVectors.length - 3],
    });
  };

  const generateFatCenterLine = () => {
    const linePoints = Array.from(
      centerLine.geometry.getAttribute('position').array
    ).slice(0, edges.length * 3);
    if (linePoints?.length <= 0) return;
    const fatCenterLine = createLine2(linePoints, C_FatLineContourMaterial);
    fatCenterLine.computeLineDistances();
    setFatCenterLine(fatCenterLine);
  };

  const updateContour = (floor: THREE.Mesh) => {
    if (!floor) return;

    const shapePoints = flatten(
      [...getExistingCoordinates(floor), getExistingCoordinates(floor)[0]].map(
        (point) => getXYZ(point)
      )
    );
    if (!contour) {
      const material = C_FatLineContourMaterial.clone();
      material.color = new THREE.Color('#808285');
      setContour(createLine2(shapePoints, material));
    } else {
      contour.geometry = new LineGeometry().setPositions(shapePoints);
      contour.computeLineDistances();
    }

    const linePoints = Array.from(
      centerLine.geometry.getAttribute('position').array
    ).slice((edges.length - 1) * 3, (edges.length + 1) * 3);

    if (linePoints?.length <= 0) return;

    if (!fatDashedCenterLine) {
      setFatDashedCenterLine(
        createLine2(linePoints, C_DashedFatLineContourMaterial)
      );
    } else {
      fatDashedCenterLine.geometry = new LineGeometry().setPositions(
        linePoints
      );
      fatDashedCenterLine.position.setY(
        fatDashedCenterLine.position.y + 0.000001
      );
      fatDashedCenterLine.computeLineDistances();
    }
  };

  const onPointerMove = (event: PointerEvent) => {
    const pointOnMap = pointTargetOnMap(event, scene, mapUUID);

    if (!pointOnMap) return;

    if (!isBuildingFinished) {
      setPointerPosition(pointOnMap);

      if (!isDirectionalInputActive) {
        updateCenterLine(pointOnMap);
      } else {
        if (processingEntity.type === DistanceInput.Distance) {
          const extendedVector = getExtendedVector(
            getActiveCenterLineSection()[0],
            pointOnMap!,
            convertMillimetersToMeters(processingEntity.value) * multiplyRate
          );
          updateCenterLine(
            extendedVector,
            convertMillimetersToMeters(processingEntity.value) * multiplyRate
          );
        }
      }
    }
  };

  const onInputSet = () => {
    switch (processingEntity.type) {
      case DistanceInput.Distance:
        addNextPoint(nextPoint.position);
        return;
      case DistanceInput.BuildingWidth:
        dispatch(
          setDirectionalInputValues([
            {
              type: DistanceInput.Distance,
              processing: true,
            },
          ])
        );
        return;
    }
  };

  const updateDistance = (width: string) => {
    let extendedVector;
    if (firstFloorSections.length <= 1) {
      extendedVector = getExtendedVector(
        getActiveCenterLineSection()[0],
        pointerPosition!,
        convertMillimetersToMeters(width) * multiplyRate
      );
    } else {
      const { normal } = getCenterLineParamsForRightAngle(
        pointerPosition!,
        convertBufferGeometryTo3DVectorList(centerLine.geometry)
      );

      extendedVector = getTranslatedVector(
        getActiveCenterLineSection()[0],
        convertMillimetersToMeters(width) * multiplyRate,
        normal
      );
    }
    updateCenterLine(extendedVector);
  };

  const onInputUpdate = (evt: CustomEvent) => {
    if (isDrawing) {
      switch (processingEntity.type) {
        case DistanceInput.Distance:
          updateDistance(evt.detail);
          return;
        case DistanceInput.BuildingWidth:
          updateBuildingWidth(evt.detail);
          return;
      }
    }
  };

  const resetDraw = () => {
    setIsDrawing(false);
    setPointerPosition(null);
    dispatch(resetExternalElementsState());
    setFirstFloorSections([]);
    setCenterLine(null!);
    setProcessingNewSection(false);
    edges.length = 0;
  };

  const closeFirstFloor = () => {
    closedFloor.geometry.setFromPoints([
      ...getExistingCoordinates(closedFloor),
      getExistingCoordinates(closedFloor)[0],
    ]);

    centerLine.geometry.setDrawRange(0, edges.length);
    publish(SET_BUILDING_CREATION_PARAMETERS, {
      floorShape: closedFloor,
      centerLine,
    });

    dispatch(
      setDirectionalInputValues([
        {
          type: NumberedInput.Floors,
          value: FLOORS_INITIAL_COUNT.toString(),
          processing: true,
          display: true,
        },
        {
          type: DistanceInput.FloorHeight,
          value: INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS.toString(),
          display: true,
        },
        {
          type: DistanceInput.Distance,
          display: false,
          min: null,
          max: null,
        },
        { type: DistanceInput.BuildingWidth, display: false },
      ])
    );
    setIsBuildingFinished(true);
  };

  const onKeydown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        if (isDrawing) {
          closeFirstFloor();
        }
        return;
      }
      case 'Escape': {
        resetDraw();
        return;
      }
    }
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.addEventListener('pointerdown', onPointerDown);
    !isBuildingFinished &&
      canvas.addEventListener('pointermove', onPointerMove);
    subscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    document.addEventListener('keydown', onKeydown);

    return () => {
      canvas.removeEventListener('pointerdown', onPointerDown);
      canvas.removeEventListener('pointermove', onPointerMove);
      unsubscribe(DIRECTIONAL_INPUT__SET, onInputSet);
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
      document.removeEventListener('keydown', onKeydown);
    };
  }, [
    edges,
    centerLine,
    isDrawing,
    firstFloorSections,
    edgeOnPointer,
    pointerPosition,
    isDirectionalInputActive,
    processingEntity,
    floorShape,
    isBuildingFinished,
    processingNewSection,
    buildingWidth,
    contour,

    closedFloor,
  ]);

  return (
    <>
      {pointerPosition && (
        <ScalableDot dotPosition={nextPoint.position.clone()} />
      )}
      {fatCenterLine && <primitive object={fatCenterLine} />}
      {fatDashedCenterLine && <primitive object={fatDashedCenterLine} />}
      {floorShape && <primitive object={floorShape} />}
      {contour && <primitive object={contour} />}
      {edges.length > 0 &&
        edges.map((edge, i) => <ScalableDot dotPosition={edge} key={i} />)}
    </>
  );
};

export default CenterLineDrawMode;
