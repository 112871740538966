import { booleanPointOnLine, lineString, point } from '@turf/turf';
import { compact, isEqual } from 'lodash';

import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import {
  FlatVector3,
  NodeType,
  UserBuildingBlock,
  UserBuildingStorey,
} from '@/models';
import { isPointsInOneLine } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  convertFlatVector3ToVectors,
  generateWalls,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useMemo } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { removeFromSelectedNodeArray } from '@/store/slices/canvasBuildingSlice';

export const useMergeWalls = (buildingGUID: string) => {
  const { updateBuildingBlocks } = useUpdateUserBuildingData();
  const { addNodesToSelectedNodes } = useSelectedNodes();
  const {
    findDataForWall,
    findDataForStorey,
    findDataForBlock,
    findDataForBuilding,
  } = useFindNodeData();
  const dispatch = useAppDispatch();

  const newGUIDs = useMemo((): string[] => {
    return [];
  }, []);

  const isWallsInOneLine = (
    wall: WallSearchResults,
    otherWall: WallSearchResults
  ) => {
    if (
      otherWall?.guid === wall?.guid ||
      otherWall?.points[0][1] !== wall?.points[0][1]
    )
      return false;

    const wallLine = lineString([
      [wall!.points[0][0], wall!.points[0][2]],
      [wall!.points[1][0], wall!.points[1][2]],
    ]);
    const otherWallPoint1 = point([
      otherWall!.points[0][0],
      otherWall!.points[0][2],
    ]);
    const otherWallPoint2 = point([
      otherWall!.points[1][0],
      otherWall!.points[1][2],
    ]);
    return (
      (booleanPointOnLine(otherWallPoint1, wallLine) &&
        isPointsInOneLine(
          [wall!.points[0][0], wall!.points[0][2]],
          [wall!.points[1][0], wall!.points[1][2]],
          [otherWall!.points[1][0], otherWall!.points[1][2]],
          true
        )) ||
      (booleanPointOnLine(otherWallPoint2, wallLine) &&
        isPointsInOneLine(
          [wall!.points[0][0], wall!.points[0][2]],
          [wall!.points[1][0], wall!.points[1][2]],
          [otherWall!.points[0][0], otherWall!.points[0][2]],
          true
        ))
    );
  };

  const validateMergeAvailabilityForWalls = (wallsGuids: string[]) => {
    const wallData = wallsGuids
      .map((guid) => findDataForWall(guid))
      .filter((wallData) => !getIsElementLockedForMerge(wallData!));
    return wallData.some((wall) => {
      return wallData.find((otherWall) => isWallsInOneLine(wall!, otherWall!));
    });
  };

  const validateMergeAvailabilityForStoreys = (storeysGuid: string[]) => {
    const storeysData = storeysGuid.map((guid) => findDataForStorey(guid));
    return storeysData.some(
      (storey) =>
        storey &&
        validateMergeAvailabilityForWalls(
          storey.childNodes
            .filter((node) => node.type === NodeType.Wall)
            .map((node) => node?.guid || '')
        )
    );
  };

  const validateMergeAvailability = (guids: string[], type: NodeType) => {
    switch (type) {
      case NodeType.Wall: {
        return validateMergeAvailabilityForWalls(guids);
      }
      case NodeType.Storey: {
        return validateMergeAvailabilityForStoreys(guids);
      }
      default:
        return false;
    }
  };

  const mergeWallsForStorey = (
    wallData: WallSearchResults[]
  ): UserBuildingStorey | null => {
    const storeyGuid = wallData[0]?.parentNodes.find(
      (node) => node?.type === NodeType.Storey
    )?.guid;
    if (!storeyGuid || !wallData) return null;

    const storeyData = findDataForStorey(storeyGuid);
    if (!storeyData) return null;

    const floorPoints = storeyData.floor.points;
    const newFloorPoints = [floorPoints[0]];
    let prevPoint = floorPoints[0];
    for (let i = 1; i < floorPoints.length; i++) {
      const currPoint = floorPoints[i];
      const nextPoint =
        floorPoints.length - 1 === i ? floorPoints[0] : floorPoints[i + 1];
      const isInLine = isPointsInOneLine(
        [prevPoint[0], prevPoint[2]],
        [currPoint[0], currPoint[2]],
        [nextPoint[0], nextPoint[2]]
      );
      const wallsFloorPoints = wallData
        .map((p) => [p?.points[0], p?.points[1]])
        .flat();
      const mergedPoints = wallsFloorPoints.filter((item, index) =>
        wallsFloorPoints.some(
          (elem, idx) => isEqual(elem, item) && idx !== index
        )
      );
      const isJoinedPoint = mergedPoints.some((point) =>
        isEqual(point, currPoint)
      );
      if (!isInLine || !isJoinedPoint) {
        newFloorPoints.push(currPoint);
      }
      prevPoint = currPoint;
    }
    const newCeilingPoints: FlatVector3[] = newFloorPoints.map((p) => [
      p[0],
      storeyData.ceiling.points[0][1],
      p[2],
    ]);
    const newWalls = generateWalls(
      convertFlatVector3ToVectors(newFloorPoints),
      convertFlatVector3ToVectors(newCeilingPoints),
      storeyData.walls,
      'Merged Wall'
    );

    newWalls.forEach((wall) => {
      const isExistBefore = storeyData.walls.find(
        (sWall) => sWall.guid === wall.guid
      );
      !isExistBefore && newGUIDs.push(wall.guid);
    });

    return {
      guid: storeyData.guid,
      userData: storeyData.userData,
      storeyNumber: storeyData.storeyNumber,
      name: storeyData.name,
      ceiling: {
        ...storeyData.ceiling,
        points: newCeilingPoints,
      },
      floor: {
        ...storeyData.floor,
        points: newFloorPoints,
      },
      walls: newWalls,
    };
  };

  const mergeWallsForBlock = (
    wallsData: WallSearchResults[]
  ): UserBuildingBlock | null => {
    const sortedByStorey = wallsData.reduce(
      (acc: { [point: number]: WallSearchResults[] }, curr) => {
        const groundPoint = curr?.points[0][1];

        if (!groundPoint) return acc;

        return {
          ...acc,
          [groundPoint]: acc[groundPoint]
            ? [...acc[groundPoint], curr]
            : [curr],
        };
      },
      {}
    );
    const blockGuid = wallsData[0].parentNodes.find(
      (node) => node.type === NodeType.Block
    )?.guid;
    const blockData = findDataForBlock(blockGuid || '');
    if (!blockData) return null;

    const newStoreys = Object.values(sortedByStorey).map((wallData) => {
      return mergeWallsForStorey(wallData);
    });
    return {
      guid: blockData.guid,
      name: blockData.name,
      userData: blockData.userData,
      storeys: blockData.storeys.map((storey) => {
        const updatedStorey = newStoreys.find(
          (newStorey) => newStorey?.guid === storey.guid
        );
        return updatedStorey || storey;
      }),
    };
  };

  const mergeWalls = (wallsGUIDsForMerge: string[], nodeType: NodeType) => {
    const wallData = wallsGUIDsForMerge
      .map((guid) => findDataForWall(guid))
      .filter((wallData) => !getIsElementLockedForMerge(wallData!))
      .reduce((acc: { [point: string]: WallSearchResults[] }, curr) => {
        const blockGuid = curr?.parentNodes.find(
          (node) => node.type === NodeType.Block
        )?.guid;

        if (!blockGuid) return acc;

        return {
          ...acc,
          [blockGuid]: acc[blockGuid] ? [...acc[blockGuid], curr] : [curr],
        };
      }, {});
    const newBlocks = Object.values(wallData).map((wallData) => {
      return mergeWallsForBlock(wallData);
    });

    const updatedBlocks = findDataForBuilding(buildingGUID)?.blocks.map(
      (block) => {
        const newBlock = newBlocks.find(
          (newBlock) => newBlock?.guid === block.guid
        );
        return newBlock ?? block;
      }
    );

    if (nodeType === NodeType.Wall) {
      const isMergedGuids = wallsGUIDsForMerge.map((guid) =>
        wallsGUIDsForMerge.some((wallGUID) =>
          isWallsInOneLine(findDataForWall(wallGUID)!, findDataForWall(guid)!)
        )
      );

      dispatch(
        removeFromSelectedNodeArray(
          wallsGUIDsForMerge.filter((_, i) => isMergedGuids[i])
        )
      );
      addNodesToSelectedNodes(
        newGUIDs.map((guid) => ({ guid, type: NodeType.Wall }))
      );
    }
    newGUIDs.length = 0;
    updateBuildingBlocks({
      buildingGUID,
      newBlocks: compact(updatedBlocks),
    });
  };

  const getIsElementLockedForMerge = (wallData: WallSearchResults) => {
    return (
      wallData.userData?.isLocked ||
      wallData.parentNodes.find((node) => node.userData?.isLocked) ||
      wallData.childNodes.find((node) => node.userData?.isLocked)!
    );
  };

  return { mergeWalls, validateMergeAvailability };
};
