import React from 'react';
import { useParams } from 'react-router';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useAuth0 } from '@auth0/auth0-react';
import { REPORT_SIDEBAR_WIDTH } from '@/models/report';
import logo from '@/images/logo-small.svg';

interface ReportSidebarProps {
  pageIndex: number;
  reportCreationDate: string;
  countOfPages: number;
  pageName?: string;
}

const ReportSidebar: React.FC<ReportSidebarProps> = ({
  pageIndex,
  reportCreationDate,
  countOfPages,
  pageName,
}) => {
  const { id } = useParams();

  const projectData = useFetchProjectQuery(id!).data!;
  const { user } = useAuth0();

  return (
    <div
      className={`border-l border-box border-0 border-solid border-light-gray-20 h-full flex-shrink-0 flex flex-col`}
      style={{
        width: `${REPORT_SIDEBAR_WIDTH}px`,
      }}
    >
      <div className="flex h-10 border-b border-0 border-solid border-light-gray-20 box-border">
        <div className="bg-light-green-100 w-10 flex justify-center items-center">
          <img src={logo} alt="logo" />
        </div>
        <div className="uppercase text-black text-sm flex items-center ml-4 leading-[14px]">
          web designer
        </div>
      </div>
      <div className="px-3 py-6 box-border flex flex-col justify-between flex-1">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <div className="text-light-green-100 text-[11px] font-medium leading-4">
              # {id}
            </div>
            <div className="text-dark-gray-100 font-medium text-base leading-6">
              {projectData.name}
            </div>
            <div className="text-black-40 text-[10px] leading-4">
              {projectData.address}
            </div>
          </div>
          {projectData.description && (
            <div className="text-dark-gray-80 font-light text-[11px] leading-4">
              {projectData.description}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-14 mt-auto">
          <div className="flex flex-col gap-1">
            <div className="text-dark-gray-100 text-[11px] leading-4">
              Created by
            </div>
            <div className="underline text-dark-gray-80 leading-4 text-[11px] font-light">
              {user?.email}
            </div>
            <div className="flex flex-col gap-1">
              <div className="text-dark-gray-100 text-[11px] leading-4">
                Creation date
              </div>
              <div className="text-dark-gray-80 text-[11px] leading-4 font-light">
                {reportCreationDate}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-0 border-solid border-light-gray-20 box-border h-10 flex justify-between py-2.5 px-3">
        <div className="text-dark-gray-100 uppercase text-xs leading-5">
          {pageName}
        </div>
        <div className="text-xs leading-5">
          <span className="text-dark-gray-100">{pageIndex}</span>
          &nbsp;
          <span className="text-dark-gray-40">/</span>
          &nbsp;
          <span className="text-dark-gray-40">{countOfPages}</span>
        </div>
      </div>
    </div>
  );
};

export default ReportSidebar;
