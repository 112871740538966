import React from 'react';
export const ResetCornerGrid = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81071 1.74174C3.99183 1.56062 3.99183 1.26696 3.81071 1.08584C3.62959 0.904719 3.33593 0.904719 3.15481 1.08584L1.08584 3.15481C0.904719 3.33593 0.904719 3.62959 1.08584 3.81071L3.15481 5.87968C3.33593 6.0608 3.62959 6.0608 3.81071 5.87968C3.99183 5.69855 3.99183 5.4049 3.81071 5.22377L2.53349 3.94655H8.03448C10.2922 3.94655 12.1224 5.77678 12.1224 8.03448C12.1224 10.2922 10.2922 12.1224 8.03448 12.1224C5.77678 12.1224 3.94655 10.2922 3.94655 8.03448C3.94655 7.77834 3.7389 7.57069 3.48276 7.57069C3.22661 7.57069 3.01897 7.77834 3.01897 8.03448C3.01897 10.8045 5.26449 13.05 8.03448 13.05C10.8045 13.05 13.05 10.8045 13.05 8.03448C13.05 5.26449 10.8045 3.01897 8.03448 3.01897H2.53349L3.81071 1.74174Z"
        fill="#414042"
        stroke="#414042"
        strokeWidth="0.1"
      />
    </svg>
  );
};
