import { BaseIconProps } from '@/models';
import React from 'react';

export const LockClosedIcon: React.FC<BaseIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6V5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5V6H10.5C11.3284 6 12 6.67157 12 7.5V11.5C12 12.3284 11.3284 13 10.5 13H5.5C4.67157 13 4 12.3284 4 11.5V7.5C4 6.67157 4.67157 6 5.5 6H6ZM7 5V6H9V5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5ZM5.5 7C5.22386 7 5 7.22386 5 7.5V11.5C5 11.7761 5.22386 12 5.5 12H10.5C10.7761 12 11 11.7761 11 11.5V7.5C11 7.22386 10.7761 7 10.5 7H5.5Z"
        fill="#414042"
      />
    </svg>
  );
};
