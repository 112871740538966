import convert from 'convert-units';
import Fraction from 'fraction.js';
import { number, round } from 'mathjs';

// TODO: Refactor from string to numbers
export const convertMetersToMillimeters = (val: string | number): string =>
  (Number(val) * 1000).toString();

export const convertMillimetersToMeters = (val: string | number): number =>
  Number(val) / 1000;

const convertInchesToFraction = (inchDecimal: number): string => {
  let inchFractional;
  try {
    return new Fraction(inchDecimal).mul(64).round().div(64).toFraction(true);
  } catch {
    inchFractional = inchDecimal.toFixed(0);
  }
  return inchFractional;
};

export const convertMillimetersToFtInch = <T>(
  val: T,
  useFractions: boolean = true
): string => {
  const ft = Number(convert(Number(val)).from('mm').to('ft'));
  if (useFractions) {
    let absFt = Math.abs(ft);
    let inchDecimal = convert(absFt % 1)
      .from('ft')
      .to('in');

    if (Math.abs(12 - inchDecimal) <= 1 / 64) {
      absFt = round(absFt, 0);
      inchDecimal = 0;
    }

    const inchFractional = convertInchesToFraction(Number(inchDecimal));

    const inchesPart = inchFractional !== '0' ? `-${inchFractional}"` : '';
    return `${Number(val) < 0 ? '-' : ''}${Math.floor(absFt)}'${inchesPart}`;
  }
  return ft.toString();
};

export const convertFtInchToMillimeters = (val: string): string => {
  const getInchValue = () => {
    try {
      const inches = val.includes("'") ? val.split("'")[1] : val;
      const split = inches.replace(/[‘’"”]/g, '').replace(/-/g, ' ');
      return new Fraction(split.trim());
    } catch {
      return 0;
    }
  };

  const isNegative = val[0] === '-';

  const value = isNegative ? val.slice(1) : val;

  const ft = Number(
    value.includes("'") ? value.split("'")[0].replace(/[‘’"”-]/g, '') : 0
  );
  const inch = number(getInchValue());

  const mm = (
    convert(ft).from('ft').to('mm') + convert(inch).from('in').to('mm')
  ).toString();

  return isNegative ? `-${mm}` : mm;
};

export const convertSquareMetersToSquareFeet = (val: number): string => {
  return convert(Number(val)).from('m2').to('ft2').toFixed(2);
};

export const convertSquareMillimetersToSquareMeters = (val: number): string => {
  return convert(val).from('mm2').to('m2').toFixed(2);
};
