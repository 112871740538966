import React, { useState } from 'react';
import { Form } from 'antd';
import { useParams } from 'react-router';
import { useAppDispatch } from '@/store/hooks';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { requiredValidator } from '@/shared/form/validators';
import { SavedCamera } from '@/models';
import EditableField from '@/shared/elements/EditableField';
import { setAppliedCamera } from '@/store/slices/canvasCamerasSlice';
import IntusMenu from '@/shared/elements/IntusMenu/IntusMenu';
import { IntusTooltip } from '@/shared/elements';

interface SavedCameraItemProps {
  camera: SavedCamera;
  deleteCamera: (id: number) => void;
  editCamera: (camera: SavedCamera, newCameraName: string) => void;
}

const SavedCameraItem: React.FC<SavedCameraItemProps> = ({
  camera,
  deleteCamera,
  editCamera,
}) => {
  const { id } = useParams();
  const savedCameras = useFetchProjectQuery(id!).data!.cameras;
  const [isEditing, setIsEditing] = useState(false);
  const [prevCameraName, setPrevCameraName] = useState(camera.name);
  const [form] = Form.useForm<{ name: string }>();

  const dispatch = useAppDispatch();

  const handleFinishEditing = () => {
    const newCameraName: string = form.getFieldValue('name').trim();

    form.setFieldsValue({ name: newCameraName });
    setPrevCameraName(newCameraName);
    editCamera(camera, newCameraName);
    setIsEditing(false);
  };

  const resetFieldChanges = () => {
    form.setFieldsValue({ name: prevCameraName });
    setIsEditing(false);
  };

  const handleDeleteCamera = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    deleteCamera(camera.id);
  };

  const selectCamera = () => {
    const selectedCamera = savedCameras?.find(
      (cameraItem) => cameraItem.id === camera.id
    );
    if (!selectedCamera) return;

    dispatch(setAppliedCamera(selectedCamera));
  };

  return (
    <IntusMenu.Item
      className={isEditing ? 'hover:!bg-white' : 'hover:!bg-light-gray-10'}
    >
      <IntusTooltip
        title={camera.name}
        placement="right"
        mouseEnterDelay={1.5}
        overlayStyle={{ marginLeft: '4px' }}
      >
        <Form
          form={form}
          initialValues={{ name: camera.name }}
          className="flex-1 my-1 mx-1"
          onFinish={handleFinishEditing}
        >
          <Form.Item
            name="name"
            noStyle
            rules={[requiredValidator('Name is required')]}
          >
            <EditableField
              value={camera.name}
              isEditing={isEditing}
              onClickOutside={resetFieldChanges}
              onEscape={resetFieldChanges}
              setEditMode={setIsEditing}
              size="small"
              textSize="xs"
              textClassNames="flex justify-between items-center min-h-[26px] group"
              className="!h-6 !leading-5 font-normal"
              hasEditIcon
              clickAction={selectCamera}
              deleteAction={handleDeleteCamera}
            />
          </Form.Item>
        </Form>
      </IntusTooltip>
    </IntusMenu.Item>
  );
};

export default SavedCameraItem;
