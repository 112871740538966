import { GENERATE_CAMERA_SNAPSHOTS } from '@/core/event-names';
import { subscribe, unsubscribe } from '@/core/events';
import { Background, Filter, Side } from '@/models/camera.model';
import { findObjectByGuid } from '@/shared/helpers/canvas';
import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import {
  Box3,
  Camera,
  Color,
  Object3D,
  OrthographicCamera,
  Vector3,
  WebGLRenderer,
} from 'three';

const CameraSnapshot = () => {
  const { scene, gl, size } = useThree();
  const aspect = window.innerWidth / window.innerHeight;
  const frustumSize = 10;
  const cameraOffset = 0.9;
  const LAYER = 12;

  const generateCameraSnapshots = ({
    filters,
    onSnapshot,
  }: {
    filters: Filter[];
    onSnapshot: (imageUrls: string[]) => void;
  }) => {
    if (filters.length) {
      const cameras = createCamerasByFilters(filters);
      const urls: string[] = [];

      cameras.forEach((camera, index) => {
        const renderTarget = findObjectByGuid(scene, filters[index].targetGUID);

        if (!renderTarget) return;

        const guides = filters[index].includeEnvironment || [];
        setLayerVisibility([...guides, filters[index].targetGUID], LAYER, true);

        const updatedCamera = updateCameraRelativeTarget(
          camera,
          renderTarget,
          filters[index].side
        );
        const imageUrl = captureImage(
          gl,
          updatedCamera,
          filters[index].background
        );

        if (imageUrl) {
          urls.push(imageUrl);
        }

        setLayerVisibility(
          [...guides, filters[index].targetGUID],
          LAYER,
          false
        );
      });

      onSnapshot(urls);
    }
  };

  useEffect(() => {
    subscribe(GENERATE_CAMERA_SNAPSHOTS, (evt) =>
      generateCameraSnapshots(evt.detail)
    );

    return () => {
      unsubscribe(GENERATE_CAMERA_SNAPSHOTS, (evt) =>
        generateCameraSnapshots(evt.detail)
      );
    };
  }, []);

  const setLayerVisibility = (
    guides: string[],
    layer: number,
    isEnabled: boolean
  ) => {
    guides.forEach((guid) => {
      const renderTarget = findObjectByGuid(scene, guid);
      if (renderTarget) {
        (renderTarget as Object3D).traverse((child) => {
          isEnabled ? child.layers.enable(layer) : child.layers.disable(layer);
        });
      }
    });
  };

  const updateCameraRelativeTarget = (
    camera: OrthographicCamera,
    target: Object3D,
    side: Side
  ) => {
    const box = new Box3().setFromObject(target);
    const center = box.getCenter(new Vector3());
    const size = box.getSize(new Vector3());

    const positionCamera = getPositionCamera(side, center, size);
    const maxDimension = Math.max(size.x, size.y, size.z);
    const zoomFactor = frustumSize / (maxDimension * aspect) / cameraOffset;

    camera.position.set(
      positionCamera.x,
      positionCamera.y + 0.1,
      positionCamera.z
    );

    camera.zoom = zoomFactor;
    camera.updateProjectionMatrix();
    camera.lookAt(center);

    return camera;
  };

  const getPositionCamera = (
    side: Side,
    center: Vector3,
    size: Vector3
  ): Vector3 => {
    switch (side) {
      case Side.FRONT:
        return new Vector3(
          center.x + size.x / 2,
          center.y + size.y / 2,
          center.z + size.z / 2
        );
      case Side.LEFT:
        return new Vector3(
          center.x - size.x / 2,
          center.y + size.y / 2,
          center.z + size.z / 2
        );
      case Side.BACK:
        return new Vector3(
          center.x - size.x / 2,
          center.y + size.y / 2,
          center.z - size.z / 2
        );
      case Side.RIGHT:
        return new Vector3(
          center.x + size.x / 2,
          center.y + size.y / 2,
          center.z - size.z / 2
        );
      default:
        return center;
    }
  };

  const createCamerasByFilters = (filters: Filter[]): OrthographicCamera[] => {
    return filters.map(() => {
      const camera = new OrthographicCamera(
        (-frustumSize * aspect) / 2,
        (frustumSize * aspect) / 2,
        frustumSize / 2,
        -frustumSize / 2,
        0.001,
        20000
      );

      camera.layers.set(LAYER);

      return camera;
    });
  };

  const captureImage = (
    renderer: WebGLRenderer,
    camera: Camera,
    background: Background | undefined
  ): string => {
    const originalClearColor = renderer.getClearColor(new Color());
    const originalAlpha = renderer.getClearAlpha();

    if (background) {
      renderer.setClearColor(background.color, background.alpha);
    }

    renderer.setSize(size.width, size.height);
    renderer.render(scene, camera);

    const dataURL = renderer.domElement.toDataURL('image/png');

    renderer.setClearColor(originalClearColor, originalAlpha);

    return dataURL;
  };

  return null;
};

export default CameraSnapshot;
