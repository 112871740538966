import React from 'react';

const PanelWidthIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.767L5.14706 13.0764C5.0559 13.0223 5 12.9242 5 12.8183L5 4.45513C5 4.22267 5.25296 4.07854 5.45294 4.19704L8 5.70641M8 14.767L14.8324 11.4067C14.935 11.3562 15 11.2518 15 11.1375V2.74554C15 2.52338 14.7669 2.37829 14.5676 2.47633L8 5.70641M8 14.767V5.70641"
        stroke="#414042"
        strokeLinejoin="round"
      />
      <path d="M8.00012 16.498V18.998" stroke="#414042" strokeLinecap="round" />
      <path d="M15 13V15.5" stroke="#414042" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4389 13.9404C15.5712 14.1828 15.4818 14.4865 15.2394 14.6187L8.2394 18.4369C7.99697 18.5691 7.69326 18.4798 7.56102 18.2374C7.42879 17.995 7.51812 17.6912 7.76055 17.559L14.7605 13.7408C15.003 13.6086 15.3067 13.6979 15.4389 13.9404Z"
        fill="#414042"
      />
    </svg>
  );
};

export default PanelWidthIcon;
