import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT } from '@/core/event-names';
import { makeScreenshotDelay } from '@/shared/helpers/camera';
import { sleep } from '@/shared/helpers/sleep';
import ZeroStateFrame from './frames/ZeroStateFrame';
import EnvironmentFrame from './frames/EnvironmentFrame';
import SurroundingBuildingFrame from './frames/SurroundingBuildingFrame';
import SurroundingBuildingsFrame from './frames/SurroundingBuildingsFrame';
import ConstructionSiteFrame from './frames/ConstructionSiteFrame';
import { NodeType, SelectedNode, UnitSystemTypes } from '@/models';
import { Position } from '@turf/turf';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useAppSelector } from '@/store/hooks';
import BuildingFrame from './frames/BuildingFrame';
import WallFrame from './frames/WallFrame';
import StoreyFrame from './frames/StoreyFrame';
import { formatAreaValue } from './propertyPanel-helpers';
import BlockFrame from './frames/BlockFrame';
import { getIsFacadeDesignerModeEnabled } from '@/store/slices/canvasModesSlice';
import PanelizationSettings from './frames/PanelizationSettings';
import {
  getSelectedPlacedWindows,
  getSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import WindowFrame from './frames/WindowFrame';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

const PropertyPanel = ({
  selectedNodes,
}: {
  selectedNodes: SelectedNode[];
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const isFacadeModeEnabled = useAppSelector(getIsFacadeDesignerModeEnabled);

  const { id } = useParams();
  const windowsInLibrary = useFetchWindowsQuery(id!).data!;
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const selectedPlacedWindowsData = selectedPlacedWindows.map(
    (window) => windowsInLibrary.find((w) => w.id === window.windowId)!
  );
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );

  const nodes = Object.values(selectedNodes);
  const areSelectedNodesTypesAreTheSame =
    nodes.length > 0 && nodes.every((node) => node.type === nodes[0].type);

  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperial = unitSystem === UnitSystemTypes.Imperial;

  const projectData = useFetchProjectQuery(id!).data!;

  const constructionSite = projectData.environment.constructionSite;

  const { calculateConstructionSiteArea, getUsedConstructionSiteArea } =
    useFrameProperties();

  const constructionSiteArea = calculateConstructionSiteArea(
    constructionSite?.points as Position[]
  );

  const selectedNode = Object.values(selectedNodes)[0];
  const selectedType = selectedNode?.type;
  const selectedNodesGUID = selectedNodes.map((node) => node.guid);

  const usedConstructionSiteArea = getUsedConstructionSiteArea(projectData);

  const getFrameForNodesOfTheSameType = useCallback(() => {
    switch (selectedType) {
      case NodeType.Environment:
        return (
          <EnvironmentFrame
            siteArea={formatAreaValue(constructionSiteArea, isImperial)}
            siteUse={{
              areaUsed: formatAreaValue(
                usedConstructionSiteArea.occupiedArea,
                isImperial
              ),
              usageRate: usedConstructionSiteArea.usageRate,
            }}
          />
        );
      case NodeType.SurroundingBuilding:
        return (
          <SurroundingBuildingFrame envBuildingsGUID={selectedNodesGUID} />
        );
      case NodeType.SurroundingBuildings:
        return <SurroundingBuildingsFrame />;
      case NodeType.ConstructionSite:
        return (
          <ConstructionSiteFrame
            area={formatAreaValue(constructionSiteArea, isImperial)}
            siteUse={{
              siteArea: formatAreaValue(
                usedConstructionSiteArea.occupiedArea,
                isImperial
              ),
              usageRate: usedConstructionSiteArea.usageRate,
            }}
          />
        );
      case NodeType.Building:
        return (
          <BuildingFrame
            buildingsGUID={selectedNodesGUID}
            isProjectLocked={projectData.locked}
          />
        );
      case NodeType.Block:
        return (
          <BlockFrame
            isProjectLocked={projectData.locked}
            blocksGUIDs={selectedNodesGUID}
          />
        );
      case NodeType.Storey:
        return (
          <StoreyFrame
            storeysGUID={selectedNodesGUID}
            isProjectLocked={projectData.locked}
          />
        );
      case NodeType.Wall:
        return <WallFrame wallsGUID={selectedNodesGUID} />;
      default:
        return <ZeroStateFrame />;
    }
  }, [selectedNodes, unitSystem, selectedType]);

  const getFrame = () => {
    let contentFrame: ReactElement;

    if (areSelectedNodesTypesAreTheSame && !isFacadeModeEnabled) {
      contentFrame = getFrameForNodesOfTheSameType();
    } else if (
      selectedWindowFromLibrary ||
      selectedPlacedWindowsData.length > 0
    ) {
      contentFrame = (
        <WindowFrame
          selectedWindows={
            selectedWindowFromLibrary
              ? [selectedWindowFromLibrary]
              : selectedPlacedWindowsData
          }
        />
      );
    } else if (isFacadeModeEnabled) {
      contentFrame = (
        <PanelizationSettings
          selectedWalls={selectedNodes
            .filter((node) => node.type === NodeType.Wall)
            .map((wall) => wall.guid)}
          areSettingsDisabled={
            !areSelectedNodesTypesAreTheSame ||
            selectedType !== NodeType.Wall ||
            projectData.locked
          }
        />
      );
    } else {
      contentFrame = <ZeroStateFrame />;
    }

    return contentFrame;
  };

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, toggleAnimation);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, toggleAnimation);
    };
  }, []);

  const toggleAnimation = async () => {
    setIsAnimating(true);
    await sleep(makeScreenshotDelay);
    setIsAnimating(false);
  };

  return (
    <div
      className={`absolute right-0 top-0 h-full z-20 flex flex-col overflow-x-hidden overflow-y-hidden max-h-full w-[220px] border bg-white border-light-gray-20 border-solid border-t-0 duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}
    >
      {getFrame()}
    </div>
  );
};

export default PropertyPanel;
