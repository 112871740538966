import React, { useEffect } from 'react';
import { IntusButton } from '@/shared/elements';
import { ReportErrorIcon } from '@/shared/icons/ReportErrorIcon';
import { ReportStatus } from '@/models/report';
import { useAppDispatch } from '@/store/hooks';
import { setStatusReport } from '@/store/slices/reportSlice';
import { useParams } from 'react-router';
import { useUpdateProjectMutation } from '@/store/apis/projectsApi';

const ErrorContent: React.FC = () => {
  const { id } = useParams();

  if (!id) return <></>;

  const [updateProject] = useUpdateProjectMutation();
  const dispatch = useAppDispatch();

  const toggleProjectStatus = async (isLocked: boolean) => {
    try {
      await updateProject({
        id: Number(id),
        locked: isLocked,
      }).unwrap();
    } catch (error) {
      console.error('Failed to lock project:', error);
    }
  };

  const handelGenerateReport = () => {
    toggleProjectStatus(true);
    dispatch(setStatusReport({ id, status: ReportStatus.PROCESSING }));
  };

  useEffect(() => {
    toggleProjectStatus(false);
  }, []);

  return (
    <>
      <ReportErrorIcon className="mb-10" />
      <IntusButton
        id="report__repeat-generation-button"
        className="rounded-lg py-[5px] px-[18px] mb-[92px]"
        onClick={handelGenerateReport}
      >
        Repeat generation
      </IntusButton>
    </>
  );
};

export default ErrorContent;
