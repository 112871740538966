import { BaseIconProps } from '@/models';
import React from 'react';

export const ReportErrorIcon: React.FC<BaseIconProps> = ({ className }) => {
  return (
    <svg
      width="154"
      height="112"
      viewBox="0 0 154 112"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.2589" cy="46.2286" r="2.96296" fill="#E8F5E5" />
      <circle cx="5.33333" cy="97.7786" r="5.33333" fill="#E8F5E5" />
      <circle cx="80.5931" cy="100.74" r="5.33333" fill="#E8F5E5" />
      <ellipse
        cx="121.481"
        cy="42.079"
        rx="3.55555"
        ry="3.55556"
        fill="#E8F5E5"
      />
      <circle cx="123.852" cy="27.8547" r="2.37037" fill="#E8F5E5" />
      <circle cx="41.4817" cy="109.636" r="2.37037" fill="#E8F5E5" />
      <circle cx="151.511" cy="72.3704" r="2.37037" fill="#E8F5E5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.96 28.3545C81.8293 39.7643 82.4554 31.8453 74.1873 31.8453H17.8118C17.4173 33.033 17.6647 32.2883 17.8118 31.8453C17.4873 30.7953 17.1373 31.1453 16.8845 28.3545C16.0152 16.9448 22.0136 7.69531 30.2817 7.69531H94.3573C86.0891 7.69531 80.0908 16.9448 80.96 28.3545Z"
        fill="#F0F2F5"
      />
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.96 28.3545C81.8293 39.7643 82.4554 31.8453 74.1873 31.8453H17.8118C16.8845 30.0953 16.9905 29.7453 16.8845 28.3545C16.0152 16.9448 22.0136 7.69531 30.2817 7.69531H94.3572C86.0891 7.69531 80.0907 16.9448 80.96 28.3545Z"
        fill="#F0F4F8"
      />
      <path
        d="M125.004 86.7944C119.673 86.7944 114.899 80.8313 114.339 73.477L110.902 28.3545C110.033 16.9448 102.626 7.69531 94.358 7.69531H30.2825C38.5506 7.69531 45.9575 16.9448 46.8268 28.3545L50.2636 73.477C50.824 80.8327 55.599 86.7944 60.9283 86.7944H125.004Z"
        fill="#D7DAE1"
      />
      <path
        opacity="0.9"
        d="M125.004 86.7944C119.673 86.7944 114.899 80.8313 114.339 73.477L110.902 28.3545C110.033 16.9448 102.626 7.69531 94.358 7.69531H30.2825C38.5506 7.69531 45.9575 16.9448 46.8268 28.3545L50.2636 73.477C50.824 80.8327 55.599 86.7944 60.9283 86.7944H125.004Z"
        fill="url(#paint0_linear_16307_55974)"
      />
      <path
        d="M124.997 86.8017C130.328 86.8017 134.194 80.8387 133.634 73.4844H69.5583C70.1187 80.84 66.2522 86.8017 60.9219 86.8017H124.997Z"
        fill="#DADDE4"
      />
      <path
        opacity="0.6"
        d="M124.997 86.8017C130.328 86.8017 134.194 80.8387 133.634 73.4844H69.5583C70.1187 80.84 66.2522 86.8017 60.9219 86.8017H124.997Z"
        fill="#F8FBFE"
      />
      <path
        d="M131.112 74.8938C132.729 72.0938 136.771 72.0938 138.387 74.8938L151.421 97.4688C153.037 100.269 151.017 103.769 147.783 103.769H121.716C118.483 103.769 116.462 100.269 118.079 97.4688L131.112 74.8938Z"
        fill="url(#paint1_linear_16307_55974)"
      />
      <path
        d="M133.179 97.469C133.179 97.2162 133.229 96.9659 133.325 96.7323C133.422 96.4988 133.564 96.2866 133.743 96.1078C133.921 95.9291 134.133 95.7873 134.367 95.6905C134.601 95.5938 134.851 95.544 135.104 95.544C135.357 95.544 135.607 95.5938 135.84 95.6905C136.074 95.7873 136.286 95.9291 136.465 96.1078C136.644 96.2866 136.785 96.4988 136.882 96.7323C136.979 96.9659 137.029 97.2162 137.029 97.469C137.029 97.9795 136.826 98.4692 136.465 98.8302C136.104 99.1912 135.614 99.394 135.104 99.394C134.593 99.394 134.104 99.1912 133.743 98.8302C133.382 98.4692 133.179 97.9795 133.179 97.469ZM133.367 85.9094C133.342 85.6665 133.367 85.421 133.443 85.1887C133.518 84.9564 133.642 84.7426 133.805 84.5611C133.968 84.3796 134.168 84.2344 134.391 84.1351C134.614 84.0357 134.856 83.9844 135.1 83.9844C135.344 83.9844 135.586 84.0357 135.809 84.1351C136.032 84.2344 136.231 84.3796 136.395 84.5611C136.558 84.7426 136.682 84.9564 136.757 85.1887C136.832 85.421 136.858 85.6665 136.832 85.9094L136.159 92.6603C136.131 92.9221 136.008 93.1644 135.812 93.3405C135.617 93.5167 135.363 93.6141 135.1 93.6141C134.837 93.6141 134.583 93.5167 134.387 93.3405C134.192 93.1644 134.068 92.9221 134.041 92.6603L133.367 85.9094Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16307_55974"
          x1="63.6871"
          y1="9.79531"
          x2="96.5871"
          y2="86.7953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.485452" stopColor="#F4F4F4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16307_55974"
          x1="134.75"
          y1="45.1437"
          x2="87.8498"
          y2="92.0438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9A770" />
          <stop offset="1" stopColor="#DB5F5E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
