import React from 'react';

const GridLineIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1V9.35418C14.714 9.03018 14.3764 8.75287 14 8.53513L13.938 8.5C13.3639 8.18142 12.7031 8 12 8C11.1292 8 10.3234 8.27825 9.66667 8.7507V8.5H6.33333V10H5.33333V8.5H2V14H5.33333V11H6.33333V14H8.53513C8.75287 14.3764 9.03018 14.714 9.35418 15H1V1H15ZM14 7.5V2L10.6667 2V7.5L14 7.5ZM6.33333 2L9.66667 2V7.5H6.33333V6H5.33333V7.5H2V2L5.33333 2V5H6.33333V2Z"
        fill={fill}
      />
      <path d="M9.66667 1V2H10.6667V1H9.66667Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33333 1H5.33333V2V5H6.33333V2V1ZM2 2V7.5V8.5V14L2 15L1 15V1H2V2ZM9.66667 7.5V2H10.6667V7.5V8.22761C10.3069 8.35476 9.97081 8.5319 9.66667 8.7507V8.5V7.5ZM5.33333 15V14V11H6.33333V14V15H5.33333ZM6.33333 7.5V6H5.33333V7.5V8.5V10H6.33333V8.5V7.5Z"
        fill={fill}
      />
      <path
        d="M14 8.53513C14.3764 8.75287 14.714 9.03018 15 9.35418V1H14V2V7.5V8.53513Z"
        fill={fill}
      />
      <path
        d="M15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12ZM12.3889 10.4444C12.3889 10.2297 12.2148 10.0556 12 10.0556C11.7852 10.0556 11.6111 10.2297 11.6111 10.4444V11.6111H10.4444C10.2297 11.6111 10.0556 11.7852 10.0556 12C10.0556 12.2148 10.2297 12.3889 10.4444 12.3889H11.6111V13.5556C11.6111 13.7703 11.7852 13.9444 12 13.9444C12.2148 13.9444 12.3889 13.7703 12.3889 13.5556V12.3889H13.5556C13.7703 12.3889 13.9444 12.2148 13.9444 12C13.9444 11.7852 13.7703 11.6111 13.5556 11.6111H12.3889V10.4444Z"
        fill={fill}
      />
    </svg>
  );
};

export default GridLineIcon;
