import { Scene, Mesh, Group } from 'three';

export const findObjectByGuid = (scene: Scene, value: string) => {
  let foundObject = null;
  scene.traverse((object) => {
    if (object.userData.guid === value) {
      foundObject = object;
    }
  });
  return foundObject;
};

export const scaleMesh = (mesh: Mesh | Group, scaleValue: number) =>
  mesh.scale.set(scaleValue, scaleValue, scaleValue);
