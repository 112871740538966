import {
  CanvasActionsModes,
  EditModes,
  NodeType,
  SelectedNodeSource,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getExtrudeNode,
  getIsNodeHovered,
  resetHoveredNode as resetHoveredNodesReducer,
  setHoveredNode as setHoveredNodeReducer,
} from '@/store/slices/canvasBuildingSlice';
import { ThreeEvent } from '@react-three/fiber';
import { getCanvasMode, getEditMode } from '@/store/slices/canvasModesSlice';
import { getDraggedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';

interface UseHoveredNodeProps {
  nodeGUID: string;
  isLocked?: boolean;
  source?: SelectedNodeSource;
  nodeType?: NodeType;
}

export const useHoveredNode = ({
  nodeGUID,
  isLocked,
  source,
  nodeType,
}: UseHoveredNodeProps) => {
  const dispatch = useAppDispatch();
  const canvasMode = useAppSelector(getCanvasMode);
  const isSelectionMode = canvasMode === CanvasActionsModes.selection;
  const draggedWindowFromLibrary = useAppSelector(getDraggedWindowFromLibrary);
  const isWindowCardDragging = !!draggedWindowFromLibrary;
  const shouldPreventNodeHover =
    nodeType !== NodeType.Wall && isWindowCardDragging;

  const isExtrudeMode = !!useAppSelector(getExtrudeNode);
  const isEditMode = useAppSelector(getEditMode) !== EditModes.Unset;
  const isNodeHovered = useAppSelector(getIsNodeHovered(nodeGUID));
  const isLockedToHover = source
    ? isLocked && source === SelectedNodeSource.Viewer
    : isLocked;

  const setHoveredNode = (event?: ThreeEvent<PointerEvent>) => {
    event?.stopPropagation();
    if (isLockedToHover || shouldPreventNodeHover) return;

    isSelectionMode &&
      nodeGUID &&
      !isExtrudeMode &&
      dispatch(setHoveredNodeReducer(nodeGUID));
  };

  const resetHoveredNode = (event?: ThreeEvent<PointerEvent>) => {
    event?.stopPropagation();
    !isEditMode && dispatch(resetHoveredNodesReducer());
  };

  return {
    setHoveredNode,
    resetHoveredNode,
    isNodeHovered,
  };
};
