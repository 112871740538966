import React from 'react';
import BuildingRenderItem from './RenderItem.tsx/RenderItem';

export interface RenderData {
  imageUrl: string;
  renderDateTime: string;
}

interface BuildingRendersProps {
  renders?: RenderData[];
}

const BuildingRenders: React.FC<BuildingRendersProps> = ({ renders }) => {
  return (
    <div className="p-6 grid grid-cols-2 gap-3 w-full bg-[#ECEEEE]">
      {renders?.map((render) => {
        return (
          <BuildingRenderItem key={render.renderDateTime} renderData={render} />
        );
      })}
    </div>
  );
};

export default BuildingRenders;
