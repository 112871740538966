import React, { useMemo } from 'react';
import { MeasurementElementType } from '@/components/WindowCreator/models';
import { Measurement } from '@/shared/components/MeasurementLine';
import LineLabelIcon from '@/images/line-label.svg';
import { FlatVector2 } from '@/models';
import useImage from 'use-image';
import { Image, Line } from 'react-konva';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { useParams } from 'react-router';
import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { useAppSelector } from '@/store/hooks';
import { round } from 'lodash';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import { convert2DPointsToDistanceInMeters } from '@/shared/helpers/metrics';
import { GRID_INFORMATION_PADDING } from '../constants';

interface GridLinesInformationMeasurementsProps {
  wallsTotalHeight: number;
  wallsTotalWidth: number;
  wallsData: WallSearchResults[];
  scale: number;
  startPoint: {
    x: number;
    z: number;
  };
}

const GridLinesInformationMeasurements = ({
  wallsData,
  wallsTotalHeight,
  wallsTotalWidth,
  startPoint,
  scale,
}: GridLinesInformationMeasurementsProps) => {
  const MEASUREMENT_TOTAL_HEIGHT = wallsTotalHeight + GRID_INFORMATION_PADDING;
  const [lineLabelIcon] = useImage(LineLabelIcon);
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const multiplyRate = useAppSelector(getMultiplyRate(id!));

  const gridLinePoints: number[] = useMemo(() => {
    const points = wallsData.flatMap((wall) => {
      const wallBottomLeftPoint: FlatVector2 = [
        wall.points[1][0],
        wall.points[1][2],
      ];

      const wallOffset = round(
        Number(
          convertMetersToMillimeters(
            convert2DPointsToDistanceInMeters(
              wallBottomLeftPoint,
              [startPoint.x, startPoint.z],
              multiplyRate
            )
          )
        ),
        2
      );

      return wall.gridLines.map((line) => wallOffset + line.offsetFromLeftEdge);
    });

    return Array.from(
      new Set([0, ...points.sort((a, b) => a - b), wallsTotalWidth])
    );
  }, [wallsData]);

  return (
    <>
      <Image
        image={lineLabelIcon}
        width={29}
        height={24}
        y={MEASUREMENT_TOTAL_HEIGHT - 12 * (1 / scale)}
        scaleX={1 / scale}
        scaleY={1 / scale}
        x={-57 * (1 / scale)}
      />

      {gridLinePoints.map((point, idx) => (
        <React.Fragment key={`measurement_group_${idx}`}>
          {gridLinePoints.length - 1 !== idx && (
            <Measurement
              key={`measurement_windows_${idx}`}
              points={[
                [point, MEASUREMENT_TOTAL_HEIGHT],
                [gridLinePoints[idx + 1], MEASUREMENT_TOTAL_HEIGHT],
              ]}
              scale={scale}
              units={unitSystem}
              level={0}
              disabled={true}
              type={MeasurementElementType.Offset}
            />
          )}

          <Line
            key={`measurement_line-${idx}`}
            points={[point, wallsTotalHeight, point, MEASUREMENT_TOTAL_HEIGHT]}
            stroke="#B3B2B4"
            strokeWidth={1}
            strokeScaleEnabled={false}
            dash={[12, 3, 4, 3]}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default GridLinesInformationMeasurements;
