import React from 'react';

const AutoGridPlacementIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8.38947V1H1V15H6.87494C6.75003 14.6801 6.65419 14.3457 6.59069 14H6.33333V11H5.33333V14H2V8.5H5.33333V10H6.33333V8.5H8.83692C9.7309 7.87046 10.8209 7.50057 11.9973 7.5H10.6667V2L14 2V7.5L12.0027 7.5C13.1085 7.50054 14.1379 7.82738 15 8.38947ZM9.66667 2L6.33333 2V5H5.33333V2L2 2V7.5H5.33333V6H6.33333V7.5H9.66667V2Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33333 1H5.33333V2V6V7.5V8.5V11V14V15H6.33333V14V11V8.5V7.5V6V2V1ZM2 2V7.5V8.5V14L2 15L1 15V1H2V2ZM9.66667 7.5V2V1H10.6667V2V7.5V7.66272C10.3191 7.74928 9.98457 7.86889 9.66667 8.01804V7.5ZM15 8.38947V1H14V2V7.5V7.87494C14.3525 8.01259 14.6872 8.18555 15 8.38947Z"
        fill={fill}
      />
      <path
        d="M12.0974 14.0971C12.0415 14.1532 11.9893 14.2126 11.9411 14.2749C11.8099 14.4444 11.7081 14.6355 11.6407 14.8402L11.3422 15.7581C11.3172 15.8288 11.2709 15.8901 11.2096 15.9334C11.1483 15.9767 11.0751 16 11 16C10.9249 16 10.8517 15.9767 10.7904 15.9334C10.7358 15.8947 10.693 15.8418 10.6667 15.7806C10.6648 15.7763 10.6631 15.772 10.6614 15.7676C10.6602 15.7645 10.659 15.7613 10.6578 15.7581L10.3593 14.8402C10.2665 14.5612 10.1098 14.3077 9.90173 14.0997C9.69365 13.8918 9.4399 13.7352 9.16064 13.6425L8.24211 13.3442C8.17132 13.3192 8.11002 13.2729 8.06665 13.2117C8.02329 13.1505 8 13.0773 8 13.0023C8 12.9273 8.02329 12.8541 8.06665 12.7929C8.11002 12.7316 8.17132 12.6854 8.24211 12.6604L8.26049 12.6558L9.17901 12.3575C9.45827 12.2648 9.71202 12.1082 9.9201 11.9003C10.1282 11.6924 10.2849 11.4388 10.3777 11.1598L10.6762 10.2419C10.7012 10.1712 10.7475 10.1099 10.8088 10.0666C10.8701 10.0233 10.9433 10 11.0184 10C11.0934 10 11.1667 10.0233 11.2279 10.0666C11.2892 10.1099 11.3355 10.1712 11.3605 10.2419L11.659 11.1598L11.6667 11.1825C11.7585 11.4506 11.9095 11.6948 12.1087 11.8969C12.3134 12.1047 12.5635 12.2623 12.8394 12.3575L13.7579 12.6558C13.8287 12.6808 13.89 12.7271 13.9333 12.7883C13.9767 12.8495 14 12.9227 14 12.9977C14 13.0727 13.9767 13.1459 13.9333 13.2071C13.89 13.2684 13.8287 13.3146 13.7579 13.3396L12.8394 13.6379C12.5596 13.7311 12.3055 13.8884 12.0974 14.0971Z"
        fill={fill}
      />
      <path
        d="M12.1302 9.82791L12.5895 9.67876C12.7291 9.63238 12.856 9.5541 12.9601 9.45014C13.0641 9.34617 13.1424 9.2194 13.1888 9.07988L13.3381 8.62096C13.3506 8.58559 13.3738 8.55496 13.4044 8.5333C13.435 8.51163 13.4717 8.5 13.5092 8.5C13.5467 8.5 13.5833 8.51163 13.614 8.5333C13.6446 8.55496 13.6678 8.58559 13.6803 8.62096L13.8295 9.07988C13.875 9.21842 13.952 9.34456 14.0543 9.44846C14.1567 9.55235 14.2818 9.63117 14.4197 9.67876L14.8789 9.82791C14.9143 9.84039 14.945 9.86353 14.9667 9.89415C14.9884 9.92476 15 9.96135 15 9.99885C15 10.0364 14.9884 10.0729 14.9667 10.1036C14.945 10.1342 14.9143 10.1573 14.8789 10.1698L14.4197 10.3189C14.2798 10.3656 14.1528 10.4442 14.0487 10.5486C13.9447 10.6529 13.8664 10.7802 13.8203 10.9201L13.6711 11.379C13.6586 11.4144 13.6354 11.445 13.6048 11.4667C13.5741 11.4884 13.5375 11.5 13.5 11.5C13.4625 11.5 13.4259 11.4884 13.3952 11.4667C13.3646 11.445 13.3414 11.4144 13.3289 11.379L13.1797 10.9201C13.1332 10.7806 13.0549 10.6538 12.9509 10.5499C12.8468 10.4459 12.7199 10.3676 12.5803 10.3212L12.1211 10.1721C12.0857 10.1596 12.055 10.1365 12.0333 10.1059C12.0116 10.0752 12 10.0387 12 10.0011C12 9.96364 12.0116 9.92706 12.0333 9.89644C12.055 9.86582 12.0857 9.84268 12.1211 9.8302L12.1302 9.82791Z"
        fill={fill}
      />
    </svg>
  );
};

export default AutoGridPlacementIcon;
