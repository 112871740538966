import { BaseIconProps } from '@/models';
import React from 'react';

export const ReportReadyIcon: React.FC<BaseIconProps> = ({ className }) => {
  return (
    <svg
      width="154"
      height="112"
      viewBox="0 0 154 112"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16340_186254)">
        <circle cx="11.2598" cy="46.2286" r="2.96296" fill="#E8F5E5" />
        <circle cx="5.33431" cy="97.7786" r="5.33333" fill="#E8F5E5" />
        <circle cx="80.5941" cy="100.74" r="5.33333" fill="#E8F5E5" />
        <ellipse
          cx="121.482"
          cy="42.079"
          rx="3.55556"
          ry="3.55556"
          fill="#E8F5E5"
        />
        <circle cx="123.853" cy="27.8547" r="2.37037" fill="#E8F5E5" />
        <circle cx="41.4827" cy="109.636" r="2.37037" fill="#E8F5E5" />
        <circle cx="151.512" cy="72.3704" r="2.37037" fill="#E8F5E5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.4737 39.5655C78.343 50.9752 78.9691 43.0562 70.701 43.0562H14.3254C13.931 44.2439 14.1783 43.4993 14.3254 43.0562C14.001 42.0063 13.651 42.3562 13.3982 39.5655C12.5289 28.1557 18.5273 18.9062 26.7954 18.9062H90.8709C82.6028 18.9062 76.6044 28.1557 77.4737 39.5655Z"
          fill="#F0F2F5"
        />
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.4737 39.5655C78.343 50.9752 78.9691 43.0562 70.701 43.0562H14.3255C13.3982 41.3062 13.5041 40.9562 13.3982 39.5655C12.5289 28.1557 18.5273 18.9062 26.7954 18.9062H90.8709C82.6028 18.9062 76.6044 28.1557 77.4737 39.5655Z"
          fill="#F0F4F8"
        />
        <path
          d="M121.517 98.0053C116.187 98.0053 111.412 92.0423 110.853 84.688L107.416 39.5655C106.546 28.1557 99.1395 18.9062 90.8714 18.9062H26.7959C35.064 18.9062 42.4709 28.1557 43.3402 39.5655L46.7771 84.688C47.3375 92.0436 52.1124 98.0053 57.4417 98.0053H121.517Z"
          fill="#D7DAE1"
        />
        <path
          opacity="0.9"
          d="M121.517 98.0053C116.187 98.0053 111.412 92.0423 110.853 84.688L107.416 39.5655C106.546 28.1557 99.1395 18.9062 90.8714 18.9062H26.7959C35.064 18.9062 42.4709 28.1557 43.3402 39.5655L46.7771 84.688C47.3375 92.0436 52.1124 98.0053 57.4417 98.0053H121.517Z"
          fill="url(#paint0_linear_16340_186254)"
        />
        <path
          d="M121.511 98.0049C126.841 98.0049 130.708 92.0418 130.147 84.6875H66.0719C66.6323 92.0432 62.7659 98.0049 57.4355 98.0049H121.511Z"
          fill="#DADDE4"
        />
        <path
          opacity="0.6"
          d="M121.511 98.0049C126.841 98.0049 130.708 92.0418 130.147 84.6875H66.0719C66.6323 92.0432 62.7659 98.0049 57.4355 98.0049H121.511Z"
          fill="#F8FBFE"
        />
        <circle
          cx="135.101"
          cy="94.5062"
          r="16.1"
          fill="url(#paint1_linear_16340_186254)"
        />
        <path
          d="M142.403 88.0637C143.024 87.3598 144.092 87.3038 144.783 87.939C145.467 88.5673 145.523 89.6359 144.909 90.3322L134.659 101.957C134.358 102.299 133.939 102.498 133.495 102.522C133.051 102.545 132.614 102.39 132.279 102.082L126.277 96.5636C125.594 95.9352 125.538 94.8667 126.152 94.1704C126.772 93.4665 127.841 93.4104 128.532 94.0456L133.28 98.4112L142.403 88.0637Z"
          fill="white"
        />
        <mask
          id="mask0_16340_186254"
          maskUnits="userSpaceOnUse"
          x="125"
          y="87"
          width="21"
          height="16"
        >
          <path
            d="M142.403 88.0637C143.024 87.3598 144.092 87.3038 144.783 87.939C145.467 88.5673 145.523 89.6359 144.909 90.3322L134.659 101.957C134.358 102.299 133.939 102.498 133.495 102.522C133.051 102.545 132.614 102.39 132.279 102.082L126.277 96.5636C125.594 95.9352 125.538 94.8667 126.152 94.1704C126.772 93.4665 127.841 93.4104 128.532 94.0456L133.28 98.4112L142.403 88.0637Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_16340_186254)">
          <path
            opacity="0.8"
            d="M133.357 98.3115L129.375 102.569L125.019 99.6829L131.233 93.4394L133.357 98.3115Z"
            fill="url(#paint2_linear_16340_186254)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_16340_186254"
          x1="60.2006"
          y1="21.0063"
          x2="93.1006"
          y2="98.0062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.485452" stopColor="#F4F4F4" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16340_186254"
          x1="124.251"
          y1="105.706"
          x2="145.951"
          y2="82.6062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#529F43" />
          <stop offset="1" stopColor="#64BC50" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_16340_186254"
          x1="127.013"
          y1="98.1043"
          x2="131.04"
          y2="100.945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#67D666" stopOpacity="0.01" />
          <stop offset="1" stopColor="#67D666" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_16340_186254">
          <rect width="154" height="112" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
