import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MullionData } from '@/models/window-configurator.model';
import { Group, Line } from 'react-konva';
import { FlatVector2 } from '@/models';
import {
  generateClosedContourPointsFromPointArray,
  generateMullionWithOffset,
} from '@/components/WindowCreator/elements/creator-windows.helpers';
import { WindowCreatorModes } from '@/models/shared.model';
import { KonvaEventObject } from 'konva/lib/Node';
import {
  clearSelectRelatedData,
  getIsWindowNodeSelected,
  getWindowCreatorMode,
  resetHoverArea,
  setHoverArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { WindowElementType } from '@/components/WindowCreator/models/konva-model';
import Konva from 'konva';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getHexByName } from '../helpers/config';
import { useWindowCreatorSelect } from '@/components/WindowCreator/elements/shared/useWindowCreatorSelect';
interface MullionProps {
  framePoints: FlatVector2[];
  mullionData: MullionData;
  color: string;
  onDeleteMullionSubmit?: (data: MullionData) => void;
}
export const Mullion = ({
  mullionData,
  framePoints,
  color,
  onDeleteMullionSubmit,
}: MullionProps) => {
  const configColors = useFetchWindowConfigQuery().data!.colors;
  const mullionRef = useRef<Konva.Line>(null);
  const dispatch = useAppDispatch();

  const mode = useAppSelector(getWindowCreatorMode);
  const isSelectionMode = mode === WindowCreatorModes.Selection;

  const axis = mullionData.points;

  const [mullionPointsToDraw, setMullionPointsToDraw] = useState<FlatVector2[]>(
    []
  );

  const isSelected = useAppSelector(
    getIsWindowNodeSelected(mullionRef.current?._id)
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!onDeleteMullionSubmit) return;
    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'Backspace' || event.key === 'Delete') {
      onDeleteMullionSubmit(mullionData);
      dispatch(clearSelectRelatedData());
    }
  };

  useEffect(() => {
    isSelected && document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSelected]);

  const mullionBorderPoints = useMemo(
    () => generateClosedContourPointsFromPointArray(mullionPointsToDraw),
    [mullionPointsToDraw]
  );

  const { selectElementInWindowCreator } = useWindowCreatorSelect(
    mullionRef?.current?._id ?? 0,
    mullionPointsToDraw
  );

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    selectElementInWindowCreator(
      WindowElementType.Mullion,
      mullionBorderPoints,
      event
    );
  };

  useEffect(() => {
    const mullionPoints = generateMullionWithOffset(axis, framePoints);
    setMullionPointsToDraw(mullionPoints);
  }, [mullionData]);

  const handlePointerEnter = () => {
    isSelectionMode && dispatch(setHoverArea(mullionBorderPoints));
  };

  return (
    <Group>
      <Line
        ref={mullionRef}
        onClick={handleClick}
        onMouseOver={handlePointerEnter}
        onMouseLeave={() => isSelectionMode && dispatch(resetHoverArea())}
        points={mullionPointsToDraw.flat()}
        closed
        fill={getHexByName(configColors, color)}
        stroke="#8D8B8F"
      ></Line>
    </Group>
  );
};
