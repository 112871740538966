import React, { useEffect, useMemo, useState } from 'react';
import { GridLineData, UnitSystemTypes } from '@/models';
import { Group, Line } from 'react-konva';
import {
  GridLineConfig,
  GridLineSettings,
} from '@/components/FacadeDesigner/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getFacadeDesignerMode,
  getHoveredGridLine,
  getMeasurementActiveWall,
  getSelectedGridlines,
  getSelectedPlacedWindows,
  isGridLineSelected,
  setHoveredGridLine,
  setMeasurementActiveWall,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import { useFacadeDesignerSelection } from '@/components/FacadeDesigner/hooks/useFacadeDesignerSelection';
import { KonvaEventObject } from 'konva/lib/Node';
import { round } from 'mathjs';
import MultiMeasurementLine from '@/shared/components/MultiMeasurementLine/MultiMeasurementLine';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models';
import { useCustomElementPlacement } from '@/components/FacadeDesigner/hooks';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { uniqBy } from 'lodash';
import { isLeftClick } from '@/shared/helpers';
import { useParams } from 'react-router';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';

interface FacadeDesignerGridLineProps {
  gridLine: GridLineData;
  scale: number;
  unitSystem: UnitSystemTypes;
  wallHeight: number;
  gridPlacement?: boolean;
  isHidden?: boolean;
  wallOffset?: number;
  wallGUID?: string;
  wallData?: WallSearchResults;

  onMeasurementChange?: (offsetChange: number) => void;
  onMeasurementEscape?: () => void;
  onMeasurementSubmit?: () => void;
}

const FacadeDesignerGridLine = ({
  gridLine,
  scale,
  wallHeight,
  unitSystem,
  gridPlacement,
  isHidden,
  wallOffset,
  wallData,

  onMeasurementChange,
  onMeasurementEscape,
  onMeasurementSubmit,
}: FacadeDesignerGridLineProps) => {
  const absoluteOffset = round(
    (wallOffset ?? 0) + gridLine.offsetFromLeftEdge,
    2
  );

  const [measurementPoints, setMeasurementPoints] = useState<FlatVector2Axis[]>(
    []
  );
  const { id } = useParams();
  const projectData = useFetchProjectQuery(id!).data!;
  const hoveredGridLine = useAppSelector(getHoveredGridLine);
  const activeWall = useAppSelector(getMeasurementActiveWall);
  const dispatch = useAppDispatch();
  const selectedGridLines = useAppSelector(getSelectedGridlines);
  const cachedData = selectedGridLines.find((l) => l.guid === gridLine.guid);
  const selectedWindows = useAppSelector(getSelectedPlacedWindows);
  const isSelected = useAppSelector(isGridLineSelected(gridLine.guid));

  const [localMeasurementActive, setLocalMeasurementActive] = useState(false);

  const isSelectionMode =
    useAppSelector(getFacadeDesignerMode) === FacadeDesignerModes.Selection;

  const { handleSelectGridLine } = useFacadeDesignerSelection();

  const isAvailableToSelect = !gridLine.cornerAlign && isSelectionMode;

  const isHoveredOnLine = useMemo(() => {
    if (isNaN(Number(wallOffset)) || !hoveredGridLine) return false;

    return hoveredGridLine.absoluteOffset === absoluteOffset;
  }, [hoveredGridLine, wallOffset, absoluteOffset]);

  const actualConfig = useMemo((): GridLineSettings => {
    if (gridPlacement) {
      if (isHidden) {
        return GridLineConfig.placingEmpty;
      } else return GridLineConfig.placing;
    }
    if (isSelected) {
      return GridLineConfig.placedSelected;
    }
    if (isHoveredOnLine) {
      return GridLineConfig.placedHover;
    }
    return GridLineConfig.placed;
  }, [isHidden, isSelected, isHoveredOnLine]);

  const isSomeSelectedOnLine = useMemo(() => {
    if (isNaN(Number(wallOffset))) return false;

    return selectedGridLines.some(
      (line) => line.absoluteOffset === absoluteOffset
    );
  }, [gridLine, wallOffset, absoluteOffset, selectedGridLines]);

  useEffect(() => {
    if (!isAvailableToSelect || isSelected) return;
    isSomeSelectedOnLine && selectGridLine(true);
  }, [selectedGridLines, isSelected, isAvailableToSelect, absoluteOffset]);

  const selectGridLine = (multiSelect: boolean) => {
    if (!isAvailableToSelect) return;

    handleSelectGridLine({
      data: { ...gridLine, absoluteOffset, wallGUID: wallData!.guid },
      multiSelect,
    });
  };

  const handleHover = (isHovered: boolean) => {
    isHovered
      ? dispatch(
          setHoveredGridLine({
            ...gridLine,
            absoluteOffset,
            wallGUID: wallData!.guid,
          })
        )
      : dispatch(setHoveredGridLine(null));
  };

  const handleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    if (!isAvailableToSelect) return;
    const stage = e.target.getStage();
    stage!.container().style.cursor = 'pointer';
    handleHover(true);
  };

  const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    if (!isAvailableToSelect) return;
    const stage = e.target.getStage();
    stage!.container().style.cursor = 'default';
    handleHover(false);
  };
  const handleGridMovePlacement = wallData
    ? useCustomElementPlacement({
        wallData,
        yPosition: wallHeight / 2,
      }).handleGridMovePlacement
    : undefined;

  const handlePointerDown = (event: KonvaEventObject<MouseEvent>) => {
    if (!isLeftClick(event.evt)) return;
    wallData && dispatch(setMeasurementActiveWall(wallData.guid));
    selectGridLine(event.evt.shiftKey);
    handleGridMovePlacement &&
      setMeasurementPoints(
        handleGridMovePlacement(gridLine.offsetFromLeftEdge)
      );
  };

  useEffect(() => {
    if (measurementPoints?.length > 0) {
      if (!(uniqBy(selectedGridLines, 'absoluteOffset').length === 1)) {
        setMeasurementPoints([]);
      }
      if (
        absoluteOffset !== selectedGridLines[0]?.absoluteOffset &&
        !localMeasurementActive
      ) {
        setMeasurementPoints([]);
      }
    }
  }, [
    selectedGridLines,
    absoluteOffset,
    gridLine.offsetFromLeftEdge,
    measurementPoints,
    selectedWindows,
    localMeasurementActive,
  ]);

  const handleStatusChange = (isActive: boolean) => {
    if (!wallData?.guid) return;
    setLocalMeasurementActive(isActive);
    dispatch(setMeasurementActiveWall(isActive ? wallData?.guid : null));
  };

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    setMeasurementPoints(points);

    cachedData &&
      onMeasurementChange &&
      onMeasurementChange(
        round(
          (wallOffset ?? 0) + points[1][0][0] - cachedData.absoluteOffset,
          2
        )
      );
  };

  const handleMeasurementEscape = () => {
    setMeasurementPoints([]);
    dispatch(setMeasurementActiveWall(null));
    onMeasurementEscape && onMeasurementEscape();
  };

  const handleMeasurementSubmit = () => {
    setMeasurementPoints([]);
    dispatch(setMeasurementActiveWall(null));
    onMeasurementSubmit && onMeasurementSubmit();
  };

  return (
    <Group>
      <Line
        points={[
          gridLine.offsetFromLeftEdge,
          0,
          gridLine.offsetFromLeftEdge,
          wallHeight,
        ]}
        listening={isAvailableToSelect}
        dash={actualConfig.dash?.map((n) => n / scale / 2)}
        stroke={actualConfig.stroke}
        strokeWidth={1.3 / scale}
        hitStrokeWidth={10 / scale}
        onPointerDown={handlePointerDown}
        onMouseEnter={handleMouseEnter}
        onPointerLeave={handleMouseLeave}
      />
      {!!measurementPoints?.length &&
        activeWall === wallData?.guid &&
        isAvailableToSelect &&
        !selectedWindows?.length &&
        !projectData.locked && (
          <MultiMeasurementLine
            multiPoints={measurementPoints}
            scale={scale}
            units={unitSystem}
            type={MeasurementElementType.WindowDistance}
            onActiveStatusChange={handleStatusChange}
            onChange={handleMeasurementChange}
            onEscape={handleMeasurementEscape}
            onSubmit={handleMeasurementSubmit}
          />
        )}
    </Group>
  );
};

export default FacadeDesignerGridLine;
