import React from 'react';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import './AntdTextArea.scss';
import { Input } from 'antd';

const IntusTextArea: React.FC<TextAreaProps> = ({ ...rest }) => {
  return <Input.TextArea rootClassName={'intus-textarea'} {...rest} />;
};

export default IntusTextArea;
