import { NodeType, UserBuildingWall } from '@/models';
import { CleanUpArea, CleanUpOptions } from '@/models/shared.model';
import { IntusButton, IntusIconButton, IntusTooltip } from '@/shared/elements';
import IntusCheckbox from '@/shared/elements/Checkbox/IntusCheckbox';
import IntusPopover from '@/shared/elements/Popover/Popover';
import IntusRadio from '@/shared/elements/Radio/IntusRadio';
import { getDefaultGridLines } from '@/shared/helpers';
import { removeSpacesFromThousands } from '@/shared/helpers/format-data';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import ClearIcon from '@/shared/icons/ClearIcon';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useAppSelector } from '@/store/hooks';
import { getSelectedNodes } from '@/store/slices/canvasBuildingSlice';
import { Checkbox, Form, Radio } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { inRange } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

interface CleanUpButtonProps {
  hasSelectedNodes: boolean;
  disabled: boolean;
}

const CleanUpButton: React.FC<CleanUpButtonProps> = ({
  hasSelectedNodes,
  disabled,
}) => {
  const { id } = useParams();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const cleanUpOptions = Object.values(CleanUpOptions);
  const { findDataForWall } = useFindNodeData();
  const userBuildings = useFetchProjectQuery(id!).data!.buildings!;
  const { updateBuildingPlacements } = useUpdateUserBuildingData();
  const selectedNodes = Object.values(useAppSelector(getSelectedNodes));
  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });
  const selectedWallsData = useMemo(
    () =>
      selectedNodes
        .filter((node) => node.type === NodeType.Wall)
        .map((node) => findDataForWall(node.guid)),
    [selectedNodes]
  );

  const [form] = Form.useForm();
  useWatch('cleanUpOptions', form);

  const indeterminate = inRange(
    form.getFieldValue('cleanUpOptions')?.length,
    1,
    cleanUpOptions.length
  );

  const handlePopoverOpenChange = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen);
    setIsTooltipVisible(false);
    form.resetFields();
  };

  const clearArea = (
    wallsData: (WallSearchResults | UserBuildingWall)[],
    options: string[]
  ) => {
    const placementData = wallsData.map((wallData) => {
      const wallWidth = Number(
        removeSpacesFromThousands(getWallWidthMetric([wallData]), false)
      );
      const defaultGridLines = getDefaultGridLines(wallData, wallWidth);

      return {
        wallGUID: wallData.guid,
        gridLines: options.includes(CleanUpOptions.GridLines)
          ? defaultGridLines
          : undefined,
        windowPlacements: options.includes(CleanUpOptions.Windows)
          ? []
          : undefined,
        wallPanels: options.includes(CleanUpOptions.Panels) ? [] : undefined,
      };
    });

    updateBuildingPlacements({
      buildingGUID: userBuildings[0].guid,
      placementData,
    });
  };

  const getWallsDataForArea = (area: CleanUpArea) => {
    if (area === CleanUpArea.FullBuilding) {
      return userBuildings[0].blocks.flatMap((block) =>
        block.storeys.flatMap((storey) => storey.walls)
      );
    } else {
      return (selectedWallsData?.filter((wall) => wall !== null) ||
        []) as WallSearchResults[];
    }
  };

  const handleCleanUp = async () => {
    const formOptions = form.getFieldValue('cleanUpOptions');
    const area = form.getFieldValue('area');

    const wallsData = getWallsDataForArea(area);
    clearArea(wallsData, formOptions);

    setIsPopoverOpen(false);
  };

  const tooltipContent = (
    <div className="leading-5">
      Clean up all the grids,
      <br /> windows, and panels
    </div>
  );

  const popoverTitle = (
    <div className="py-2 px-3 text-xs font-medium">Clean up</div>
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isPopoverOpen && e.key === 'Escape') {
        e.stopPropagation();
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown, { capture: true });
    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true });
    };
  }, [isPopoverOpen]);

  const popoverContent = (
    <Form
      form={form}
      initialValues={{ cleanUpOptions: [], area: 'selectedArea' }}
    >
      <div className="text-xs w-[226px]">
        <div className="p-3">
          <div className="leading-5 mb-2">Choose the items to clean up</div>

          <IntusCheckbox
            className="mb-1"
            indeterminate={indeterminate}
            checked={
              form.getFieldValue('cleanUpOptions')?.length ===
              cleanUpOptions.length
            }
            onChange={(e) =>
              form.setFieldValue(
                'cleanUpOptions',
                e.target.checked ? cleanUpOptions : []
              )
            }
          >
            <span className="font-light leading-5 text-dark-gray-100">All</span>
          </IntusCheckbox>

          <Form.Item noStyle name="cleanUpOptions">
            <Checkbox.Group className="flex flex-col gap-1 mb-2.5">
              <IntusCheckbox value="gridLines">
                <span className="font-light leading-5 text-dark-gray-100">
                  Grid lines
                </span>
              </IntusCheckbox>
              <IntusCheckbox value="windows">
                <span className="font-light leading-5 text-dark-gray-100">
                  Windows
                </span>
              </IntusCheckbox>
              <IntusCheckbox value="panels">
                <span className="font-light leading-5 text-dark-gray-100">
                  Panels
                </span>
              </IntusCheckbox>
            </Checkbox.Group>
          </Form.Item>
          <div className="leading-5 mb-2">Choose the area to clean up</div>
          <Form.Item name="area" noStyle>
            <Radio.Group className="flex flex-col gap-1 mb-3">
              <IntusRadio value="selectedArea">
                <span className="leading-5 font-light">Selected area</span>
              </IntusRadio>
              <IntusRadio value="fullBuilding">
                <span className="leading-5 font-light">Full building</span>
              </IntusRadio>
            </Radio.Group>
          </Form.Item>
          <div className="flex gap-2">
            <IntusButton
              onClick={() => setIsPopoverOpen(false)}
              type="text"
              block
            >
              Cancel
            </IntusButton>
            <IntusButton
              onClick={handleCleanUp}
              danger
              block
              disabled={!form.getFieldValue('cleanUpOptions')?.length}
            >
              Clean up
            </IntusButton>
          </div>
        </div>
      </div>
    </Form>
  );

  return (
    <IntusPopover
      placement="bottomLeft"
      title={popoverTitle}
      content={popoverContent}
      trigger={'click'}
      open={isPopoverOpen}
      arrow={false}
      onOpenChange={handlePopoverOpenChange}
    >
      <div
        className="absolute top-2 left-4 z-50"
        onMouseEnter={() => {
          hasSelectedNodes && !isPopoverOpen && setIsTooltipVisible(true);
        }}
        onMouseLeave={() => {
          hasSelectedNodes && !isPopoverOpen && setIsTooltipVisible(false);
        }}
      >
        <IntusTooltip
          open={isTooltipVisible}
          title={tooltipContent}
          placement="right"
        >
          <IntusIconButton
            type="default"
            icon={<ClearIcon />}
            disabled={!hasSelectedNodes || disabled}
            id="facadeDesigner__clean-up-button"
          />
        </IntusTooltip>
      </div>
    </IntusPopover>
  );
};

export default CleanUpButton;
