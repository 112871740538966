import * as THREE from 'three';
import { ShapeUtils } from 'three';
import { FlatVector2, FlatVector3, UserBuildingStorey } from '@/models';
import {
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
} from './distance';
import { addSpacesToThousands } from './format-data';
import { convertFlatVector3ToVector2 } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

interface ObjectDimensions {
  width: string;
  length: string;
}

export const getAreaInMeters = (
  objectPoints: FlatVector3[],
  multiplyRate: number = 1
) => {
  const targetPoints = objectPoints.map((point) =>
    convertFlatVector3ToVector2(point)
  );

  const floorArea = ShapeUtils.area(targetPoints) / Math.pow(multiplyRate, 2);
  return Number(Math.abs(floorArea)).toFixed(2);
};

export const getObjectDimensions = (
  points: FlatVector3[],
  multiplyRate: number,
  isImperialUnits: boolean
): ObjectDimensions => {
  const width = new THREE.Vector3(...points[0]).distanceTo(
    new THREE.Vector3(...points[1])
  );
  const length = new THREE.Vector3(...points[2]).distanceTo(
    new THREE.Vector3(...points[1])
  );

  const widthInMillimeters = convertMetersToMillimeters(width / multiplyRate);

  const lengthInMillimeters = convertMetersToMillimeters(length / multiplyRate);

  return {
    width: addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(widthInMillimeters)
        : parseFloat(Number(widthInMillimeters).toFixed(2)).toString(),
      isImperialUnits
    ),
    length: addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(lengthInMillimeters)
        : parseFloat(Number(lengthInMillimeters).toFixed(2)).toString(),
      isImperialUnits
    ),
  };
};

export const convert2DPointsToDistanceInMeters = (
  p1: FlatVector2,
  p2: FlatVector2,
  multiplyRate: number
): number => Math.hypot(p2[0] - p1[0], p2[1] - p1[1]) / multiplyRate;

export const getWallWidth = (points: FlatVector3[], multiplyRate: number) => {
  return (
    new THREE.Vector3(...points[0]).distanceTo(
      new THREE.Vector3(...points[1])
    ) / multiplyRate
  );
};

export const getWallHeight = (points: FlatVector3[], multiplyRate: number) => {
  return (points[2][1] - points[1][1]) / multiplyRate;
};

export const getWallArea = (points: FlatVector3[], multiplyRate: number) => {
  return (
    getWallWidth(points, multiplyRate) * getWallHeight(points, multiplyRate)
  );
};

export const getFacadesArea = ({
  walls,
  multiplyRate,
}: {
  walls: {
    points: FlatVector3[];
  }[];
  multiplyRate: number;
}) => {
  const facadeArea = walls.reduce((acc, wall) => {
    const wallArea =
      getWallWidth(wall.points, multiplyRate) *
      getWallHeight(wall.points, multiplyRate);
    return acc + Number(wallArea);
  }, 0);

  return Number(facadeArea.toFixed(2));
};

export const getStoreyHeight = (
  storey: UserBuildingStorey,
  multiplyRate: number
) => {
  return parseFloat(
    Number(
      convertMetersToMillimeters(
        (storey.ceiling.points[0][1] - storey.floor.points[0][1]) / multiplyRate
      )
    ).toFixed(2)
  ).toString();
};
