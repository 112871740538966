import { useEffect } from 'react';
import { getCursorCoordinatesOnOrthographicSystem } from '@/shared/helpers';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

const UseMouseOutsideMesh = ({
  meshes,
  onMouseIntersectMeshes,
  onMouseNotIntersectMeshes,
}: {
  meshes?: THREE.Mesh[];
  onMouseIntersectMeshes?: () => void;
  onMouseNotIntersectMeshes?: () => void;
}) => {
  const three = useThree();

  const checkIsMouseOutsideBuilding = (event: PointerEvent) => {
    if (!meshes) return;
    const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
      event,
      three.gl
    );
    three.raycaster.setFromCamera(cursorCoordinates, three.camera);
    const intersect =
      three.raycaster.intersectObjects(meshes, true)?.length > 0;

    !intersect && onMouseNotIntersectMeshes && onMouseNotIntersectMeshes();
    intersect && onMouseIntersectMeshes && onMouseIntersectMeshes();
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.addEventListener('pointermove', checkIsMouseOutsideBuilding);
    return () => {
      canvas.removeEventListener('pointermove', checkIsMouseOutsideBuilding);
    };
  }, [meshes]);
};

export default UseMouseOutsideMesh;
