import DashboardProjectsView from './projects/DashboardProjectsView';
import Project from './projects/project/Project';
import React from 'react';
import CreateProject from '@/routes/dashboard/projects/create/CreateProject';
import ReportPage from './projects/project/ReportPage/ReportPage';

const DashboardRoutes = [
  {
    path: '/dashboard/projects/',
    element: <DashboardProjectsView />,
  },
  {
    path: '/dashboard/projects/:id',
    element: <Project />,
  },
  {
    path: '/dashboard/projects/create',
    element: <CreateProject />,
  },
  {
    path: '/dashboard/projects/:id/report',
    element: <ReportPage />,
  },
];

export default DashboardRoutes;
