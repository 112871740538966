import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { ReportStatus } from '@/models/report';

interface ReportState {
  projects: {
    [key: string]: {
      status: ReportStatus;
    };
  };
}

const initialState: ReportState = {
  projects: {},
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setStatusReport: (
      state,
      action: PayloadAction<{
        id: string;
        status: ReportStatus;
      }>
    ) => {
      state.projects[action.payload.id] = {
        status: action.payload.status,
      };
    },
  },
});

export const { setStatusReport } = reportSlice.actions;

export const getStatusReport = (state: RootState, id: string) =>
  state.canvasReducer.report.projects?.[id]?.status;
