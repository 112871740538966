import React, { useEffect, useRef, useState } from 'react';
import { CustomModalProps } from '@/models/shared.model';
import { ReportStatus } from '@/models/report';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useSaveReportMutation } from '@/store/apis/projectsApi';
import { getStatusReport, setStatusReport } from '@/store/slices/reportSlice';
import { useParams } from 'react-router';
import Report from '@/components/Report/Report';
import ShadowButton from '@/shared/elements/ShadowNotification/ShadowButton';
import { ProjectStructure } from '@/models';
import { NotificationService } from '@/shared/services/error-handler.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import WrapperModalReport from './report/WrapperModalContent';

interface ProjectModalReportProps extends CustomModalProps {
  data: ProjectStructure;
}

const ProjectModalReport: React.FC<ProjectModalReportProps> = ({
  data,
  isOpen,
  setIsOpen,
}) => {
  const { id } = useParams();

  if (!id) return <></>;

  const [saveReport] = useSaveReportMutation();
  const stopGeneration = useRef(false);
  const dispatch = useAppDispatch();
  const [percent, setPercent] = useState(0);
  const reportRef = useRef<HTMLDivElement>(null!);
  const statusReport = useAppSelector((state) => getStatusReport(state, id));

  const generateReport = async () => {
    const doc = new jsPDF({
      format: [914, 610.01],
      unit: 'px',
      orientation: 'landscape',
    });

    const imgWidth = doc.internal.pageSize.getWidth();
    const imgHeight = doc.internal.pageSize.getHeight();
    const children = Array.from(reportRef.current.children) as HTMLDivElement[];

    for (let i = 0; i < children.length; i++) {
      if (stopGeneration.current) {
        setPercent(0);
        throw new Error('STOPPED');
      }

      const canvas = await html2canvas(children[i], {
        scale: 2,
      });

      const imgData = canvas.toDataURL('image/jpeg', 1);

      doc.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);

      if (i !== children.length - 1) doc.addPage();

      const nextPercent = Math.round(((i + 1) / children.length) * 100);

      setPercent(nextPercent);
    }

    setPercent(100);

    return doc.output('blob');
  };

  const handleSaveReport = async (blob: Blob) => {
    const formData = new FormData();
    formData.append('file', blob, 'document.pdf');

    await saveReport({
      id: Number(id),
      data: formData,
    });
  };

  useEffect(() => {
    if (data.locked) {
      dispatch(
        setStatusReport({
          id,
          status: ReportStatus.READY,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      [ReportStatus.READY, ReportStatus.ERROR].includes(statusReport) &&
      !isOpen
    ) {
      const message =
        statusReport === ReportStatus.READY
          ? 'The Report was successfully generated'
          : 'The Report generation failed';

      NotificationService.shadow({
        message,
        description: (
          <ShadowButton onClick={() => setIsOpen(true)}>View</ShadowButton>
        ),
      });
    }

    return () => {
      NotificationService.close();
    };
  }, [statusReport]);

  useEffect(() => {
    if (statusReport !== ReportStatus.PROCESSING) {
      setPercent(0);
      return;
    }

    stopGeneration.current = false;

    generateReport()
      .then((blob) => {
        handleSaveReport(blob);
        dispatch(
          setStatusReport({
            id,
            status: ReportStatus.READY,
          })
        );
      })
      .catch(({ message }) => {
        if (message === 'STOPPED') return;

        console.error(message);

        dispatch(
          setStatusReport({
            id,
            status: ReportStatus.ERROR,
          })
        );
      });
  }, [statusReport]);

  return (
    <>
      {isOpen && (
        <WrapperModalReport
          data={data}
          percent={percent}
          setIsOpen={setIsOpen}
          setPercent={setPercent}
          isOpen={isOpen}
          stopGeneration={stopGeneration}
        />
      )}
      {statusReport === ReportStatus.PROCESSING && <Report ref={reportRef} />}
    </>
  );
};

export default ProjectModalReport;
