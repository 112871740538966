import { FlatVector3, NodeType, PanelPlacementData } from '@/models';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { convertFlatVector3ToVector } from '../user-building.helpers';
import {
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
} from '../../project-canvas.helpers';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { getWallHeight } from '@/shared/helpers/metrics';
import { useGLTF } from '@react-three/drei';
import { scaleMesh } from '@/shared/helpers/canvas';
import * as THREE from 'three';

interface ViewerPanelProps {
  scale: number;
  placementData: PanelPlacementData;
  wallData: FlatVector3[];
}

const ViewerPanel: React.FC<ViewerPanelProps> = ({
  scale,
  placementData,
  wallData,
}) => {
  const { id } = useParams();
  const fetchedPanels = useGetAllPanelsQuery(id!).data;
  if (!fetchedPanels) return null;
  const data = useMemo(
    () => fetchedPanels.find((panel) => panel.id === placementData.panelId),
    [fetchedPanels, placementData.panelId]
  );

  const panelHeight = useMemo(
    () => Number(getWallHeight(wallData, scale)),
    [wallData, scale]
  );

  const leftSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallData[1]),
    [wallData]
  );
  const rightSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallData[0]),
    [wallData]
  );
  const perpendicularForWall = useMemo(
    () =>
      getPerpendicularVectorToVectors([leftSideBottom, rightSideBottom], true),
    [leftSideBottom, rightSideBottom]
  );

  const getPanelPosition = () => {
    const position = getExtendedVector(
      leftSideBottom,
      rightSideBottom,
      convertMillimetersToMeters(
        placementData.offsetFromLeftEdge + data!.width / 2
      ) * scale
    );
    position.setY(position.y + (panelHeight / 2) * scale);
    //TODO: Temporary constant, should read from BE
    return getTranslatedVector(
      position,
      (-0.225 * scale) / 2,
      perpendicularForWall
    );
  };

  const getLookAtPosition = () => {
    return getTranslatedVector(getPanelPosition(), 90, perpendicularForWall);
  };

  let panelModel: THREE.Group;

  try {
    const gltf = useGLTF(data!.model);
    panelModel = gltf.scene.clone();
    panelModel.castShadow = true;
    panelModel.receiveShadow = true;
  } catch {
    return null;
  }

  scaleMesh(panelModel, convertMillimetersToMeters(scale));

  panelModel.position.copy(getPanelPosition());
  panelModel.lookAt(getLookAtPosition());
  panelModel.userData = {
    nodeType: NodeType.Panel,
  };

  return <primitive object={panelModel} />;
};

export default ViewerPanel;
