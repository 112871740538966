import React from 'react';

const ClearIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34658 9.27585L14.7466 3.87585L13.789 2.92187L8.38902 8.32188M5.13 8.41709L9.21 12.4971M4.05 12.3771L4.89 11.5371M9.81 9.73709C10.29 10.2171 10.35 10.9971 9.93 11.5371L7.05 15.3771L2.25 10.5771L6.09 7.69709C6.63 7.27709 7.41 7.33709 7.89 7.81709L9.81 9.73709Z"
        stroke="#414042"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClearIcon;
