import React from 'react';

const MergeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 12.5523 3.44772 13 4 13H7V14H4C2.89543 14 2 13.1046 2 12V9H3V12ZM9 14H12C13.1046 14 14 13.1046 14 12V9H13V12C13 12.5523 12.5523 13 12 13H9V14Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3H4C3.44772 3 3 3.44772 3 4V7H2V4C2 2.89543 2.89543 2 4 2H7V3ZM9 3H12C12.5523 3 13 3.44772 13 4V7H14V4C14 2.89543 13.1046 2 12 2H9V3Z"
        fill="#414042"
      />
      <path
        d="M5 8.50001L2 8.50018V7.50018L5 7.50001L4.20711 6.70711C4.01184 6.51184 4.01184 6.19526 4.20711 6C4.40237 5.80474 4.71895 5.80474 4.91421 6L6.56066 7.64646C6.75592 7.84172 6.75592 8.15831 6.56066 8.35357L4.91421 9.99999C4.71895 10.1953 4.40237 10.1953 4.20711 9.99999C4.01184 9.80473 4.01184 9.48815 4.20711 9.29288L5 8.50001Z"
        fill="#414042"
      />
      <path
        d="M11 8.50002L14 8.5001V7.5001L11 7.50002L11.7929 6.70712C11.9882 6.51185 11.9882 6.19527 11.7929 6.00001C11.5976 5.80475 11.281 5.80475 11.0858 6.00001L9.43934 7.64647C9.24408 7.84173 9.24408 8.15831 9.43934 8.35358L11.0858 10C11.281 10.1953 11.5976 10.1953 11.7929 10C11.9882 9.80474 11.9882 9.48816 11.7929 9.29289L11 8.50002Z"
        fill="#414042"
      />
    </svg>
  );
};

export default MergeIcon;
