import { IntusButton } from '@/shared/elements';
import React from 'react';
import { ReportGenerationIcon } from '@/shared/icons/ReportGenerationIcon';
import { ReportStatus } from '@/models/report';
import IntusProgressBar from '@/shared/elements/ProgressBar/ProgressBar';
import { useAppDispatch } from '@/store/hooks';
import { setStatusReport } from '@/store/slices/reportSlice';
import { useParams } from 'react-router';
import { useUpdateProjectMutation } from '@/store/apis/projectsApi';

interface ProcessContentProps {
  setPercent: React.Dispatch<React.SetStateAction<number>>;
  percent: number;
  stopGeneration: React.MutableRefObject<boolean>;
}

const ProcessContent: React.FC<ProcessContentProps> = ({
  percent,
  setPercent,
  stopGeneration,
}) => {
  const { id } = useParams();

  if (!id) return <></>;

  const [updateProject] = useUpdateProjectMutation();
  const estimatedTime = 2;

  const dispatch = useAppDispatch();

  const handelStopGenerationReport = async () => {
    try {
      stopGeneration.current = true;
      dispatch(setStatusReport({ id, status: ReportStatus.DEFAULT }));

      await updateProject({
        id: Number(id),
        locked: false,
      }).unwrap();

      setPercent(0);
    } catch (error) {
      console.error('Failed to lock project:', error);
    }
  };

  return (
    <>
      <ReportGenerationIcon className="mb-10" />
      <div className="flex flex-col gap items-center mb-6">
        <p className="m-0 mb-1 text-xs leading-5 text-dark-gray-100">
          {percent}%
        </p>
        <IntusProgressBar
          strokeColor={'#65BD51'}
          percent={percent}
          showInfo={false}
          className="h-[6px]"
        />
        <p className="m-0 mt-2 text-dark-gray-80 text-xs leading-5">
          Estimated waiting time {estimatedTime} minutes
        </p>
      </div>
      <IntusButton
        danger
        id="report__stop-generation-button"
        className="rounded-lg py-[5px] px-[18px] mb-[30px]"
        onClick={handelStopGenerationReport}
      >
        Stop generation
      </IntusButton>
    </>
  );
};

export default ProcessContent;
