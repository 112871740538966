import React from 'react';

import { Sides } from '@/models';

export interface PanelView {
  imageUrl: string;
  side: Sides;
}

const PanelViewItem: React.FC<PanelView> = ({ imageUrl, side }) => {
  return (
    <div className="flex flex-col gap-3">
      <img src={imageUrl} alt={side} />
      <div className="italic leading-4 text-[10px] font-light text-dark-gray-80 flex justify-center">
        {side}
      </div>
    </div>
  );
};

export default PanelViewItem;
