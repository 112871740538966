import React from 'react';
import { Checkbox, CheckboxProps } from 'antd';
import './AntdCheckbox.scss';

const IntusCheckbox: React.FC<CheckboxProps> = ({
  className = '',
  children,
  ...rest
}) => {
  return (
    <Checkbox className={`intus-checkbox ${className}`} {...rest}>
      {children}
    </Checkbox>
  );
};

export default IntusCheckbox;
