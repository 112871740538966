import {
  IntusInput,
  IntusLoader,
  IntusModal,
  IntusTextArea,
} from '@/shared/elements';
import { CustomError, ProjectCreateFormData, ProjectStructure } from '@/models';
import React, { useState } from 'react';
import { CustomModalProps } from '@/models/shared.model';
import { useLoadMap } from '@/shared/hooks/useLoadMap';
import { useForm } from 'antd/lib/form/Form';
import { Form } from 'antd';
import { GeoMarkerIcon } from '@/shared/icons';
import {
  maxLengthValidator,
  requiredValidator,
} from '@/shared/form/validators';
import { setFormErrorFromServer } from '@/shared/form/error-handlers';
import { useUpdateProjectMutation } from '@/store/apis/projectsApi';
import { IntusSelect } from '@/shared/elements';
import { unitSystems } from '@/models';

interface ProjectCardModalProps extends CustomModalProps {
  data: ProjectStructure;
}

const ProjectCardSettings: React.FC<ProjectCardModalProps> = ({
  data,
  setIsOpen,
  ...rest
}) => {
  const { map, isFetching } = useLoadMap(data.id);
  const [form] = useForm<ProjectCreateFormData>();
  const [updateProject, { isLoading: isProjectUpdating }] =
    useUpdateProjectMutation();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleFinish = async () => {
    try {
      await form.validateFields();
      await updateProject({
        id: data.id,
        name: form.getFieldValue('name'),
        description: form.getFieldValue('description'),
        unitSystem: form.getFieldValue('unitSystem'),
      }).unwrap();
      setIsOpen(false);
    } catch (err) {
      if (Object.hasOwn(err as never, 'data')) {
        setFormErrorFromServer(form, err as CustomError);
      }
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <IntusModal
      centered
      open
      title={'Settings'}
      onCancel={() => handleCancel()}
      onOk={handleFinish}
      okButtonProps={{
        disabled: buttonDisabled,
        style: { marginLeft: 'auto' },
      }}
      okText={'Apply changes'}
      width={746}
      cancelButtonProps={{ style: { display: 'none' } }}
      {...rest}
    >
      <IntusLoader loading={isProjectUpdating}>
        <div className="flex my-6 h-[410px]">
          <div className="relative w-[350px]">
            <div className="mb-2">
              <div className="text-sm font-normal mb-2">Address</div>
              <div className="text-sm text-black-40 truncate">
                {data.address}
              </div>
            </div>
            <div>
              <IntusLoader loading={isFetching}>
                {isFetching ? (
                  <div className="h-[350px] w-[350px]"></div>
                ) : (
                  <div className="h-[350px] w-[350px]">
                    <img
                      className="block h-full w-full"
                      src={map}
                      alt="Project map"
                    />
                    <GeoMarkerIcon
                      className={
                        'mapbox-geo-icon absolute top-1/2 left-1/2 z-10'
                      }
                    ></GeoMarkerIcon>
                  </div>
                )}
              </IntusLoader>
            </div>
          </div>
          <div className="ml-8 flex-1">
            <Form
              layout="vertical"
              form={form}
              initialValues={data}
              onValuesChange={() => setButtonDisabled(false)}
            >
              <Form.Item
                label="Project name"
                required
                name="name"
                rules={[
                  requiredValidator('Project Name is required'),
                  maxLengthValidator('Max 50 characters', 50),
                ]}
              >
                <IntusInput disabled={data.locked} />
              </Form.Item>
              <Form.Item name={'unitSystem'} className={'w-40'} label={'Units'}>
                <IntusSelect options={unitSystems} />
              </Form.Item>
              <Form.Item
                label="Project description"
                name="description"
                rules={[maxLengthValidator('Max 450 characters', 450)]}
                className="min-h-32"
              >
                <IntusTextArea
                  disabled={data.locked}
                  className="max-h-[285px]"
                  rows={5}
                ></IntusTextArea>
              </Form.Item>
            </Form>
          </div>
        </div>
      </IntusLoader>
    </IntusModal>
  );
};

export default ProjectCardSettings;
