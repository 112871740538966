import React from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Line } from 'react-konva';

import { WINDOW_GLASS_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { FlatVector2 } from '@/models';

interface WindowGlassProps {
  points: FlatVector2[];
  onClick?: (event: KonvaEventObject<MouseEvent>) => void;
}

const WindowGlass = React.forwardRef<Konva.Line, WindowGlassProps>(
  ({ onClick, points }, ref) => {
    return (
      <Line
        onClick={onClick}
        points={points.flat()}
        fill={WINDOW_GLASS_COLOR}
        closed
        ref={ref}
      />
    );
  }
);

WindowGlass.displayName = 'WindowGlass';

export default WindowGlass;
