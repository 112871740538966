import React from 'react';
import { Radio, RadioProps } from 'antd';
import './AntdRadio.scss';

const IntusRadio: React.FC<RadioProps> = ({
  className = '',
  children,
  ...rest
}) => {
  return (
    <Radio className={`intus-radio ${className}`} {...rest}>
      {children}
    </Radio>
  );
};

export default IntusRadio;
