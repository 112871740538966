import {
  INITIAL_SCALE,
  STAGE_BOTTOM_PADDING_TO_FLOOR_0,
  STAGE_HEIGHT,
  STAGE_WIDTH,
} from '../constants';
import { FlatVector2 } from '@/models';
import { FlatVector2Axis } from '@/components/WindowCreator/models/konva-model';
import {
  InnerWindowData,
  MullionData,
  OperationType,
  WindowFrame,
} from '@/models/window-configurator.model';
import {
  isHorizontal,
  mirrorPointAcrossX,
} from '@/components/WindowCreator/helpers/direction.helpers';
import { get2DCenter } from '@/shared/helpers/konva';

export const generateDefaultWindow = (
  width: number,
  height: number,
  offset: number
): FlatVector2[] => [
  [
    STAGE_WIDTH / 2 / INITIAL_SCALE - width / 2,
    (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE -
      height -
      offset,
  ],
  [
    STAGE_WIDTH / 2 / INITIAL_SCALE + width / 2,
    (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE -
      height -
      offset,
  ],
  [
    STAGE_WIDTH / 2 / INITIAL_SCALE + width / 2,
    (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE - offset,
  ],
  [
    STAGE_WIDTH / 2 / INITIAL_SCALE - width / 2,
    (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE - offset,
  ],
];

export const generateFrameMeasurements = (
  data: WindowFrame
): [FlatVector2Axis, FlatVector2Axis] => {
  // Sizes following first two points (TopLeft, TopRight, BottomRight)
  return [
    [
      [data.points[0][0], data.points[0][1]],
      [data.points[1][0], data.points[1][1]],
    ],
    [
      [data.points[1][0], data.points[1][1]],
      [data.points[2][0], data.points[2][1]],
    ],
  ];
};

export const generateOffsetMeasurement = (
  data: WindowFrame
): FlatVector2Axis => {
  // Distance from BottomRight point to Floor (using offset characteristic)
  return [
    [data.points[2][0], data.points[2][1]],
    [data.points[2][0], data.points[2][1] + data.distanceToFloor],
  ];
};

export const generateMullionMeasurements = (
  data: WindowFrame,
  direction: 'x' | 'y'
) => {
  const directionRelatedIdx = direction === 'x' ? 0 : 1;
  const mullionAxisPoints = data.mullions
    .map((mullion) => [
      mullion.points[0][directionRelatedIdx],
      mullion.points[1][directionRelatedIdx],
    ])
    .flat();
  const frameAxisPoints = data.points
    .map((measurement) => measurement[directionRelatedIdx])
    .flat();

  const uniqueAxisPoints = [
    ...new Set(
      [...mullionAxisPoints, ...frameAxisPoints].sort((a, b) => a - b)
    ),
  ];

  // Based on rectangle form of Window frame. Should be adjusted if frame will not follow rectangle shape
  const edgeFramePoint =
    data.points[direction === 'x' ? 0 : 1][direction === 'x' ? 1 : 0];
  const points: FlatVector2[] = uniqueAxisPoints.map((point) =>
    direction === 'x' ? [point, edgeFramePoint] : [edgeFramePoint, point]
  );

  const measurementPoints: FlatVector2Axis[] = [];
  for (let i = 0; i < points.length - 1; i++) {
    measurementPoints.push([points[i], points[i + 1]]);
  }
  return measurementPoints;
};

export const generateMullion = (points: FlatVector2Axis): MullionData => {
  return {
    points,
  };
};

export const generateSplitWindows = (
  axis: FlatVector2Axis,
  // Contour has 4 points only
  existingWindow: InnerWindowData
): InnerWindowData[] => {
  const isAxisHorizontal = isHorizontal(axis);
  let windowContours: FlatVector2[][];
  const contour = existingWindow.points;
  if (isAxisHorizontal) {
    windowContours = [
      [contour[0], contour[1], axis[1], axis[0]],
      [axis[0], axis[1], contour[2], contour[3]],
    ];
  } else {
    windowContours = [
      [contour[0], axis[0], axis[1], contour[3]],
      [axis[0], contour[1], contour[2], axis[1]],
    ];
  }
  return windowContours.map(
    (windowContour): InnerWindowData => ({
      points: windowContour,
      operationType: OperationType.Fixed,
      glazing: 'double',
    })
  );
};

const mirrorOperationType = (operationType: OperationType): OperationType => {
  switch (operationType) {
    case OperationType.DualActionLeftTop:
      return OperationType.DualActionRightTop;
    case OperationType.DualActionRightTop:
      return OperationType.DualActionLeftTop;
    case OperationType.CasementLeft:
      return OperationType.CasementRight;
    case OperationType.CasementRight:
      return OperationType.CasementLeft;
    default:
      return operationType;
  }
};

export const swapDiagonalCorners = (points: FlatVector2[]): FlatVector2[] => {
  return [points[1], points[0], points[3], points[2]];
};

export const mirrorFlatVector2Axis = (
  [point1, point2]: FlatVector2Axis,
  frameCenter: number
): FlatVector2Axis => {
  return isHorizontal([point1, point2])
    ? [
        mirrorPointAcrossX(point2, frameCenter),
        mirrorPointAcrossX(point1, frameCenter),
      ]
    : [
        mirrorPointAcrossX(point1, frameCenter),
        mirrorPointAcrossX(point2, frameCenter),
      ];
};

export const generateMirroredWindow = (
  windowData: WindowFrame,
  mirrorOperationTypes: boolean = true
): WindowFrame => {
  const frameCenter = get2DCenter(
    windowData.points[0],
    windowData.points[1]
  )[0];

  const mirroredInnerWindows = windowData.innerWindows.map((innerWindow) => ({
    ...innerWindow,
    operationType: mirrorOperationTypes
      ? mirrorOperationType(innerWindow.operationType)
      : innerWindow.operationType,
    points: swapDiagonalCorners(
      innerWindow.points.map((point) => mirrorPointAcrossX(point, frameCenter))
    ),
  }));

  const mirroredMullions = windowData.mullions.map((mullion) => ({
    ...mullion,
    points: mirrorFlatVector2Axis(mullion.points, frameCenter),
  }));

  return {
    ...windowData,
    innerWindows: mirroredInnerWindows,
    mullions: mirroredMullions,
  };
};
