import React, { useEffect, useState } from 'react';
import * as THREE from 'three';

import {
  DistanceInput,
  FlatVector3,
  UserBuildingBlock,
  UserBuildingSurface,
} from '@/models';
import { ExtendedMinMaxCoordinatesPairs } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import SplitFaces from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitFaces';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import {
  getProcessingEntity,
  setDirectionalInputValues,
} from '@/store/slices/canvasExternalElementsSlice';
import { findFacadePointsHelper } from '@/routes/dashboard/projects/project/UserBuilding/helpers/editing-tools.helpers';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';

interface CutFaceProps {
  block: UserBuildingBlock;
  mousePosition: THREE.Vector3 | null;
  currentWall: UserBuildingSurface | null;
  setCutFaceWallPoints: (v: FlatVector3[]) => void;
  cutFaceWallPoints: FlatVector3[] | undefined;
}

const CutFace: React.FC<CutFaceProps> = ({
  block,
  mousePosition,
  currentWall,
  cutFaceWallPoints,
  setCutFaceWallPoints,
}) => {
  const [minMax, setMinMax] = useState<ExtendedMinMaxCoordinatesPairs>();
  const [previousActiveWall, setPreviousActiveWall] = useState<string>();
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const dispatch = useAppDispatch();
  const processingEntity = useAppSelector(getProcessingEntity);

  const findFacadeWalls = () => {
    if (!mousePosition) return;
    const facadeData = findFacadePointsHelper(mousePosition, block);

    //this is necessary to correct the close distance and method isPointsInOneLine, to prevent a lot of unnecessary recalculations
    setPreviousActiveWall(currentWall?.guid);

    if (!facadeData) return;

    const { points, minMax, farthestRightWall, farthestLeftWall } = facadeData;

    setMinMax({
      max: {
        ...minMax.max,
        y: block.storeys[block.storeys.length - 1].ceiling.points[0][1],
      },
      min: {
        ...minMax.min,
        y: block.storeys[0].floor.points[0][1],
      },
    });
    setCutFaceWallPoints(points);
    const firstPoint = convertFlatVector3ToVector(farthestLeftWall);
    const secondPoint = convertFlatVector3ToVector(farthestRightWall);
    const maxDistance = firstPoint.distanceTo(secondPoint);

    const maxDistanceInMillimeters = (
      Number(convertMetersToMillimeters(maxDistance / multiplyRate)) - 1
    ).toFixed(0);
    //Max distance shouldn't be equal to facade width

    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          min: 1,
          max: Number(maxDistanceInMillimeters),
          validationMessage: 'Invalid value',
        },
        {
          type:
            processingEntity?.type === DistanceInput.LeftEdgeDistance
              ? DistanceInput.RightEdgeDistance
              : DistanceInput.LeftEdgeDistance,
          min: 1,
          max: Number(maxDistanceInMillimeters),
          validationMessage: 'Invalid value',
        },
      ])
    );
  };

  useEffect(() => {
    currentWall?.guid !== previousActiveWall && findFacadeWalls();
  }, [currentWall, previousActiveWall, mousePosition]);

  return (
    <group>
      {mousePosition && minMax && cutFaceWallPoints && (
        <SplitFaces
          position={mousePosition}
          minMaxCoordinates={minMax}
          wallPoints={cutFaceWallPoints}
          isBuildingClockwise={false}
        />
      )}
    </group>
  );
};

export default CutFace;
