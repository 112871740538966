import React, { forwardRef, Ref } from 'react';
import moment from 'moment';
import ReportCover from './Pages/ReportCover/ReportCover';
import morningSummerRender from '@/images/mockReportCover.png';
import morningWinterRender from '@/images/morningWinterRender.png';
import eveningSummerRender from '@/images/eveningSummerRender.png';
import eveningWinterRender from '@/images/eveningWinterRender.png';
import BuildingRenders, {
  RenderData,
} from './Pages/BuildingRenders/BuildingRenders';
import BuildingProperties from './Pages/BuildingProperties/BuildingProperties';
import ReportWrapper from './Pages/ReportWrapper';
import { useParams } from 'react-router';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import PanelProperties from './Pages/PanelProperties/PanelProperties';
import PanelMetrics from './Pages/PanelMetrics/PanelMetrics';
import WindowPropertiesTable from './Pages/PanelMetrics/WindowsPropertiesTable/WindowsPropertiesTable';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

const renders: RenderData[] = [
  { imageUrl: morningSummerRender, renderDateTime: '10 AM - Summer' },
  { imageUrl: morningWinterRender, renderDateTime: '10 AM - Winter' },
  { imageUrl: eveningSummerRender, renderDateTime: '10 PM - Summer' },
  { imageUrl: eveningWinterRender, renderDateTime: '10 PM - Winter' },
];

const Report = forwardRef<HTMLDivElement>((_, ref: Ref<HTMLDivElement>) => {
  const creationDate = moment(new Date()).format('DD/MM/YYYY hh:mm A');
  const isReportRoute = location.pathname.endsWith('/report');
  const { id } = useParams();
  const { data: fetchedPanels } = useGetAllPanelsQuery(id!);
  const { panelCount, panelWindowCount } = useFrameProperties();
  const totalPages = (fetchedPanels ?? []).reduce(
    (acc, panel) => acc + 1 + (panel.panelWindows.length > 1 ? 1 : 0),
    3
  );

  let panelPageIndex = 4;

  return (
    <div
      className={`left-0 right-0 top-0 mr-auto ml-auto w-fit z-[-9999] ${
        isReportRoute ? 'flex flex-col gap-5' : 'fixed bg-light-gray-20'
      }`}
      ref={ref}
    >
      <ReportCover />
      <ReportWrapper
        pageIndex={1}
        reportCreationDate={creationDate}
        countOfPages={totalPages}
        pageName="Renders"
      >
        <BuildingRenders renders={renders} />
      </ReportWrapper>

      <ReportWrapper
        pageIndex={2}
        reportCreationDate={creationDate}
        countOfPages={totalPages}
        pageName="Building properties"
      >
        <BuildingProperties />
      </ReportWrapper>
      <ReportWrapper
        pageIndex={3}
        reportCreationDate={creationDate}
        countOfPages={totalPages}
        pageName="Panelization properties"
      >
        <PanelProperties />
      </ReportWrapper>
      {fetchedPanels?.map((panel, index) => {
        const hasMoreThanOneWindow = panel.panelWindows.length > 1;
        return (
          <React.Fragment key={`panel-${panel.id}`}>
            <ReportWrapper
              key={panel.id}
              pageIndex={panelPageIndex++}
              countOfPages={totalPages}
              reportCreationDate={creationDate}
              pageName="panel list"
            >
              <PanelMetrics
                panelIndex={index}
                panelData={panel}
                panelCount={panelCount}
                panelWindowCount={panelWindowCount}
              />
            </ReportWrapper>
            {hasMoreThanOneWindow && (
              <ReportWrapper
                pageIndex={panelPageIndex++}
                countOfPages={totalPages}
                reportCreationDate={creationDate}
                pageName="panel list"
              >
                <div className="w-full px-3 py-3">
                  <WindowPropertiesTable
                    panelData={panel}
                    panelWindowCount={panelWindowCount}
                  />
                </div>
              </ReportWrapper>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
});

Report.displayName = 'Report';

export default Report;
